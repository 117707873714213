import DeviceUpdates from '../../models/DeviceUpdates';
import UpdateHandle from '../../models/UpdateHandle';
import Device from '../../../core/models/Device';

export const splitUpdatesAndDevices = (
  deviceUpdates: DeviceUpdates[]
): { uniqueUpdates: UpdateHandle[]; selectedDevices: Device[] } => {
  const selectedDevices: Device[] = [];
  const updatesById: { [identifier: string]: UpdateHandle } = {};
  deviceUpdates.forEach(({ device, updates }) => {
    selectedDevices.push(device);
    updates.forEach(update => {
      updatesById[identify(update)] = update;
    });
  });
  return { uniqueUpdates: Object.values(updatesById), selectedDevices };
};

function identify(update: UpdateHandle): string {
  if (update.type == 'windows-update') {
    return update.id;
  } else {
    return update.appId + update.targetVersion;
  }
}
