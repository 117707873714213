import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DeviceUpdateSummaryCellRenderer.module.scss';
import DeviceDetailsLink from '../../../DeviceDetails/DeviceDetailsLink';
import Context from '../Context';
import CompanyDeviceUpdateStatus from '../../../../models/CompanyDeviceUpdateStatus';
import { numberOfAppUpdates, numberOfWindowsUpdates } from '../../DeviceUpdatesStatistics';
import { DeviceDetailsTargetTab, DeviceDetailsViewedEvent } from '../../../../../core/models/UserTrackingEvents';

type DeviceUpdateSummaryCellRendererProps = Omit<ICellRendererParams<CompanyDeviceUpdateStatus, never>, 'context'> & {
  context: Context;
};

export default function DeviceUpdateSummaryCellRenderer({
  data: updateRow
}: DeviceUpdateSummaryCellRendererProps): ReactElement | null {
  const { t } = useTranslation();
  const numberOfWindowsUpdatesCached = updateRow ? numberOfWindowsUpdates(updateRow.updates) : 0;
  const numberOfAppUpdatesCached = updateRow ? numberOfAppUpdates(updateRow.updates) : 0;

  if (!updateRow) {
    return null;
  }

  return (
    <span role="presentation" className={styles.deviceUpdateSummaryCell}>
      {numberOfWindowsUpdatesCached > 0 && (
        <DeviceDetailsLink
          deviceId={updateRow.device.id}
          targetTab={DeviceDetailsTargetTab.WindowsUpdates}
          trackingEvent={new DeviceDetailsViewedEvent(DeviceDetailsTargetTab.WindowsUpdates)}>
          {t('companyUpdatesOverview.grid.updateSummary.windowsUpdateCount', { count: numberOfWindowsUpdatesCached })}
        </DeviceDetailsLink>
      )}
      {numberOfAppUpdatesCached > 0 && (
        <DeviceDetailsLink
          deviceId={updateRow.device.id}
          targetTab={DeviceDetailsTargetTab.ApplicationUpdates}
          trackingEvent={new DeviceDetailsViewedEvent(DeviceDetailsTargetTab.ApplicationUpdates)}>
          {t('companyUpdatesOverview.grid.updateSummary.applicationUpdateCount', { count: numberOfAppUpdatesCached })}
        </DeviceDetailsLink>
      )}
    </span>
  );
}
