import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { PropsWithChildren, ReactElement } from 'react';
import styles from './CellText.module.scss';

export function CellText({ children }: PropsWithChildren): ReactElement {
  return (
    <Typography variant="caption-medium" className={styles.cellText} lineClamp={2}>
      {children}
    </Typography>
  );
}
