import { ContainerModule } from 'inversify';
import { environment } from './environment';

const EnvironmentServices = {
  EnvironmentConfig: Symbol.for('EnvironmentConfig')
};

const EnvironmentModule = new ContainerModule(bind => {
  bind(EnvironmentServices.EnvironmentConfig).toConstantValue(environment);
});

export { EnvironmentServices, EnvironmentModule };
