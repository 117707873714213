import { ReactElement } from 'react';
import { Button } from '@getgo/chameleon-web-react-wrapper';
import { PlayFilledIcon, RefreshFilledIcon } from '@getgo/chameleon-icons/react';
import { useTranslation } from 'react-i18next';

interface Props {
  inProgress?: boolean;
  installRequestPending: boolean;
  onInstallUpdateClick: () => void;
}

function InstallUpdateButton({ inProgress, installRequestPending, onInstallUpdateClick }: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <Button
      leadingIcon={inProgress ? <RefreshFilledIcon /> : <PlayFilledIcon />}
      isLoading={installRequestPending}
      disabled={inProgress}
      onClick={onInstallUpdateClick}>
      {inProgress
        ? t('deviceDetails.grid.windows.actions.inProgress')
        : t('deviceDetails.grid.windows.actions.installUpdate')}
    </Button>
  );
}

export default InstallUpdateButton;
