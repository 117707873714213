import { ReactElement } from 'react';
import { DateTime } from 'luxon';
import { useAppSelector } from '../../../core/store/root.store';
import { selectDateLocale } from '../../../ui-frame/state/ui-frame.slice';
import { CellText } from './CellText';

function DateTimeCellRenderer(date: Date | undefined): ReactElement | null {
  const locale = useAppSelector(selectDateLocale);
  return date ? <CellText>{DateTime.fromJSDate(date).toFormat('FF', { locale: locale })}</CellText> : null;
}

export default DateTimeCellRenderer;
