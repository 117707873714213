import { createAsyncThunk, createSelector, createSlice, Draft } from '@reduxjs/toolkit';
import { Guid } from '../../core/models/guid';
import fileService from '../../core/services/file/file.service';
import http from '../../core/services/http/default-http-client';
import logger from '../../core/services/logger.service';
import { RootState } from '../../core/store/root.store';
import { combineUrl, pollUntil } from '../../core/utilities/utilities';
import { environment } from '../../environments/environment';
import { DownloadAllLogsStatusResponse } from '../models/download-all-logs-status-response';
import { StartDownloadAllLogsResponse } from '../models/start-download-all-logs-response';

interface DownloadingStatus {
  downloading: boolean;
  error?: string;
}

export interface AllLogsState {
  entities: Record<Guid, DownloadingStatus>;
}

const initialState: AllLogsState = {
  entities: {}
};

function setDownloadStatus({
  state,
  updateJobId,
  params
}: {
  state: Draft<AllLogsState>;
  updateJobId: string;
  params: Partial<DownloadingStatus>;
}): void {
  const item = state.entities[updateJobId];
  state.entities[updateJobId] = { ...item, ...params };
}

// slice (actions and reducers)
const allLogsSlice = createSlice({
  name: 'allLogs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(downloadAllLogs.pending, (state, { meta: { arg } }) => {
        setDownloadStatus({ state, updateJobId: arg, params: { downloading: true } });
      })
      .addCase(downloadAllLogs.fulfilled, (state, { meta: { arg } }) => {
        setDownloadStatus({ state, updateJobId: arg, params: { downloading: false } });
      })
      .addCase(downloadAllLogs.rejected, (state, { error: { message }, meta: { arg } }) => {
        setDownloadStatus({ state, updateJobId: arg, params: { downloading: false, error: message } });
      });
  }
});

// selectors
export const selectAllLogsDownloadingByUpdateJobId = createSelector(
  [(state: RootState) => state.allLogs.entities, (state, updateJobId) => updateJobId],
  (items, updateJobId) => items[updateJobId]
);

// thunks (async actions)
export const downloadAllLogs = createAsyncThunk(
  'allLogsSlice/downloadAllLogs',
  async (updateJobId: Guid, { rejectWithValue }) => {
    const startDownloadAllLogs = async (): Promise<void> => {
      const { requestId } = await http.post<{ runId: Guid }, StartDownloadAllLogsResponse>(
        combineUrl(environment.remoteExecutionFrontendApiUrl, `automation/runs/${updateJobId}/logs`),
        {
          runId: updateJobId
        }
      );

      await getDownloadAllLogsReadyStatus(requestId);
    };

    const getDownloadAllLogsReadyStatus = async (requestId: Guid): Promise<void> => {
      await pollUntil(
        () =>
          http.get<DownloadAllLogsStatusResponse>(
            combineUrl(
              environment.remoteExecutionFrontendApiUrl,
              `automation/runs/${updateJobId}/logs/requests/${requestId}/status`
            )
          ),
        response => response.ready,
        3000
      );

      await downloadAllLogs(requestId);
    };

    const downloadAllLogs = async (requestId: Guid): Promise<void> => {
      const { file, filename } = await http.getFile(
        combineUrl(
          environment.remoteExecutionFrontendApiUrl,
          `automation/runs/${updateJobId}/logs/requests/${requestId}`
        )
      );

      fileService.save(file, filename);
    };

    try {
      await startDownloadAllLogs();
    } catch (error) {
      logger.logError(error as Error);
      return rejectWithValue({ errorMessage: (error as Error).message ?? 'Unknown error' });
    }
  }
);

export default allLogsSlice.reducer;
