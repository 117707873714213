import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useAppSelector } from '../store/root.store';
import liveUpdatesService from '../services/live-updates.service';
import { selectUser } from '../state/user.slice';

function LiveUpdatesProvider({ children }: PropsWithChildren): ReactElement | null {
  const user = useAppSelector(selectUser);

  useEffect(() => {
    if (user) {
      liveUpdatesService.start();
    }
  }, [user]);

  return user ? <>{children}</> : null;
}

export default LiveUpdatesProvider;
