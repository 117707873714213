import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement, useCallback } from 'react';
import Tooltip from '../../../../../core/components/tooltips/Tooltip';
import { Button, Menu, MenuItem, MenuSection } from '@getgo/chameleon-web-react-wrapper';
import { ClearAllFilledIcon, PlayOutlinedIcon } from '@getgo/chameleon-icons/react';
import useDisabledUpdateTooltipText from '../../../../hooks/useDisabledUpdateTooltipText';
import GridMoreActions from '../../../../../core/components/grid/GridMoreActions';
import { navigate, navigateToApp } from '../../../../../core/services/navigation.service';
import GridActions from '../../../../../core/components/grid/GridActions';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../../core/components/dialogs/DialogProvider';
import Context from '../Context';
import useUpdateInstallCapabilities from '../../../../hooks/useUpdateInstallCapabilities';
import UpdateInstallGuard from '../../../../hooks/UpdateInstallGuard';
import CompanyDeviceUpdateStatus from '../../../../models/CompanyDeviceUpdateStatus';

type DeviceUpdatesActionsProps = Omit<ICellRendererParams<CompanyDeviceUpdateStatus>, 'context'> & { context: Context };

export default function DeviceUpdatesGridRowActions({
  data: updateRow,
  context,
  eGridCell,
  node,
  api: gridApi
}: DeviceUpdatesActionsProps): ReactElement {
  const { t } = useTranslation();
  const dialog = useDialog();
  const deviceUpdatesCapabilities = useUpdateInstallCapabilities();
  const disabledUpdateTooltipText = useDisabledUpdateTooltipText();
  const updateInstallGuard = UpdateInstallGuard.useForDevice();

  const navigateToDeviceUpdates = (): void => {
    if (updateRow?.device?.id) {
      navigateToApp({
        path: '/devices/details/:deviceId',
        params: { deviceId: updateRow.device.id }
      });
    }
  };

  const onInstallAllClick = useCallback(() => {
    if (updateInstallGuard()) {
      return;
    }
    gridApi.deselectAll();
    node.setSelected(true);
  }, [gridApi, node, updateInstallGuard]);

  if (!updateRow) {
    return <></>;
  }

  const onReviewUpdatesClick = (): void => {
    const path = '/devices/:deviceId/updates';
    navigate({ path, params: { deviceId: updateRow.device.id } });
  };

  return (
    <GridActions>
      <Tooltip
        hidden={deviceUpdatesCapabilities.canInstallUpdates(updateRow)}
        trigger={
          <Button
            variant={'neutral'}
            leadingIcon={<PlayOutlinedIcon />}
            isLoading={context.installRequestPending}
            disabled={
              !deviceUpdatesCapabilities.deviceCanReceiveUpdates(updateRow) ||
              !deviceUpdatesCapabilities.hasUpdatesToInstall(updateRow)
            }
            onClick={onInstallAllClick}>
            {t('companyUpdatesOverview.grid.actions.installAllUpdates')}
          </Button>
        }>
        {disabledUpdateTooltipText(updateRow.device, undefined, updateRow.updateStatus)}
      </Tooltip>
      <Button leadingIcon={<ClearAllFilledIcon />} onClick={onReviewUpdatesClick}>
        {t('companyUpdatesOverview.grid.moreActions.reviewUpdates')}
      </Button>
      <GridMoreActions gridCell={eGridCell}>
        <Menu aria-label="actions menu">
          <MenuSection>{t('companyUpdatesOverview.grid.moreActions.title')}</MenuSection>
          <MenuItem onClick={navigateToDeviceUpdates}>{t('companyUpdatesOverview.grid.actions.manageDevice')}</MenuItem>
          <MenuItem onClick={() => dialog.showCreateTicketDialog(updateRow.device.id)}>
            {t('deviceDetails.grid.windows.moreActions.createTicket')}
          </MenuItem>
        </Menu>
      </GridMoreActions>
    </GridActions>
  );
}
