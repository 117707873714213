import { AppLinkDef } from '../../../core/models/AppLinkDef';

export default function useUpdateDetailsLink(jobId: string, jobName?: string): AppLinkDef | null {
  return !jobName
    ? null
    : {
        route: { path: '/updates/:updateJobId', params: { updateJobId: jobId } },
        text: jobName
      };
}
