import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReact, AgGridReactProps, AgReactUiProps } from '@ag-grid-community/react';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import clsx from 'clsx';
import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import { selectTheme } from '../../../ui-frame/state/ui-frame.slice';
import { useAppSelector } from '../../store/root.store';
import styles from './Grid.module.scss';
import GridToolbar, { GridToolbarProps } from './GridToolbar';
import LoadingOverlay from './loading-overlay/LoadingOverlay';

type GridProps<TData = any> = (AgGridReactProps<TData> | AgReactUiProps<TData>) & {
  toolbarProps?: Omit<GridToolbarProps, 'gridRef'>;
};

const Grid = React.forwardRef<AgGridReact, GridProps>(({ toolbarProps, ...props }, ref) => {
  const [gridReady, setGridReady] = useState(false);
  const innerRef = useRef<AgGridReact>(null);
  const groupRowRendererParams = {
    checkbox: true,
    ...props.groupRowRendererParams
  };
  useImperativeHandle(ref, () => innerRef.current!);

  const onGridReady = (event: GridReadyEvent): void => {
    setGridReady(true);
    props.onGridReady && props.onGridReady(event);
    if (toolbarProps?.initialFilter) {
      const filters = toolbarProps?.filters;
      const filter = filters?.find(({ filterId }) => filterId === toolbarProps.initialFilter);
      innerRef.current?.api.setFilterModel(filter ? filter.filterModel : null);
    }
  };

  const theme = useAppSelector(selectTheme);
  const loadingOverlayComponent = useMemo<typeof LoadingOverlay>(() => LoadingOverlay, []);

  return (
    <div className={clsx(styles.gridWrapper, gridReady && styles.gridReady)}>
      {toolbarProps && <GridToolbar gridRef={innerRef} {...toolbarProps} />}
      <AgGridReact
        modules={[ClientSideRowModelModule, RowGroupingModule]}
        rowHeight={56}
        headerHeight={56}
        ref={innerRef}
        suppressRowClickSelection
        suppressCellFocus
        suppressPropertyNamesCheck
        suppressContextMenu
        enableCellTextSelection
        suppressDragLeaveHidesColumns
        loadingOverlayComponent={loadingOverlayComponent}
        rowSelection="multiple"
        pagination={true}
        paginationAutoPageSize={true}
        paginateChildRows={true}
        tooltipShowDelay={500}
        groupDisplayType="groupRows"
        groupRowRendererParams={groupRowRendererParams}
        groupSelectsChildren={true}
        groupSelectsFiltered={true}
        groupDefaultExpanded={1}
        {...props}
        onGridReady={onGridReady}
        rowData={gridReady ? props.rowData : null}
        className={clsx(
          theme === 'dark' ? 'ag-theme-chameleon-dark' : 'ag-theme-chameleon',
          styles.grid,
          props.className
        )}
        defaultColDef={{ sortable: true, suppressMenu: true, lockPinned: true, minWidth: 40, ...props.defaultColDef }}
      />
    </div>
  );
});

Grid.displayName = 'Grid';

export default Grid;
