import { ColDef } from '@ag-grid-community/core';
import { SoftwareUpdateGridRow } from './SoftwareUpdateGridRow';
import { generalColumnDefs } from '../../../../core/components/grid/column-definitions';
import useDisabledUpdateTooltipText from '../../../hooks/useDisabledUpdateTooltipText';
import { t } from '../../../../i18n/i18n';
import SoftwareUpdateStatusCellRenderer from './cell-renderers/SoftwareUpdateStatusCellRenderer';
import DeviceSoftwareInventoryGridRowActions from './cell-renderers/DeviceSoftwareInventoryActions';

export default function useDeviceSoftwareInventoryColumnDefinitions(): ColDef<SoftwareUpdateGridRow>[] {
  const disabledUpdateTooltipText = useDisabledUpdateTooltipText();

  return [
    {
      ...generalColumnDefs.select,
      tooltipValueGetter: params =>
        disabledUpdateTooltipText(params.data?.device, params.data?.softwareUpdateStatus.updateInProgress)
    },
    {
      field: 'name',
      headerName: t('deviceDetails.grid.apps.headers.name'),
      minWidth: 300,
      flex: 1
    },
    {
      field: 'installedVersion',
      headerName: t('deviceDetails.grid.apps.headers.installedVersion'),
      minWidth: 50
    },
    {
      field: 'softwareUpdateStatus',
      headerName: t('deviceDetails.grid.apps.headers.updateStatus'),
      minWidth: 400,
      cellRenderer: SoftwareUpdateStatusCellRenderer
    },
    {
      ...generalColumnDefs.actions,
      cellRenderer: DeviceSoftwareInventoryGridRowActions
    }
  ];
}
