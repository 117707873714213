import React from 'react';
import ReactDOM, { Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import App from '../../App';
import { languages } from '../../core/models/language';
import store from '../../core/store/root.store';
import { rootElementProd } from '../../root-element';
import { setAppPrefix, setJwt, setLanguage, setNavigationRoute, setOptions, setTheme } from '../state/ui-frame.slice';

const observedAttributes = ['navigation-route', 'jwt-token', 'theme', 'language', 'prefix', 'options'] as const;
type ObservedAttribute = (typeof observedAttributes)[number];

class CustomElement extends HTMLElement {
  private root: Root | undefined;

  constructor() {
    super();
  }

  connectedCallback(): void {
    this.root = ReactDOM.createRoot(rootElementProd);

    this.root.render(
      <React.StrictMode>
        <Provider store={store}>
          <MemoryRouter>
            <App />
          </MemoryRouter>
        </Provider>
      </React.StrictMode>
    );
  }

  disconnectedCallback(): void {
    this.root?.unmount();
  }

  static get observedAttributes(): typeof observedAttributes {
    return observedAttributes;
  }

  attributeChangedCallback(name: ObservedAttribute, oldValue: string, newValue: string): void {
    switch (name) {
      case 'navigation-route':
        store.dispatch(setNavigationRoute(newValue));
        return;
      case 'jwt-token':
        store.dispatch(setJwt(newValue));
        return;
      case 'theme':
        store.dispatch(setTheme(newValue === 'synapse-dark' ? 'dark' : 'light'));
        return;
      case 'language': {
        const language = languages.find(lang => lang.includes(newValue)) ?? 'en-US';
        store.dispatch(setLanguage(language));
        return;
      }
      case 'options': {
        const options = JSON.parse(newValue);
        store.dispatch(setOptions(options));
        return;
      }
      case 'prefix': {
        store.dispatch(setAppPrefix(newValue));
        return;
      }
    }
  }
}

export default CustomElement;
