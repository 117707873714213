import { ReactElement, useState } from 'react';
import UpdateDrawer from './UpdateDrawer';
import UpdateDrawerInfoPanel from './UpdateDrawerInfoPanel';
import DeviceUpdates from '../../models/DeviceUpdates';

interface CompanyDeviceUpdateDrawerProps {
  onClose: () => void;
  selectedDeviceUpdates: DeviceUpdates[];
  isInstallationPending: boolean;
  setInstallationPending: (isPending: boolean) => void;
}

export default function CompanyDeviceUpdateDrawer({
  onClose,
  selectedDeviceUpdates,
  isInstallationPending,
  setInstallationPending
}: CompanyDeviceUpdateDrawerProps): ReactElement {
  const [installOptionalWindowsUpdates, setInstallOptionalWindowsUpdates] = useState(true);
  const [installWindowsUpdates, setInstallWindowsUpdates] = useState(true);
  const [installAppUpdates, setInstallAppUpdates] = useState(true);

  return (
    <UpdateDrawer
      onClose={onClose}
      selectedDeviceUpdates={selectedDeviceUpdates}
      isInstallationPending={isInstallationPending}
      setInstallationPending={setInstallationPending}
      installAppUpdates={installAppUpdates}
      installWindowsUpdates={installWindowsUpdates}
      installOptionalWindowsUpdates={installOptionalWindowsUpdates}>
      <UpdateDrawerInfoPanel
        selectedDeviceUpdates={selectedDeviceUpdates}
        installWindowsUpdates={installWindowsUpdates}
        setInstallWindowsUpdates={setInstallWindowsUpdates}
        installOptionalWindowsUpdates={installOptionalWindowsUpdates}
        setInstallOptionalWindowsUpdates={setInstallOptionalWindowsUpdates}
        installAppUpdates={installAppUpdates}
        setInstallAppUpdates={setInstallAppUpdates}
      />
    </UpdateDrawer>
  );
}
