import { Button, DialogProps } from '@getgo/chameleon-web-react-wrapper';
import { DialogContextProps } from './DialogProvider';
import { Trans, useTranslation } from 'react-i18next';
import { navigateToApp } from '../../services/navigation.service';

function useProDevicesDialog({ show: showDialog, close: closeDialog }: Pick<DialogContextProps, 'show' | 'close'>): {
  showProDevicesDialog: () => void;
} {
  const { t } = useTranslation();

  const props: DialogProps = {
    open: true,
    closable: true,
    title: t('proDeviceDialog.title'),
    children: <Trans i18nKey="proDeviceDialog.text" />,
    actions: (
      <>
        <Button size="medium" onClick={() => navigateToApp({ path: '/devices/manage-devices' })}>
          {t('proDeviceDialog.actions.gotoDevices')}
        </Button>
        <Button size="medium" variant="tertiary-neutral" onClick={closeDialog}>
          {t('dialog.actions.close')}
        </Button>
      </>
    )
  };

  return { showProDevicesDialog: () => showDialog(props) };
}

export default useProDevicesDialog;
