import { HelpdeskTicketResponse } from '../models/helpdesk-ticket-response';
import http from '../../core/services/http/default-http-client';
import { combineUrl, getRandomId } from '../../core/utilities/utilities';
import { environment } from '../../environments/environment';
import logger from '../../core/services/logger.service';
import { HelpdeskService } from '../models/helpdesk-service';
import { HelpdeskTicketData } from '../models/helpdesk-ticket-data';
import { HelpdeskTicketPayload } from '../models/helpdesk-ticket-payload';
import { HelpdeskServiceCategoryResponse, HelpdeskServiceResponseData } from '../models/helpdesk-service-response';
import { WindowsUpdate, WindowsUpdateDto } from '../models/windows-update';
import Device from '../../core/models/Device';
import { t } from '../../i18n/i18n';

class HelpdeskTicketService {
  async getHelpdeskServices(): Promise<HelpdeskService[]> {
    const helpdeskServices = await http.get<HelpdeskServiceResponseData>(
      combineUrl(environment.helpdeskApiUrl, '/api/v1/dashboards/get_services')
    );

    return await Promise.all(
      helpdeskServices.services.map<Promise<HelpdeskService>>(async service => {
        const { data } = await http.get<HelpdeskServiceCategoryResponse>(
          combineUrl(
            environment.helpdeskApiUrl,
            `/api/v1/services/${service.id}.json?include=categories,service_priorities`
          )
        );
        return { ...service, id: String(service.id), priorities: data.service_priorities, categories: data.categories };
      })
    );
  }

  createDefaultTicketData({
    device,
    helpdeskServices,
    isFormDisabled,
    updates
  }: {
    device: Device;
    helpdeskServices: HelpdeskService[];
    isFormDisabled: boolean;
    updates: WindowsUpdate[];
  }): HelpdeskTicketData {
    const severity = this.getHighestSeverityLevel(updates);
    const numberOfUpdates = updates.length;
    const optionalUpdateCount = updates.reduce((sum, u) => sum + (u.isMandatory ? 0 : 1), 0);
    const deviceWithNoOfUpdatesAndSeverity = {
      ...device,
      deviceName: device.name,
      deviceId: device.id,
      numberOfUpdates,
      severity,
      optionalUpdateCount
    };

    const priority = helpdeskServices.length
      ? helpdeskServices[0].priorities.find(priority => priority.description === 'Medium')
      : undefined;

    const firstServiceId = helpdeskServices.length ? helpdeskServices[0].id : '';
    const firstCategoryOfService = helpdeskServices.length ? helpdeskServices[0].categories[0].id : '';

    return {
      subject: t('ticketCreationDialog.values.subject', deviceWithNoOfUpdatesAndSeverity),
      summary: t('ticketCreationDialog.values.summary', deviceWithNoOfUpdatesAndSeverity),
      serviceId: !isFormDisabled ? String(firstServiceId) : '',
      categoryId: !isFormDisabled ? firstCategoryOfService : '',
      priorityId: !isFormDisabled ? String(priority?.id ?? '') : ''
    };
  }

  async createTicket(ticketData: HelpdeskTicketData): Promise<{
    id: string;
    serviceId: string;
    referenceId: string;
  }> {
    // ¯\_(ツ)_/¯ https://bitbucket.3amlabs.net/projects/RC/repos/synapse-ticketing-ui/browse/src/components/incidents/edit-form-component.js#1568
    const tempId = getRandomId(15);

    const payload: HelpdeskTicketPayload = {
      data: {
        type: 'incidents',
        attributes: {
          title: ticketData.subject
        },
        relationships: {
          service: {
            data: {
              type: 'service',
              id: Number(ticketData.serviceId)
            }
          },
          priority: {
            data: {
              id: Number(ticketData.priorityId),
              type: 'priority'
            }
          },
          category: {
            data: {
              type: 'category',
              id: Number(ticketData.categoryId)
            }
          },
          symptoms: {
            data: {
              method: 'create',
              type: 'symptoms',
              'temp-id': tempId
            }
          }
        }
      },
      included: [
        { attributes: { note: ticketData.summary, note_type: 'Symptom' }, 'temp-id': tempId, type: 'symptoms' }
      ]
    };

    try {
      const result = await http.post<HelpdeskTicketPayload, HelpdeskTicketResponse>(
        combineUrl(environment.helpdeskApiUrl, '/api/v1/incidents.json'),
        payload
      );

      return {
        id: result.data.id,
        serviceId: result.data.relationships.service.data.id,
        referenceId: String(result.data.attributes.reference_num)
      };
    } catch (error) {
      logger.logError(error as Error);
      throw error;
    }
  }

  private getHighestSeverityLevel(updates: WindowsUpdateDto[]): string {
    let highestSeverity = 'Low';
    for (const update of updates) {
      switch (update.severity) {
        case 'Critical': {
          return 'Critical';
        }
        case 'Important': {
          highestSeverity = 'Important';
          break;
        }
        case 'Moderate': {
          if (highestSeverity !== 'Important') {
            highestSeverity = 'Moderate';
          }
          break;
        }
      }
    }
    return highestSeverity;
  }
}

const helpdeskTicketService = new HelpdeskTicketService();

export default helpdeskTicketService;
