import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { environment } from '../../environments/environment';

function AgGridLicenseProvider({ children }: PropsWithChildren): ReactElement | null {
  useEffect(() => {
    LicenseManager.setLicenseKey(environment.agGridLicense);
  }, []);

  return <>{children}</>;
}

export default AgGridLicenseProvider;
