import { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';
import { RadioButton, RadioGroup, RadioGroupProps, Typography } from '@getgo/chameleon-web-react-wrapper';
import { useTranslation } from 'react-i18next';
import ForceRebootDelayOption from './ForceRebootDelayOption';
import styles from './ForceRebootDelayPicker.module.scss';
import { RadioGroupComponent } from '@getgo/chameleon-web';

interface ForceRebootDelayOptionsProps {
  value: ForceRebootDelayOption;
  onChange: (option: ForceRebootDelayOption) => void;
}

export default function ForceRebootDelayPicker({
  value,
  onChange,
  ...radioProps
}: Omit<RadioGroupProps, 'onChange' | 'value'> & ForceRebootDelayOptionsProps): ReactElement {
  const { t } = useTranslation();
  const options: [ForceRebootDelayOption, string][] = useMemo(
    () => [
      [ForceRebootDelayOption.Once, t('updateDrawer.forceReboot.delayOptions.once')],
      [ForceRebootDelayOption.Twice, t('updateDrawer.forceReboot.delayOptions.twice')],
      [ForceRebootDelayOption.Thrice, t('updateDrawer.forceReboot.delayOptions.thrice')],
      [ForceRebootDelayOption.Not, t('updateDrawer.forceReboot.delayOptions.not')]
    ],
    [t]
  );
  const onRadioChange = useCallback(
    ({ target }: ChangeEvent<RadioGroupComponent>) => onChange(target.value as ForceRebootDelayOption),
    [onChange]
  );

  return (
    <div>
      <Typography variant="caption-medium-strong">{t('updateDrawer.forceReboot.delayOptions.labelLineOne')}</Typography>
      <Typography variant="caption-medium-strong">{t('updateDrawer.forceReboot.delayOptions.labelLineTwo')}</Typography>
      <RadioGroup value={value} onChange={onRadioChange} {...radioProps} className={styles.delayRadioGroup}>
        {options.map(([key, name]) => (
          <RadioButton value={key} key={key}>
            <Typography variant="body-small">{name}</Typography>
          </RadioButton>
        ))}
      </RadioGroup>
    </div>
  );
}
