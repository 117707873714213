import { ICellRendererParams } from '@ag-grid-community/core';
import React, { ReactElement } from 'react';
import { DeviceJobGridRow } from '../../models/device-job-grid-row';
import { useTranslation } from 'react-i18next';
import styles from './DeviceScheduledForCellRenderer.module.scss';
import { useAppSelector } from '../../../core/store/root.store';
import { selectLanguage } from '../../../ui-frame/state/ui-frame.slice';
import { DateTime } from 'luxon';

function DeviceScheduledForCellRenderer({
  value,
  data
}: ICellRendererParams<DeviceJobGridRow, DeviceJobGridRow['scheduledAt']>): ReactElement | null {
  const { t } = useTranslation();
  const language = useAppSelector(selectLanguage);

  if (!value || !data) {
    return null;
  }
  const { scheduledAtTimeZone, scheduledAt } = data;

  const valueDateTime = DateTime.fromJSDate(value);

  const dateInHostTimeZone = DateTime.fromObject(
    {
      year: valueDateTime.year,
      month: valueDateTime.month,
      day: valueDateTime.day,
      hour: valueDateTime.hour,
      minute: valueDateTime.minute
    },
    { zone: scheduledAtTimeZone }
  );

  const offsetInMinutes =
    !!scheduledAtTimeZone && !!scheduledAt ? dateInHostTimeZone.offset - DateTime.local().offset : 0;

  return (
    <div className={styles.content}>
      {`${Intl.DateTimeFormat(language, {
        dateStyle: 'medium',
        timeStyle: 'medium'
      }).format(dateInHostTimeZone.toLocal().toJSDate())}`}
      {offsetInMinutes ? (
        <>
          <br />
          {t(`updateDetails.grid.scheduledForHourOffset${offsetInMinutes < 0 ? 'Behind' : 'Ahead'}`, {
            count: Math.abs(offsetInMinutes) / 60
          })}
        </>
      ) : null}
    </div>
  );
}

export default DeviceScheduledForCellRenderer;
