import useSwr, { SWRResponse } from 'swr';
import { combineUrl } from '../../core/utilities/utilities';
import { environment } from '../../environments/environment';
import http from '../../core/services/http/default-http-client';
import { DeviceJobWithUpdateCountDto } from '../models/device-job-with-update-count';

export const deviceUpdateJobCacheKey = (jobId: string): string =>
  combineUrl(environment.frontendApiUrl, `jobs/${jobId}/all/devices`);

export const useDeviceUpdateJob = (jobId: string): SWRResponse<DeviceJobWithUpdateCountDto[], unknown> =>
  useSwr(deviceUpdateJobCacheKey(jobId), url => http.get<DeviceJobWithUpdateCountDto[]>(url));
