import { useEffect } from 'react';
import liveUpdatesService from '../../core/services/live-updates.service';
import { jobsListCacheKey } from '../models/cache';
import { useSWRConfig } from 'swr';

export const useUpdateJobsCreatedLiveData = (): void => {
  const { mutate } = useSWRConfig();
  useEffect(() => {
    const runCreatedHandler = (): void => void mutate(jobsListCacheKey);
    liveUpdatesService.on('RunCreated', runCreatedHandler);
    return () => {
      liveUpdatesService.off('RunCreated', runCreatedHandler);
    };
  }, [mutate]);
};
