import { PropsWithChildren, ReactElement, useEffect } from 'react';
import tracking from '../../services/tracking/tracking.service';
import { selectUser } from '../../state/user.slice';
import { useAppSelector } from '../../store/root.store';
import { matchPath } from 'react-router-dom';
import { routes } from '../../models/routes';
import { selectNavigationRoute } from '../../../ui-frame/state/ui-frame.slice';
import pageViewTrackingEvents from '../../services/tracking/tracking-page-view-events';

function TrackingProvider({ children }: PropsWithChildren): ReactElement | null {
  const user = useAppSelector(selectUser);
  const navigationRoute = useAppSelector(selectNavigationRoute);

  useEffect(() => {
    if (user) {
      tracking.init(user.id, user.company.id);
    }
  }, [user]);

  useEffect(() => {
    const matchedPath = routes.find(r => matchPath(r, navigationRoute));
    if (!matchedPath) {
      console.warn('could not match path', navigationRoute);
      return;
    }
    const trackingEvent = pageViewTrackingEvents[matchedPath.path];
    console.debug('page_view', trackingEvent);
    tracking.startTrackPage(navigationRoute);
    return () => tracking.stopTrackPage(navigationRoute);
  }, [navigationRoute]);

  return user ? <>{children}</> : null;
}

export default TrackingProvider;
