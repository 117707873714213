import { Button, DialogProps } from '@getgo/chameleon-web-react-wrapper';
import { DialogContextProps } from './DialogProvider';
import { useTranslation } from 'react-i18next';
import Device from '../../models/Device';
import {
  areAppUpdateCapabilitiesLimited,
  isDeviceOsNotActivated
} from '../../../windows-updates/utilities/device-utilities';
import LimitedCapabilitiesInfo from './LimitedCapabilitiesInfo';

function useLimitedUpdateCapabilitiesDialog({
  show: showDialog,
  close: closeDialog
}: Pick<DialogContextProps, 'show' | 'close'>): {
  showLimitedUpdateCapabilitiesDialog: (targetDevices: Device[], installFunc: () => Promise<void>) => void;
} {
  const { t } = useTranslation();

  return {
    showLimitedUpdateCapabilitiesDialog: (targetDevices, installFunc) => {
      const osNotActivatedDevices = targetDevices.filter(device => isDeviceOsNotActivated(device));
      const wingetInaccessibleDevices = targetDevices.filter(device => areAppUpdateCapabilitiesLimited(device));
      const props: DialogProps = {
        open: true,
        closable: true,
        title: t('limitedUpdateCapabilitiesDialog.title'),
        children: (
          <LimitedCapabilitiesInfo
            osNotActivatedDevices={osNotActivatedDevices}
            wingetInaccessibleDevices={wingetInaccessibleDevices}
          />
        ),
        actions: (
          <>
            <Button
              size="medium"
              onClick={() => {
                installFunc();
                closeDialog();
              }}>
              {t('limitedUpdateCapabilitiesDialog.actions.proceed')}
            </Button>
            <Button
              size="medium"
              variant="tertiary"
              onClick={() =>
                window.open(
                  'https://support.goto.com/resolve/help/working-with-windows-updates-in-goto-resolve',
                  '_blank'
                )
              }>
              {t('limitedUpdateCapabilitiesDialog.actions.learnMore')}
            </Button>
          </>
        )
      };

      showDialog(props);
    }
  };
}
export default useLimitedUpdateCapabilitiesDialog;
