import { WindowsUpdate } from '../models/windows-update';
import useSwr from 'swr';
import http from '../../core/services/http/default-http-client';
import { combineUrl } from '../../core/utilities/utilities';
import { environment } from '../../environments/environment';
import { AllUpdatesJobByDeviceResponse } from '../models/windows-update-job-by-device-response';
import { ApplicationUpdate } from '../models/application/application-update';

interface JobDeviceUpdatesData {
  jobName: string;
  deviceName: string;
  isLoading: boolean;
  isError: boolean;
  allUpdates: AllUpdates;
}

export interface AllUpdates {
  osUpdates: WindowsUpdate[];
  appUpdates: ApplicationUpdate[];
}

export const useJobDeviceUpdates = (jobId: string, deviceId: string): JobDeviceUpdatesData => {
  const { data, error } = useSwr(
    combineUrl(environment.frontendApiUrl, `jobs/${jobId}/all/devices/${deviceId}/updates`),
    url => http.get<AllUpdatesJobByDeviceResponse>(url)
  );

  if (!data || error) {
    return {
      jobName: '',
      deviceName: '',
      allUpdates: {
        osUpdates: [],
        appUpdates: []
      },
      isError: error,
      isLoading: !error && !data
    };
  }

  const { osUpdates: updatesDto, jobName, deviceName, appUpdates } = data;
  const osUpdates = updatesDto.map<WindowsUpdate>(update => ({
    ...update,
    kbArticleIds: update.kbArticleIds?.map(kbArticleId => `KB${kbArticleId}`) ?? [],
    severity: update.severity ?? 'Low'
  }));

  return {
    jobName,
    deviceName,
    allUpdates: {
      osUpdates,
      appUpdates
    },
    isError: false,
    isLoading: false
  };
};
