import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement } from 'react';
import UpdateStatus from '../../../../models/UpdateStatus';
import UpdateStatusCells from '../../../../grid/cell-renderers/UpdateStatusCells';
import CompanyDeviceUpdateStatus from '../../../../models/CompanyDeviceUpdateStatus';
import { numberOfOptionalWindowsUpdates, numberOfUpdates } from '../../DeviceUpdatesStatistics';
import { areAppUpdateCapabilitiesLimited, isDeviceOsNotActivated } from '../../../../utilities/device-utilities';

export default function UpdateStatusCellRenderer({
  value,
  data: updateRow
}: ICellRendererParams<CompanyDeviceUpdateStatus, UpdateStatus>): ReactElement | null {
  const appUpdateCapabilitiesLimited = updateRow?.device ? areAppUpdateCapabilitiesLimited(updateRow.device) : false;
  const osNotActivated = updateRow?.device ? isDeviceOsNotActivated(updateRow?.device) : true;
  if (osNotActivated) {
    if (value === UpdateStatus.Vulnerable) {
      return <UpdateStatusCells.VulnerableLimitedCapabilities />;
    }
  }
  if (appUpdateCapabilitiesLimited) {
    return osNotActivated ? <UpdateStatusCells.OsNotActivated /> : <UpdateStatusCells.LimitedCapabilities />;
  }
  switch (value) {
    case UpdateStatus.UpToDate:
      return <UpdateStatusCells.UpToDate />;
    case UpdateStatus.UpdatesAvailable:
      return <UpdateStatusCells.UpdatesAvailable count={updateRow ? numberOfUpdates(updateRow.updates) : 0} />;
    case UpdateStatus.Vulnerable:
      return <UpdateStatusCells.Vulnerable />;
    case UpdateStatus.UpToDateWithOptionalUpdates:
      return (
        <UpdateStatusCells.UpdatesAvailable count={updateRow ? numberOfOptionalWindowsUpdates(updateRow.updates) : 0} />
      );
    case UpdateStatus.Unknown:
      return <UpdateStatusCells.Unknown />;
  }
  return null;
}
