import { TooltipV2, TooltipV2Props } from '@getgo/chameleon-web-react-wrapper';
import clsx from 'clsx';
import { ReactElement, useEffect, useState } from 'react';
import styles from './Tooltip.module.scss';

function Tooltip(props: TooltipV2Props): ReactElement {
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!wrapperRef) {
      return;
    }

    const triggerElement = wrapperRef.parentElement?.children[0];

    if (!triggerElement) {
      return;
    }

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type !== 'attributes' || mutation.attributeName !== 'disabled') {
          return;
        }

        setDisabled((mutation.target as HTMLInputElement | HTMLButtonElement).disabled ?? false);
      });
    });

    observer.observe(triggerElement, { attributes: true });
    setDisabled((triggerElement as HTMLInputElement | HTMLButtonElement).disabled ?? false);

    return () => {
      observer.disconnect();
    };
  }, [wrapperRef]);

  return (
    <TooltipV2
      {...props}
      trigger={
        <div>
          {props.trigger}
          {/* HACK: position a div on top of the tooltip trigger element to have an element that fires mouse events when trigger element is disabled */}
          <div ref={setWrapperRef} className={clsx(styles.tooltipWrapper, disabled && styles.disabled)}></div>
        </div>
      }
    />
  );
}

export default Tooltip;
