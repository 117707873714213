import { createSlice } from '@reduxjs/toolkit';
import { setJwt, setMockUiFrameData } from '../../ui-frame/state/ui-frame.slice';
import { User } from '../models/user';
import { getUserFromJwt } from '../services/user.service';
import { RootState } from '../store/root.store';

// state
export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null
};

// slice (actions and reducers)
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(setJwt, (state, action) => {
        state.user = getUserFromJwt(action.payload);
      })
      .addCase(setMockUiFrameData, (state, action) => {
        state.user = getUserFromJwt(action.payload.jwt);
      })
});

// selectors
export const selectUser = (state: RootState): User | null => state.user.user;

export default userSlice.reducer;
