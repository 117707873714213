import { useEffect, useState } from 'react';
import liveUpdatesService from '../../core/services/live-updates.service';
import { HostRunChangedData } from '@goto/remote-execution';
import { DeviceJobDto } from '../models/device-job';

type DeviceJobLiveRow = Pick<DeviceJobDto, 'status' | 'finishedAt' | 'hasLog' | 'failureReason'> & {
  deviceId: string;
};

interface DeviceJobLiveData {
  deviceJobLiveRows: Record<string, DeviceJobLiveRow>;
}

export const useDeviceUpdateJobLiveData = (jobId: string): DeviceJobLiveData => {
  const [deviceJobLiveRows, setDeviceJobLiveRows] = useState<Record<string, DeviceJobLiveRow>>({});

  useEffect(() => {
    // whenever jobId changes, clear
    setDeviceJobLiveRows({});

    const handler = (hostRun: HostRunChangedData): void => {
      if (hostRun.runId !== jobId) {
        return;
      }

      const rowData: DeviceJobLiveRow = {
        finishedAt: hostRun.finishedAt,
        hasLog: hostRun.hasLog,
        status: hostRun.status,
        failureReason: hostRun.failureReason,
        deviceId: `${hostRun.hostId}`
      };

      setDeviceJobLiveRows(prevState => ({ ...prevState, [rowData.deviceId]: rowData }));
    };
    liveUpdatesService.on('HostRunChanged', handler);
    return () => {
      liveUpdatesService.off('HostRunChanged', handler);
    };
  }, [jobId]);

  return {
    deviceJobLiveRows
  };
};
