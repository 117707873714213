import axios, { AxiosInstance } from 'axios';
import type EnvironmentConfig from '../../../environments/EnvironmentConfig';
import AccessTokenProvider from '../auth/AccessTokenProvider';

export default function (environment: EnvironmentConfig, accessTokenProvider: AccessTokenProvider): AxiosInstance {
  const patchManagementAxios = axios.create({
    baseURL: environment.frontendApiUrl
  });
  patchManagementAxios.interceptors.request.use(async requestConfig => {
    const token = await accessTokenProvider();
    if (!token) {
      throw new Error('Could not get a token to send request ' + requestConfig);
    }
    requestConfig.headers['Authorization'] = `Bearer ${token}`;
    return requestConfig;
  });
  return patchManagementAxios;
}
