import { Snackbar, SnackbarProps } from '@getgo/chameleon-web-react-wrapper';
import { createContext, PropsWithChildren, ReactElement, useContext, useState } from 'react';
import styles from './SnackbarProvider.module.scss';
import clsx from 'clsx';

interface SnackbarContextProps {
  show: (props: SnackbarProps) => void;
}

const SnackbarContext = createContext<SnackbarContextProps>({} as SnackbarContextProps);

export function SnackbarProvider({ children }: PropsWithChildren): ReactElement {
  const [visible, setVisible] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps | undefined>(undefined);
  const snackbarVisible = visible && snackbarProps;

  const show = (props: SnackbarProps): void => {
    setVisible(true);
    setSnackbarProps({ ...props, className: clsx(styles.customSnackbar, props.className) });
    setTimeout(() => setVisible(false), 8000);
  };

  return (
    <SnackbarContext.Provider
      value={{
        show
      }}>
      <>
        {snackbarVisible && (
          <div className={styles.snackbarWrapper}>
            <Snackbar {...snackbarProps}></Snackbar>
          </div>
        )}
        {children}
      </>
    </SnackbarContext.Provider>
  );
}

export function useSnackbar(): SnackbarContextProps {
  return useContext(SnackbarContext);
}
