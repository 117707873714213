import { AlertOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Alert, Button, Option, Select, TextArea, TextField, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ExternalLink from '../../../core/components/ExternalLink';
import LoadingOverlay from '../../../core/components/grid/loading-overlay/LoadingOverlay';
import { useSnackbar } from '../../../core/components/snackbar/SnackbarProvider';
import { navigateToApp } from '../../../core/services/navigation.service';
import { Category } from '../../models/helpdesk-service';
import { HelpdeskTicketData } from '../../models/helpdesk-ticket-data';
import { CreatedHelpdeskTicketInfo } from '../../models/helpdesk-ticket-response';
import { useTicketFormData } from '../../services/helpdesk-ticket.hooks';
import helpdeskTicketService from '../../services/helpdesk-ticket.service';
import styles from './CreateTicketForm.module.scss';

export interface FormProps {
  deviceId: string;
  closeDialog: () => void;
}

function CreateTicketForm({ deviceId, closeDialog }: FormProps): ReactElement {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const { loading, helpdeskServices, initialTicketData, isFormDisabled } = useTicketFormData(deviceId);
  const [ticketData, setTicketData] = useState<HelpdeskTicketData>({
    categoryId: '',
    priorityId: '',
    serviceId: '',
    subject: '',
    summary: ''
  });
  const [submitError, setSubmitError] = useState(false);

  const isCreateTicketDisabled = isFormDisabled || !ticketData.subject;
  const selectedService = helpdeskServices?.find(s => s.id === ticketData.serviceId);
  const categories = selectedService?.categories;
  const firstCategory = categories?.at(0)?.id;
  const errorMessage = t('ticketCreationDialog.emptyFieldError');

  useEffect(() => {
    if (!loading && initialTicketData) {
      setTicketData(current => (Object.values(current).join('') === '' ? initialTicketData : current));
    }
  }, [initialTicketData, loading]);

  useEffect(() => {
    if (!ticketData.serviceId) {
      return;
    }

    // let's select the first category when we switch to a new service
    setTicketData(current => ({ ...current, categoryId: firstCategory ?? '' }));
  }, [firstCategory, !ticketData.serviceId]);

  const handleInputChange = (field: keyof typeof ticketData) => (event: ChangeEvent<{ value?: string }>) => {
    setTicketData((ticketData: HelpdeskTicketData) => ({ ...ticketData, [field]: event.target.value }));
  };

  const handleCreateTicketClick = async (): Promise<void> => {
    try {
      const ticketInfo: CreatedHelpdeskTicketInfo = await helpdeskTicketService.createTicket(ticketData);
      snackbar.show({
        title: t('ticketCreationDialog.ticketCreated', ticketInfo),
        actions: (
          <Button
            size="small"
            variant="neutral-inverse"
            onClick={() =>
              navigateToApp({
                path: '/helpdesk/services/:serviceId/incidents/edit/:referenceId',
                params: {
                  serviceId: ticketInfo.serviceId,
                  referenceId: ticketInfo.referenceId
                }
              })
            }>
            {t('snackbar.actions.seeDetails')}
          </Button>
        )
      });
      closeDialog();
    } catch {
      setSubmitError(true);
    }
  };

  if (loading) {
    return <LoadingOverlay className={styles.loading} />;
  }

  return (
    <div className={styles.createTicketForm}>
      {isFormDisabled && (
        <Alert className={styles.createTicketFormAlert} variant={'danger'} icon={<AlertOutlinedIcon />}>
          <Trans
            i18nKey={'ticketCreationDialog.noHelpdeskAlert'}
            components={{
              helpdeskServiceLink: (
                <ExternalLink to={'https://support.goto.com/resolve/help/how-do-i-create-helpdesk-services'} />
              )
            }}
          />
        </Alert>
      )}
      {submitError && <Alert variant={'danger'}>{t('ticketCreationDialog.submitUnsuccessful')}</Alert>}

      <div className={styles.createTicketFormDetails}>
        <Typography variant={'body-medium'}>{t('ticketCreationDialog.text')}</Typography>
      </div>
      <div className={styles.createTicketFormSubject}>
        <TextField
          labelId="subject"
          error={!ticketData.subject}
          helperText={!ticketData.subject ? errorMessage : undefined}
          disabled={isFormDisabled}
          fullwidth
          fieldsize="large"
          value={ticketData.subject}
          onChange={event => handleInputChange('subject')(event)}>
          {t('ticketCreationDialog.labels.subject')}
        </TextField>
      </div>
      <div className={styles.createTicketFormSummary}>
        <TextArea
          labelId="summary"
          disabled={isFormDisabled}
          fullwidth
          resize="vertical"
          className={styles.summaryFormControl}
          value={ticketData.summary}
          onChange={event => handleInputChange('summary')(event)}>
          {t('ticketCreationDialog.labels.summary')}
        </TextArea>
      </div>
      <div className={styles.createTicketFormSelect}>
        <Select
          disabled={isFormDisabled}
          inDialog={true}
          fullwidth
          size={'large'}
          label={t('ticketCreationDialog.labels.helpdesk')}
          value={ticketData.serviceId}
          onChange={event => handleInputChange('serviceId')(event)}>
          {helpdeskServices.length &&
            helpdeskServices.map(helpdeskService => (
              <Option key={helpdeskService.id} value={helpdeskService.id}>
                {helpdeskService.name}
              </Option>
            ))}
        </Select>
      </div>
      <div className={styles.createTicketFormSelect}>
        <Select
          disabled={isFormDisabled}
          inDialog={true}
          fullwidth
          size={'large'}
          label={t('ticketCreationDialog.labels.category')}
          value={ticketData.categoryId}
          onChange={event => handleInputChange('categoryId')(event)}>
          {categories &&
            categories.map((category: Category) => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
        </Select>
      </div>
      <div className={styles.createTicketFormButtons}>
        <Button disabled={isCreateTicketDisabled} onClick={handleCreateTicketClick}>
          {t('ticketCreationDialog.actions.createTicket')}
        </Button>
        <Button variant={'tertiary'} onClick={closeDialog}>
          {t('dialog.actions.close')}
        </Button>
      </div>
    </div>
  );
}

export default CreateTicketForm;
