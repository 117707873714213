import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CellText } from './CellText';

export type JobUpdateCountCellRendererProps = {
  osMandatoryUpdateCount: number;
  osOptionalUpdateCount: number;
  appUpdateCount: number;
};

function JobUpdateCountCellRenderer({
  osMandatoryUpdateCount,
  osOptionalUpdateCount,
  appUpdateCount
}: JobUpdateCountCellRendererProps): ReactElement | string {
  const { t } = useTranslation();

  return (
    <>
      {osMandatoryUpdateCount === undefined || osOptionalUpdateCount === undefined ? (
        <CellText>{t('activeAndPastUpdates.grid.updateCount.all')}</CellText>
      ) : (
        <>
          {evaluateOsMandatoryUpdatesCount()}
          {evaluateOsOptionalUpdatesCount()}
          {evaluateAppUpdatesCount()}
        </>
      )}
    </>
  );

  function evaluateOsOptionalUpdatesCount(): JSX.Element | undefined {
    if (osOptionalUpdateCount) {
      return (
        <CellText>{t('activeAndPastUpdates.grid.osUpdateCount.optional', { count: osOptionalUpdateCount })}</CellText>
      );
    }
  }

  function evaluateOsMandatoryUpdatesCount(): JSX.Element | undefined {
    if (osMandatoryUpdateCount) {
      return (
        <CellText>{t('activeAndPastUpdates.grid.osUpdateCount.mandatory', { count: osMandatoryUpdateCount })}</CellText>
      );
    }
  }

  function evaluateAppUpdatesCount(): JSX.Element | undefined {
    if (appUpdateCount) {
      return <CellText>{t('activeAndPastUpdates.grid.appUpdateCount.count', { count: appUpdateCount })}</CellText>;
    }
  }
}

export default JobUpdateCountCellRenderer;
