import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';
import styles from './InfoPanel.module.scss';

interface InfoPanelProps {
  className?: string;
}

function InfoPanel({ children, className }: PropsWithChildren<InfoPanelProps>): ReactElement {
  return <div className={clsx(styles.infoPanel, className)}>{children}</div>;
}

export default InfoPanel;
