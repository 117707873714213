import { ReactElement } from 'react';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import styles from './ErrorFallback.module.scss';
import { t } from '../../../i18n/i18n';
export function ErrorFallback(): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.emoji}>🔧</div>
      <Typography tag="h1" variant="avatar-xlarge" className={styles.description}>
        {t('errorFallback.title')}
      </Typography>
      <Typography className={styles.subtext} variant="body-medium" color="type-color-disabled">
        {t('errorFallback.description')}
      </Typography>
    </div>
  );
}
