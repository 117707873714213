import { DownloadFilledIcon } from '@getgo/chameleon-icons/react';
import { Button } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileResponse } from '../../../core/models/file-response';
import { Guid } from '../../../core/models/guid';
import fileService from '../../../core/services/file/file.service';
import http from '../../../core/services/http/default-http-client';
import logger from '../../../core/services/logger.service';
import { combineUrl } from '../../../core/utilities/utilities';
import { environment } from '../../../environments/environment';

interface Props {
  updateJobId: Guid;
  deviceId: Guid;
  logAvailable: boolean;
}

function DownloadLog({ updateJobId, deviceId, logAvailable }: Props): ReactElement {
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);

  const handleDownloadLogClick = async (): Promise<void> => {
    setDownloading(true);

    try {
      await downloadLog(deviceId);
    } catch (error) {
      logger.logError(error as Error);
    } finally {
      setDownloading(false);
    }
  };

  const getLog = async (deviceId: Guid): Promise<FileResponse> => {
    return http.getFile(
      combineUrl(environment.remoteExecutionFrontendApiUrl, `automation/runs/${updateJobId}/hosts/${deviceId}/log`)
    );
  };

  const downloadLog = async (deviceId: Guid): Promise<void> => {
    const { file, filename } = await getLog(deviceId);
    fileService.save(file, filename);
  };

  return (
    <Button
      leadingIcon={<DownloadFilledIcon />}
      disabled={!logAvailable}
      isLoading={downloading}
      onClick={handleDownloadLogClick}>
      {t('updateDetails.grid.actions.downloadLog')}
    </Button>
  );
}

export default DownloadLog;
