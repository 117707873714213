import { ReactElement } from 'react';
import { LoaderIcon } from '@getgo/chameleon-icons/react';
import styles from './Spinner.module.scss';

interface Props {
  size?: number;
  marginRight?: number;
  useGotoColor?: boolean;
}

function Spinner({ size = 24, marginRight = 0, useGotoColor = false }: Props): ReactElement {
  return (
    <span
      className={useGotoColor ? [styles.spinner, styles.gotoIconColor].join(' ') : styles.spinner}
      style={{ marginRight: marginRight + 'px' }}>
      <LoaderIcon size={size + 'px'} />
    </span>
  );
}

export default Spinner;
