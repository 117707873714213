import { GetQuickFilterTextParams } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import OnlineStatus from '../../../../models/OnlineStatus';

export default function useOnlineStatusQuickFilterText(): (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  param: GetQuickFilterTextParams<any, OnlineStatus>
) => string {
  const { t } = useTranslation();
  return useCallback(
    ({ value }) => {
      switch (value) {
        case OnlineStatus.Offline:
          return t('onlineStatus.offline');
        case OnlineStatus.Online:
          return t('onlineStatus.online');
        case OnlineStatus.InSession:
          return t('onlineStatus.inSession');
        default:
          throw new Error(`Unknown OnlineStatus value: ${value}`);
      }
    },
    [t]
  );
}
