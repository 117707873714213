import { lowerCaseFirstLetter } from '../../core/utilities/utilities';
import { DeviceJobGridRow } from '../models/device-job-grid-row';
import { DeviceJobWithUpdateCountDto } from '../models/device-job-with-update-count';
import { HostRunFailureReason, HostRunStatus } from '@goto/remote-execution';

export function mapToDeviceJobGridRow(deviceJobWithUpdateCount: DeviceJobWithUpdateCountDto): DeviceJobGridRow {
  const { device: deviceJob, osUpdateCount, osOptionalUpdateCount, appUpdateCount } = deviceJobWithUpdateCount;

  const effectiveStatus = getEffectiveStatus(deviceJob.status, deviceJob.failureReason);
  const statusTranslationKey = lowerCaseFirstLetter(effectiveStatus);

  const titleTranslationKey = deviceJob.failureReason
    ? `updateDetails.grid.deviceJobFailureReason.${statusTranslationKey}.title`
    : `updateDetails.grid.deviceJobStatus.${statusTranslationKey}.title`;
  const descriptionTranslationKey = deviceJob.failureReason
    ? `updateDetails.grid.deviceJobFailureReason.${statusTranslationKey}.description`
    : `updateDetails.grid.deviceJobStatus.${statusTranslationKey}.description`;

  return {
    status: {
      deviceJobStatus: deviceJob.status,
      failureReason: deviceJob.failureReason,
      effectiveStatus,
      title: titleTranslationKey,
      description: descriptionTranslationKey
    },
    deviceId: deviceJob.hostSnapshot.hostId,
    hostName: deviceJob.hostSnapshot.hostName,
    deviceName: deviceJob.hostSnapshot.name,
    finishedAt: deviceJob.finishedAt ? new Date(deviceJob.finishedAt) : undefined,
    logAvailable: !!deviceJob.finishedAt && deviceJob.hasLog,
    updateCount: osUpdateCount + osOptionalUpdateCount + appUpdateCount,
    isScheduled: deviceJob.isScheduled,
    scheduledAt: deviceJob.scheduledAt ? new Date(deviceJob.scheduledAt) : undefined,
    scheduledAtTimeZone: deviceJob.scheduledAtTimeZone,
    deviceTimeZone: deviceJob.hostSnapshot.timeZone
  };
}

function getEffectiveStatus(deviceJobStatus: HostRunStatus, failureReason?: HostRunFailureReason): string {
  return (
    (failureReason ? HostRunFailureReason[failureReason] : HostRunStatus[deviceJobStatus]) ??
    HostRunFailureReason[HostRunFailureReason.UnknownError]
  );
}
