import { CloseFilledIcon } from '@getgo/chameleon-icons/react';
import { IconButton, Typography } from '@getgo/chameleon-web-react-wrapper';
import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';
import styles from './Drawer.module.scss';

interface Props {
  show: boolean;
  title: string;
  footer?: ReactElement;
  onClose?: () => void;
  className?: string;
}

function Drawer({ children, show, title, footer, onClose, className }: PropsWithChildren<Props>): ReactElement {
  const close = (): void => {
    onClose && onClose();
  };

  return (
    <>
      <div className={clsx(styles.drawer, show && styles.drawerOpen)}>
        <div className={styles.drawerHeader}>
          <Typography className={styles.drawerHeading} variant="heading-small" tag="h2">
            {title}
          </Typography>
          <IconButton aria-label="close" variant="primary" onClick={close}>
            <CloseFilledIcon />
          </IconButton>
        </div>
        <div className={clsx(styles.drawerContent, className)}>{children}</div>
        {footer && <div className={styles.drawerFooter}>{footer}</div>}
      </div>
    </>
  );
}

export default Drawer;
