import Device from '../../core/models/Device';
import http from '../../core/services/http/default-http-client';
import { combineUrl } from '../../core/utilities/utilities';
import { environment } from '../../environments/environment';
import OnlineStatus from '../models/OnlineStatus';
import { Platform } from '../models/platform';
import { Architecture } from '../models/architecture';
import PatchManagementCapabilities from '../models/PatchManagementCapabilities';

export class DeviceService {
  async getDevice(deviceId: string): Promise<Device> {
    const response = await http.get<DeviceWebDto>(combineUrl(environment.frontendApiUrl, `devices/${deviceId}`));
    return mapToDevice(response);
  }
}

function mapToDevice(legacyDevice: DeviceWebDto): Device {
  return {
    ...legacyDevice,
    onlineStatus: mapLegacyToNewOnlineStatus(legacyDevice.onlineStatus),
    name: mapHostNameToNameIfNull(legacyDevice.name, legacyDevice.hostName),
    osActivation: legacyDevice.osActivation ? legacyDevice.osActivation === 'SL_GEN_STATE_IS_GENUINE' : undefined
  };
}

function mapLegacyToNewOnlineStatus(legacyStatus: OnlineStatusLegacyWebDto): OnlineStatus {
  switch (legacyStatus) {
    case OnlineStatusLegacyWebDto.Offline:
      return OnlineStatus.Offline;
    case OnlineStatusLegacyWebDto.Online:
      return OnlineStatus.Online;
    case OnlineStatusLegacyWebDto.InSession:
      return OnlineStatus.InSession;
  }
}

function mapHostNameToNameIfNull(name?: string, hostname?: string): string | undefined {
  return name === null ? hostname : name;
}

interface DeviceWebDto {
  id: string;
  name?: string;
  hostName?: string;
  onlineStatus: OnlineStatusLegacyWebDto;
  platform?: Platform;
  architecture?: Architecture;
  lastOnline: string;
  timeZone?: string;
  patchmanagement?: PatchManagementCapabilities;
  osActivation?: string;
}

enum OnlineStatusLegacyWebDto {
  Offline = 0,
  Online = 1,
  InSession = 2
}

export default new DeviceService();
