import {
  BlockFilledIcon,
  CheckmarkCircleFilledIcon,
  ClockOutlinedIcon,
  CloseCircleFilledIcon,
  PlayFilledIcon
} from '@getgo/chameleon-icons/react';
import { TooltipV2 } from '@getgo/chameleon-web-react-wrapper';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceJobGridRow, DeviceJobStatusWithFailureReason } from '../../models/device-job-grid-row';
import styles from './DeviceJobStatusCellRenderer.module.scss';
import { HostRunStatus } from '@goto/remote-execution';

const deviceJobStatusIconMap: Record<HostRunStatus, ReactElement> = {
  [HostRunStatus.Pending]: <ClockOutlinedIcon size="24px" class={styles.pending} />,
  [HostRunStatus.Running]: <PlayFilledIcon size="24px" class={styles.running} />,
  [HostRunStatus.Finished]: <CheckmarkCircleFilledIcon size="24px" class={styles.finished} />,
  [HostRunStatus.Cancelled]: <BlockFilledIcon size="24px" class={styles.cancelled} />,
  [HostRunStatus.Failed]: <CloseCircleFilledIcon size="24px" class={styles.failed} />
};

function DeviceJobStatusCellRenderer({
  value
}: ICellRendererParams<DeviceJobGridRow, DeviceJobStatusWithFailureReason>): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.cell}>
      <TooltipV2
        position="top-center"
        trigger={
          <div className={styles.tooltipTrigger}>
            {deviceJobStatusIconMap[value.deviceJobStatus]}
            {t(value.title as any)}
          </div>
        }>
        {t(value.description as any)}
      </TooltipV2>
    </div>
  );
}

export default DeviceJobStatusCellRenderer;
