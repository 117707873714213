import { EmptyState } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from '../DeviceDetails/DeviceDetails.module.scss';

export enum UpdateType {
  os = 'os',
  app = 'app'
}

export interface IProps {
  triggeredUpdateType: UpdateType;
}

export default function UpdatesEmptyState(props: IProps): ReactElement {
  const { t } = useTranslation();

  function Body(): ReactElement {
    return (
      <div className={styles.body}>
        <span>{evaluateUpdateType()}</span>
      </div>
    );
  }

  function evaluateUpdateType(): JSX.Element | undefined {
    return props.triggeredUpdateType === UpdateType.os ? (
      <Trans i18nKey={`jobHistoryGrid.emptyState.osUpdates`} />
    ) : (
      <Trans i18nKey={`jobHistoryGrid.emptyState.appUpdates`} />
    );
  }

  return (
    <EmptyState>
      <Body />
    </EmptyState>
  );
}
