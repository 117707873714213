import { TooltipV2 } from '@getgo/chameleon-web-react-wrapper';
import clsx from 'clsx';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Translations } from '../../../i18n/i18n';
import styles from './UpdateJobProgress.module.scss';
import { HostRunStatus, HostRunStatusCount } from '@goto/remote-execution';

interface Props {
  deviceJobStatusCounts: HostRunStatusCount[];
}

const orderMap: Record<HostRunStatus, number> = {
  [HostRunStatus.Finished]: 1,
  [HostRunStatus.Failed]: 2,
  [HostRunStatus.Running]: 4,
  [HostRunStatus.Cancelled]: 3,
  [HostRunStatus.Pending]: 5
};

const deviceJobStatusTranslationMap: Record<
  HostRunStatus,
  keyof Translations['updateDetails']['grid']['deviceJobStatus']
> = {
  [HostRunStatus.Pending]: 'pending',
  [HostRunStatus.Running]: 'running',
  [HostRunStatus.Finished]: 'finished',
  [HostRunStatus.Cancelled]: 'cancelled',
  [HostRunStatus.Failed]: 'failed'
};

function UpdateJobProgress({ deviceJobStatusCounts }: Props): ReactElement {
  const { t } = useTranslation();
  const isAnyInProgress = deviceJobStatusCounts.some(({ status }) => status === HostRunStatus.Running);

  const percentage = (current: number, total: number): number | null => {
    return total === 0 ? null : Math.round((current / total) * 100);
  };

  const total = useMemo(() => {
    return deviceJobStatusCounts
      .filter(({ count }) => count)
      .map(({ count }) => count)
      .reduce((accumulator, count) => accumulator + count, 0);
  }, [deviceJobStatusCounts]);

  const items = useMemo(() => {
    return [...deviceJobStatusCounts].sort((a, b) => {
      const aOrder = orderMap[a.status];
      const bOrder = orderMap[b.status];

      if (aOrder < bOrder) {
        return -1;
      }

      if (aOrder > bOrder) {
        return 1;
      }

      return 0;
    });
  }, [deviceJobStatusCounts]);

  return (
    <TooltipV2
      className={styles.tooltip}
      position="top-center"
      trigger={
        <div className={clsx(styles.progressBar, isAnyInProgress && styles.inProgress)}>
          {items.map(({ count, status }) => (
            <span
              className={clsx(styles.progression, styles[HostRunStatus[status].toLowerCase()])}
              style={{ width: percentage(count, total) + '%' }}
              key={status}></span>
          ))}
        </div>
      }>
      <ul className={styles.statusList}>
        <li>
          {deviceJobStatusCounts
            .filter(item => item.count)
            .map(({ count, status }) => (
              <span key={status}>
                {t(`updateDetails.grid.deviceJobStatus.${deviceJobStatusTranslationMap[status]}.title`)}: {count}
              </span>
            ))}
        </li>
      </ul>
    </TooltipV2>
  );
}

export default UpdateJobProgress;
