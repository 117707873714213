import { MoreMenuHorizontalFilledIcon } from '@getgo/chameleon-icons/react';
import { IconButton, Popover } from '@getgo/chameleon-web-react-wrapper';
import { PropsWithChildren, ReactElement } from 'react';

interface GridMoreActionsProps {
  gridCell: HTMLElement;
}

function GridMoreActions({ children, gridCell }: PropsWithChildren<GridMoreActionsProps>): ReactElement {
  return (
    <Popover
      menu
      onChange={isOpen => {
        const row = gridCell.parentElement;
        row?.classList[isOpen ? 'add' : 'remove']('row-has-popover');
      }}
      trigger={
        <IconButton aria-label="more" variant="primary">
          <MoreMenuHorizontalFilledIcon />
        </IconButton>
      }>
      {children}
    </Popover>
  );
}

export default GridMoreActions;
