import {
  AlertFilledIcon,
  AttentionOutlinedIcon,
  CheckmarkCircleFilledIcon,
  CheckmarkCircleOutlinedIcon,
  HelpSquareInactiveIcon,
  RefreshFilledIcon
} from '@getgo/chameleon-icons/react';
import clsx from 'clsx';
import styles from './UpdateStatusCells.module.scss';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { Trans } from 'react-i18next';
import { PropsWithChildren, ReactElement } from 'react';
import Spinner from '../../../core/components/spinner/Spinner';

function UpdateStatusCell({ children }: PropsWithChildren): ReactElement {
  return <div className={styles.cell}>{children}</div>;
}

export default {
  UpToDate: ({ children }: PropsWithChildren) => (
    <UpdateStatusCell>
      <CheckmarkCircleFilledIcon class={clsx(styles.cellIcon, styles.cellUpToDate)} />
      <Trans i18nKey="updateStatus.upToDate" />
      {children}
    </UpdateStatusCell>
  ),
  UpdatesAvailable: ({ count, children }: PropsWithChildren<{ count?: number }>) => (
    <UpdateStatusCell>
      <RefreshFilledIcon class={clsx(styles.cellIcon, styles.cellUpdatesAvailable)} />
      <Trans i18nKey="updateStatus.updateAvailable" count={count} />
      {children}
    </UpdateStatusCell>
  ),
  UpdatesInProgress: ({ count, children }: PropsWithChildren<{ count?: number }>) => (
    <UpdateStatusCell>
      <Spinner useGotoColor={true} />
      <Trans i18nKey="updateStatus.updateInProgress" count={count} />
      {children}
    </UpdateStatusCell>
  ),
  Vulnerable: ({ children }: PropsWithChildren) => (
    <UpdateStatusCell>
      <AlertFilledIcon class={clsx(styles.cellIcon, styles.cellVulnerable)} />
      <Trans i18nKey="updateStatus.vulnerable" />
      {children}
    </UpdateStatusCell>
  ),
  VulnerableLimitedCapabilities: ({ children }: PropsWithChildren) => (
    <UpdateStatusCell>
      <AlertFilledIcon class={clsx(styles.cellIcon, styles.cellVulnerable)} />
      <Typography variant="caption-medium" className={styles.text} lineClamp={2}>
        <Trans i18nKey="updateStatus.vulnerableLimitedCapabilities" />
      </Typography>
      {children}
    </UpdateStatusCell>
  ),
  LimitedCapabilities: ({ children }: PropsWithChildren) => (
    <UpdateStatusCell>
      <AttentionOutlinedIcon class={clsx(styles.cellIcon, styles.cellLimited)} />
      <Typography variant="caption-medium" className={styles.text} lineClamp={2}>
        <Trans i18nKey="updateStatus.limitedCapabilities" />
      </Typography>
      {children}
    </UpdateStatusCell>
  ),
  OsNotActivated: ({ children }: PropsWithChildren) => (
    <UpdateStatusCell>
      <AttentionOutlinedIcon class={clsx(styles.cellIcon, styles.cellLimited)} />
      <Typography variant="caption-medium" className={styles.text} lineClamp={2}>
        <Trans i18nKey="updateStatus.osNotActivated" />
      </Typography>
      {children}
    </UpdateStatusCell>
  ),
  Error: ({ children }: PropsWithChildren) => (
    <UpdateStatusCell>
      <HelpSquareInactiveIcon class={clsx(styles.cellIcon, styles.cellError)} />
      <Typography variant="caption-medium" className={styles.text} lineClamp={2}>
        <Trans i18nKey="updateStatus.error" />
      </Typography>
      {children}
    </UpdateStatusCell>
  ),
  UpToDateWithOptionalUpdates: ({ count, children }: PropsWithChildren<{ count?: number }>) => (
    <UpdateStatusCell>
      <CheckmarkCircleOutlinedIcon class={clsx(styles.cellIcon, styles.cellUpToDate)} />
      <Trans i18nKey="updateStatus.optionalUpdateAvailable" count={count} />
      {children}
    </UpdateStatusCell>
  ),
  Unknown: ({ children }: PropsWithChildren) => (
    <UpdateStatusCell>
      <HelpSquareInactiveIcon class={clsx(styles.cellIcon, styles.cellUnknown)} />
      <Typography variant="caption-medium" className={styles.text} lineClamp={2}>
        <Trans i18nKey="updateStatus.unknown" />
      </Typography>
      {children}
    </UpdateStatusCell>
  )
};
