import { WindowsPatchFilter, WindowsUpdatePatchesFilteredEvent } from '../../../../core/models/UserTrackingEvents';
import { useTranslation } from 'react-i18next';
import { GridFilterData } from '../../../../core/components/grid/grid-filter';
import { useMemo } from 'react';
import UpdateStatus from '../../../models/UpdateStatus';
import GridFilterIds from '../../../models/GridFilterIds';

export default function useDeviceFilters(): GridFilterData[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t('companyUpdatesOverview.grid.toolbar.actions.allDevices'),
        trackingEvent: new WindowsUpdatePatchesFilteredEvent(WindowsPatchFilter.AllUpdates),
        filterModel: null,
        filterId: GridFilterIds.ALL
      },
      {
        label: t('companyUpdatesOverview.grid.toolbar.actions.vulnerableDevices'),
        trackingEvent: new WindowsUpdatePatchesFilteredEvent(WindowsPatchFilter.ImportantAndCritical),
        filterModel: {
          updateStatus: {
            filterType: 'text',
            type: 'equals',
            filter: UpdateStatus.Vulnerable
          }
        },
        filterId: GridFilterIds.VULNERABLE_DEVICES
      }
    ],
    [t]
  );
}
