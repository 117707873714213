import { DeviceJobGridRow } from '../models/device-job-grid-row';
import { UpdateJob } from '../models/update-job';
import { useUpdateJob } from './use-update-job.hook';
import { useDeviceUpdateJob } from './use-device-update-job.hook';
import { useUpdateJobLiveData } from './use-update-job-live-data.hook';
import { useDeviceUpdateJobLiveData } from './use-device-update-job-live-data.hook';
import { mapToDeviceJobGridRow } from '../mappers/device-job-grid-row.mapper';
import { HostRunStatusCount } from '@goto/remote-execution';

interface UpdateJobData {
  job?: UpdateJob;
  deviceJobs: DeviceJobGridRow[];
  isLoading: boolean;
  isError?: unknown;
}

export const useUpdateJobData = (jobId: string): UpdateJobData => {
  const { data: jobData, error: jobError } = useUpdateJob(jobId);
  const { data: deviceJobsDto, error: deviceJobError } = useDeviceUpdateJob(jobId);
  const { jobLiveData } = useUpdateJobLiveData(jobId);
  const { deviceJobLiveRows } = useDeviceUpdateJobLiveData(jobId);

  const deviceJobs =
    deviceJobsDto?.map<DeviceJobGridRow>(({ device, osUpdateCount, osOptionalUpdateCount, appUpdateCount }) => {
      const liveData = deviceJobLiveRows[device.hostSnapshot.hostId];
      if (liveData) {
        device = { ...device, ...liveData };
      }
      return mapToDeviceJobGridRow({
        device,
        osUpdateCount,
        osOptionalUpdateCount,
        appUpdateCount
      });
    }) ?? [];

  const job: UpdateJob | undefined = jobData
    ? { ...jobData, ...jobLiveData, hostRunStatusCounts: getDeviceJobStatusCount(deviceJobs) }
    : jobData;

  return {
    job,
    deviceJobs: deviceJobs ?? [],
    isLoading: !job || !deviceJobs,
    isError: jobError ?? deviceJobError
  };
};

function getDeviceJobStatusCount(deviceJobs: DeviceJobGridRow[]): HostRunStatusCount[] {
  return deviceJobs.reduce<HostRunStatusCount[]>((accumulator, current) => {
    const item = accumulator.find(i => i.status === current.status.deviceJobStatus);
    if (item) {
      item.count = item.count + 1;
    } else {
      accumulator.push({
        status: current.status.deviceJobStatus,
        count: 1
      });
    }
    return accumulator;
  }, []);
}
