import { ComponentType, useCallback } from 'react';
import trackingService from '../../services/tracking/tracking.service';
import logger from '../../services/logger.service';
import { TrackableProps } from './TrackableProps';

type UnrestrictedEventHandler<E> = { bivarianceHack(event: E): void }['bivarianceHack'];

export default function withTracking<
  EventType,
  PropsType extends {
    onClick?: UnrestrictedEventHandler<EventType>;
  }
>(InnerComponent: ComponentType<PropsType>): ComponentType<PropsType & TrackableProps> {
  return function TrackedComponent(props) {
    const { trackingEvent, onClick } = props;
    const trackedOnClick = useCallback<UnrestrictedEventHandler<EventType>>(
      clickEvent => {
        try {
          trackingService.trackUserEvent(trackingEvent);
        } catch (error) {
          logger.logError(error as Error | string);
        }
        if (onClick) {
          onClick(clickEvent);
        }
      },
      [onClick, trackingEvent]
    );
    return <InnerComponent {...props} onClick={trackedOnClick} />;
  };
}
