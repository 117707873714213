import { PropsWithChildren, ReactElement } from 'react';
import ThemeProvider from './ThemeProvider';
import TrackingProvider from './tracking/TrackingProvider';
import TranslationsProvider from './TranslationsProvider';
import { SnackbarProvider } from './snackbar/SnackbarProvider';
import { DialogProvider } from './dialogs/DialogProvider';
import LiveUpdatesProvider from './LiveUpdatesProvider';
import AgGridLicenseProvider from './AgGridLicenseProvider';
import UpdatesProvider from '../../windows-updates/components/DeviceDetails/UpdatesProvider';
import { Provider as InversifyJSProvider } from 'inversify-react';
import patchManagementContainer from '../../inversify.config';

export function AppProvider({ children }: PropsWithChildren): ReactElement {
  return (
    <InversifyJSProvider container={patchManagementContainer}>
      <AgGridLicenseProvider>
        <TrackingProvider>
          <LiveUpdatesProvider>
            <ThemeProvider>
              <TranslationsProvider>
                <UpdatesProvider>
                  <SnackbarProvider>
                    <DialogProvider>{children}</DialogProvider>
                  </SnackbarProvider>
                </UpdatesProvider>
              </TranslationsProvider>
            </ThemeProvider>
          </LiveUpdatesProvider>
        </TrackingProvider>
      </AgGridLicenseProvider>
    </InversifyJSProvider>
  );
}
