import { ReactElement, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AppProvider } from './core/components/AppProvider';
import { routes } from './core/models/routes';
import { useAppSelector } from './core/store/root.store';
import { selectNavigationRoute } from './ui-frame/state/ui-frame.slice';
import clsx from 'clsx';
import { environment } from './environments/environment';
import GettingStarted from './getting-started/GettingStarted';
import Layout from './Layout';

function App(): ReactElement {
  const routerNavigate = useNavigate();
  const navigationRoute = useAppSelector(selectNavigationRoute);

  useEffect(() => {
    routerNavigate(navigationRoute);
  }, [navigationRoute, routerNavigate]);

  return (
    <div className={clsx(!environment.production && 'dev-mode')}>
      <AppProvider>
        <GettingStarted>
          <Layout>
            <Routes>
              {routes.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))}
            </Routes>
          </Layout>
        </GettingStarted>
      </AppProvider>
    </div>
  );
}

export default App;
