import { Architecture } from '../../windows-updates/models/architecture';
import OnlineStatus from '../../windows-updates/models/OnlineStatus';
import PatchManagementCapabilities from '../../windows-updates/models/PatchManagementCapabilities';
import { Platform } from '../../windows-updates/models/platform';

interface Device {
  id: string;
  name?: string | null;
  hostName?: string | null;
  group?: { id: string; name: string } | null;
  onlineStatus: OnlineStatus;
  platform?: Platform;
  architecture?: Architecture;
  timeZone?: string;
  patchmanagement?: PatchManagementCapabilities | null;
  osActivation?: boolean;
}

export default Device;

export const UngroupedDeviceGroupId = '';
