import { ReactElement } from 'react';
import Spinner from '../../spinner/Spinner';
import styles from './LoadingOverlay.module.scss';
import clsx from 'clsx';

function LoadingOverlay({ className }: { className?: string }): ReactElement {
  return (
    <div className={clsx(styles.loadingOverlay, className)}>
      <Spinner size={36} />
    </div>
  );
}

export default LoadingOverlay;
