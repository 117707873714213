import { t } from '../../i18n/i18n';

export const formatSelectedDevicesTranslation = (deviceIds: string[] | undefined): string => {
  const noDevicesSelected = !deviceIds || deviceIds?.length === 0;

  if (noDevicesSelected) {
    return t('scheduleUpdatesPage.progressStepper.selectDevices');
  }
  if (deviceIds.length === 1) {
    return t('scheduleUpdatesPage.progressStepper.selectDevicesDescription_one');
  }
  return t('scheduleUpdatesPage.progressStepper.selectDevicesDescription', { number: deviceIds.length });
};
