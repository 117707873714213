import { Chip } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement } from 'react';
import trackingService from '../../services/tracking/tracking.service';
import logger from '../../services/logger.service';
import { GridFilterData } from './grid-filter';

export interface GridFilterProps {
  filter: GridFilterData;
  selected: boolean;
  selectFilter: (filter: GridFilterData) => void;
}

function GridFilter({ filter, selected, selectFilter }: GridFilterProps): ReactElement | null {
  return (
    <Chip
      active={selected}
      onClick={() => {
        try {
          trackingService.trackUserEvent(filter.trackingEvent);
        } catch (e) {
          logger.logError(e as Error);
        }
        selectFilter(filter);
      }}>
      {filter.label}
    </Chip>
  );
}

export default GridFilter;
