import { ContainerModule } from 'inversify';
import uiFrameAccessTokenService from './uiFrameAccessTokenService';

const AuthServices = {
  AccessTokenProvider: Symbol.for('AccessTokenProvider')
};

const AuthModule = new ContainerModule(bind => {
  bind(AuthServices.AccessTokenProvider).toConstantValue(uiFrameAccessTokenService);
});

export { AuthModule, AuthServices };
