export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  wait: number
): ((...args: Parameters<F>) => ReturnType<F> | undefined) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return function executedFunction(...args: Parameters<F>): ReturnType<F> | undefined {
    let result: ReturnType<F> | undefined;

    const later = (): any => {
      timeout = null;
      result = func(...args);
    };
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);

    return result;
  };
};
