import clsx from 'clsx';
import styles from './OnlineStatusBadge.module.scss';
import OnlineStatus from '../../../windows-updates/models/OnlineStatus';
import OnlineStatusIndicator from './OnlineStatusIndicator';
import { Badge } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement } from 'react';

const onlineStatusStyles = {
  [OnlineStatus.Offline]: styles.statusBadgeOffline,
  [OnlineStatus.Online]: styles.statusBadgeOnline,
  [OnlineStatus.InSession]: styles.statusBadgeInSession
};

export default function OnlineStatusBadge({ onlineStatus }: { onlineStatus: OnlineStatus }): ReactElement {
  return (
    <Badge
      className={clsx(styles.statusBadge, onlineStatusStyles[onlineStatus])}
      variant={onlineStatus !== OnlineStatus.Offline ? 'success' : 'neutral'}>
      <OnlineStatusIndicator onlineStatus={onlineStatus} />
    </Badge>
  );
}
