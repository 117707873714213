import { ChangeEvent, ReactElement, useCallback } from 'react';
import { Switch, SwitchProps, Typography } from '@getgo/chameleon-web-react-wrapper';
import { useTranslation } from 'react-i18next';

import updateDrawerStyles from '../UpdateDrawer.module.scss';
import { SwitchComponent } from '@getgo/chameleon-web';

interface ForceRebootSwitcherProps {
  updatesRequireRebootCount: number;
  onChange: (isEnabled: boolean) => void;
}

function ForceRebootSwitcher({
  updatesRequireRebootCount,
  checked,
  onChange,
  ...switchProps
}: Omit<SwitchProps, 'onChange'> & ForceRebootSwitcherProps): ReactElement {
  const { t } = useTranslation();
  const dynamicSentence = updatesRequireRebootCount
    ? t('updateDrawer.forceReboot.sentenceWithUpdates', { count: updatesRequireRebootCount })
    : t('updateDrawer.forceReboot.sentenceWithoutUpdates');
  const onSwitchChange = useCallback(
    ({ target }: ChangeEvent<SwitchComponent>) => onChange(target.checked),
    [onChange]
  );

  return (
    <div>
      <div className={updateDrawerStyles.switchSectionTitle}>
        <Switch onChange={onSwitchChange} {...switchProps}>
          <Typography variant="body-small-strong">{t('updateDrawer.forceReboot.title')}</Typography>
        </Switch>
      </div>
      {!checked && (
        <Typography variant="caption-medium" color="type-color-secondary">
          {dynamicSentence} {t('updateDrawer.forceReboot.description')}
        </Typography>
      )}
      {checked && (
        <Typography variant="caption-medium" color="type-color-secondary">
          {dynamicSentence} {t('updateDrawer.forceReboot.descriptionActiveState')}
        </Typography>
      )}
    </div>
  );
}

export default ForceRebootSwitcher;
