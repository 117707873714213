import { useEffect, useState } from 'react';
import liveUpdatesService from '../../core/services/live-updates.service';
import { RunChangedData } from '@goto/remote-execution';

interface JobLiveData extends Omit<RunChangedData, 'finishedAt'> {
  finishedAt?: Date;
}

interface UpdateJobLiveData {
  jobLiveData?: JobLiveData;
}

export const useUpdateJobLiveData = (jobId: string): UpdateJobLiveData => {
  const [jobLiveData, setJobLiveData] = useState<JobLiveData | undefined>(undefined);

  useEffect(() => {
    const handler = (runChangedData: RunChangedData): void => {
      if (runChangedData.id !== jobId) {
        return;
      }
      setJobLiveData({
        ...runChangedData,
        finishedAt: runChangedData.finishedAt ? new Date(runChangedData.finishedAt) : undefined
      });
    };
    liveUpdatesService.on('RunChanged', handler);
    return () => liveUpdatesService.off('RunChanged', handler);
  }, [jobId]);

  return {
    jobLiveData
  };
};
