import { environment } from '../../environments/environment';

/** Dispatches navigated custom event to the UI frame for changing URL. */
export function navigate(route: string): void {
  notifyUiFrame({ type: 'navigated', route });
}

/** Dispatches navigated custom event to the UI frame for changing app. */
export function navigateToApp(prefix: string, route = ''): void {
  notifyUiFrame({ type: 'navigated', prefix, route });
}

/** Dispatches custom event to the UI frame for opening external URL. */
export function openExternalLink(link: string): void {
  notifyUiFrame({
    exposed: true,
    type: 'integration-event',
    event: {
      type: 'open-link',
      link
    }
  });
}

export function trackEvent(name: string, properties?: Record<string, unknown>): void {
  notifyUiFrame({ type: 'event-tracked', name, properties });
}

export function trackPageView(url: string, properties?: Record<string, unknown>): void {
  notifyUiFrame({ type: 'page-tracked', url, name: url, properties });
}

function notifyUiFrame(detail: {
  [key: string]: string | number | boolean | Record<string, unknown> | undefined;
}): void {
  const element = document.getElementsByTagName('patch-management')[0];

  element?.dispatchEvent(
    new CustomEvent('synapse-app-event', {
      detail,
      bubbles: true,
      composed: true
    })
  );

  console.log(`dispatchEvent: synapse-app-event ${detail.type}`);
}
