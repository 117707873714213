import {
  navigate as uiFrameNavigate,
  navigateToApp as uiFrameNavigateToApp
} from '../../ui-frame/services/ui-frame.service';
import { AppRoute, GoToResolveAppRoute } from '../models/routes';

export function getPathToNavigate(route: GoToResolveAppRoute | AppRoute): string {
  const { path, params } = route;
  let routeToNavigate: string = path;

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      routeToNavigate = routeToNavigate.replaceAll(`:${key}`, value);
    }
  }

  return routeToNavigate;
}
/** Navigates within the app. */
export function navigate(route: AppRoute): void {
  uiFrameNavigate(getPathToNavigate(route));
}

export function navigateToPath(path: string): void {
  uiFrameNavigate(path);
}

/** Navigates to another GoTo Resolve app. */
export function navigateToApp(route: GoToResolveAppRoute): void {
  const pathToNavigate = getPathToNavigate(route);
  uiFrameNavigateToApp(pathToNavigate);
}
