import { FileResponse } from '../../models/file-response';
import { getFilenameFromContentDisposition } from '../../utilities/utilities';
import AccessTokenProvider from '../auth/AccessTokenProvider';

export class HttpClient {
  constructor(private accessTokenProvider: AccessTokenProvider) {}

  async get<T>(url: string): Promise<T> {
    const requestInit: RequestInit = {
      method: 'GET',
      headers: await this.getRequestHeaders()
    };

    return await this.send<T>(url, requestInit);
  }

  async post<TBody, TResponse>(url: string, body: TBody): Promise<TResponse> {
    const requestInit: RequestInit = {
      method: 'POST',
      body: body ? JSON.stringify(body) : null,
      headers: await this.getRequestHeaders()
    };

    return await this.send<TResponse>(url, requestInit);
  }

  async getFile(url: string): Promise<FileResponse> {
    const requestInit: RequestInit = {
      method: 'GET',
      headers: await this.getRequestHeaders()
    };

    const response = await fetch(url, requestInit);
    const file = await response.blob();
    const contentDisposition = response.headers.get('Content-Disposition');

    let filename = 'unknown';

    if (contentDisposition) {
      filename = getFilenameFromContentDisposition(contentDisposition) ?? 'unknown';
    }

    return {
      filename,
      file
    };
  }

  private async send<T>(url: string, init: RequestInit): Promise<T> {
    const response = await fetch(url, init);
    return await this.handleResponse<T>(response);
  }

  private async getRequestHeaders(): Promise<HeadersInit> {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    const jwt = await this.accessTokenProvider();

    if (jwt) {
      requestHeaders.set('Authorization', `Bearer ${jwt}`);
    }

    return requestHeaders;
  }

  private async handleResponse<T>(response: Response): Promise<T> {
    return this.isJsonResponse(response) ? await response.json() : ((await response.text()) as T);
  }

  private isJsonResponse(response: Response): boolean {
    if (!response.headers) {
      return false;
    }

    const contentTypeHeader = response.headers.get('Content-Type') ?? response.headers.get('content-type');
    // application/vnd.api+json; charset=utf-8
    const jsonRegex = /application\/.*json/;
    return jsonRegex.test(contentTypeHeader?.toLowerCase() ?? '');
  }
}
