import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { WindowsUpdate } from '../../models/windows-update';
import ExternalLink from '../../../core/components/ExternalLink';
import styles from './ShowDetailsLinkRenderer.module.scss';

function ShowDetailsLinkRenderer({ value }: ICellRendererParams<WindowsUpdate, string>): ReactElement | null {
  const { t } = useTranslation();

  return (
    <ExternalLink className={styles.link} size="small" to={value}>
      {t('deviceDetails.grid.windows.showDetails')}
    </ExternalLink>
  );
}

export default ShowDetailsLinkRenderer;
