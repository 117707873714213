import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import {
  trackEvent as uiFrameTrackEvent,
  trackPageView as uiFrameTrackPageView
} from '../../../ui-frame/services/ui-frame.service';
import { UserTrackingEvent } from '../../models/UserTrackingEvents';

export class TrackingService {
  readonly appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsightsInstrumentationKey,
      autoTrackPageVisitTime: true,
      enableAjaxPerfTracking: true,
      enableAjaxErrorStatusText: true,
      disableDataLossAnalysis: false,
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: false,
      enableResponseHeaderTracking: true,
      correlationHeaderExcludedDomains: ['segment.io', 'cdn.segment.com', 'pendo.io'],
      namePrefix: 'patchManagement'
    }
  });

  private segmentUserProperties: Record<string, string> = {};

  constructor() {
    this.appInsights.loadAppInsights();
  }

  init(userId: string, companyId: string): void {
    this.appInsights.setAuthenticatedUserContext(userId, companyId, false);
    this.appInsights.addTelemetryInitializer(item => {
      if (!item.data) {
        return;
      }

      item.data.userId = userId;
      item.data.companyId = companyId;
    });

    this.segmentUserProperties = { userId, companyId };
  }

  trackUserEvent({ name, ...properties }: UserTrackingEvent): void {
    const enhancedProperties = {
      ...properties,
      location: window.location.pathname
    };
    this.appInsights.trackEvent({ name }, enhancedProperties);
    uiFrameTrackEvent(name, { ...this.segmentUserProperties, ...enhancedProperties });
  }

  startTrackPage(url: string): void {
    this.appInsights.startTrackPage(url);
    uiFrameTrackPageView(url, this.segmentUserProperties);
  }

  stopTrackPage(url: string): void {
    this.appInsights.stopTrackPage(url);
  }

  trackInfo(message: string): void {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Information });
  }

  trackWarning(error: string | Error): void {
    const exception = error instanceof Error ? error : Error(error);
    this.appInsights.trackException({ exception, severityLevel: SeverityLevel.Warning });
  }

  trackException(error: string | Error): void {
    const exception = error instanceof Error ? error : Error(error);
    this.appInsights.trackException({ exception, severityLevel: SeverityLevel.Error });
  }
}

const tracking = new TrackingService();
export default tracking;
