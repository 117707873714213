import { LinkProps } from '@getgo/chameleon-web-react-wrapper';
import React, { HTMLAttributeAnchorTarget, PropsWithChildren, ReactElement } from 'react';
import { AppRoute } from '../models/routes';
import { navigate } from '../services/navigation.service';
import NavigationLink from './NavigationLink';
import TrackingEvent from '../models/TrackingEvent';
import { AppLinkDef } from '../models/AppLinkDef';

export interface AppLinkProps extends LinkProps {
  route: AppRoute;
  trackingEvent?: TrackingEvent;
  target?: HTMLAttributeAnchorTarget | undefined;
}

function AppLink(props: PropsWithChildren<AppLinkProps> | AppLinkDef): ReactElement {
  const action = (): void => navigate(props.route);
  if (Object.hasOwn(props, 'text')) {
    return (
      <NavigationLink navigate={action} route={props.route}>
        {(props as AppLinkDef).text}
      </NavigationLink>
    );
  }
  return <NavigationLink {...props} navigate={action} route={props.route} />;
}

export default AppLink;
