import UpdateHandle from '../../models/UpdateHandle';

export const numberOfWindowsUpdates = (updates: UpdateHandle[]): number =>
  count(updates, update => update.type == 'windows-update');

export const numberOfMandatoryWindowsUpdates = (updates: UpdateHandle[]): number =>
  count(updates, update => update.type == 'windows-update' && !update.isOptional);

export const numberOfWingetUpdates = (updates: UpdateHandle[]): number =>
  count(updates, update => update.type == 'winget-update');

export const numberOfAppUpdates = numberOfWingetUpdates;

export const numberOfUpdates = (updates: UpdateHandle[]): number =>
  numberOfWindowsUpdates(updates) + numberOfAppUpdates(updates);

export const numberOfOptionalWindowsUpdates = (updates: UpdateHandle[]): number =>
  count(updates, update => update.type == 'windows-update' && update.isOptional);

export const numberOfUpdatesRequiringReboot = (updates: UpdateHandle[]): number =>
  count(updates, update => update.type == 'windows-update' && update.requiresReboot);

function count<T>(arr: T[], predicate: (t: T) => boolean): number {
  return arr.reduce((acc, t) => acc + (predicate(t) ? 1 : 0), 0);
}
