import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { selectUser } from '../../../core/state/user.slice';
import { useAppSelector } from '../../../core/store/root.store';
import { selectJwt } from '../../../ui-frame/state/ui-frame.slice';
import updateScheduler from '../../services/UpdateScheduler';

function UpdatesProvider({ children }: PropsWithChildren): ReactElement | null {
  const user = useAppSelector(selectUser);
  const jwt = useAppSelector(selectJwt);

  useEffect(() => {
    if (user) {
      updateScheduler.init(user.id, user.company.id, jwt);
    }
  }, [jwt, user]);

  return user ? <>{children}</> : null;
}

export default UpdatesProvider;
