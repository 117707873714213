import { GetQuickFilterTextParams } from '@ag-grid-community/core';
import CompanyDeviceUpdateStatus from '../../../../models/CompanyDeviceUpdateStatus';
import { numberOfUpdates } from '../../DeviceUpdatesStatistics';
import { useCallback } from 'react';

export default function useUpdateSummaryQuickFilterText(): (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  param: GetQuickFilterTextParams<CompanyDeviceUpdateStatus>
) => string {
  return useCallback(({ data }) => (!data ? '' : numberOfUpdates(data.updates) + ''), []);
}
