import { ReactElement, useCallback, useEffect, useRef } from 'react';
import Grid from '../../../../core/components/grid/Grid';
import { GetRowIdParams, SelectionChangedEvent } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import useDeviceFilters from './useDeviceFilters';
import useDeviceUpdateStatusColumnDefinitions from './useDeviceUpdateStatusColumnDefinitions';
import useCompanyDeviceUpdateStatus from '../../../hooks/useCompanyDeviceUpdateStatus';
import useUpdateInstallCapabilities from '../../../hooks/useUpdateInstallCapabilities';
import { RowNode } from '@ag-grid-community/core/dist/cjs/es5/entities/rowNode';
import Context from './Context';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import CompanyDeviceUpdateStatus from '../../../models/CompanyDeviceUpdateStatus';
import { AgGridReact } from '@ag-grid-community/react';
import deviceGroupComparator from './deviceGroupComparator';
import tracking from '../../../../core/services/tracking/tracking.service';
import GridFilterIds from '../../../models/GridFilterIds';

type DeviceUpdateStatusGridProps = {
  selectedDeviceIds: Set<string>;
  onDeviceSelectionChange: (selectedDevices: CompanyDeviceUpdateStatus[]) => void;
  installRequestPending: boolean;
  initialFilter: GridFilterIds;
  onFilterChanged: (currentFilter: GridFilterIds) => void;
};

const defaultColDef = { resizable: true };

export default function DeviceUpdateStatusGrid({
  selectedDeviceIds,
  onDeviceSelectionChange,
  installRequestPending,
  initialFilter,
  onFilterChanged
}: DeviceUpdateStatusGridProps): ReactElement {
  const { t } = useTranslation();

  const { error: loadError, data: status } = useCompanyDeviceUpdateStatus();
  const deviceFilters = useDeviceFilters();
  const columnDefinitions = useDeviceUpdateStatusColumnDefinitions();
  const deviceStatusCapabilities = useUpdateInstallCapabilities();
  const gridRef = useRef<AgGridReact | null>(null);

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent<CompanyDeviceUpdateStatus>) => {
      onDeviceSelectionChange(event.api.getSelectedRows());
    },
    [onDeviceSelectionChange]
  );
  const isRowSelectable = useCallback(
    ({ data: row }: RowNode<CompanyDeviceUpdateStatus>) => deviceStatusCapabilities.canInstallUpdates(row),
    [deviceStatusCapabilities]
  );
  const context: Context = { installRequestPending };

  useEffect(() => {
    if (loadError) {
      console.error('Failed to load company device updates', loadError);
      tracking.trackException(loadError);
    }
  }, [loadError]);
  useEffect(() => {
    gridRef.current?.api?.forEachLeafNode(node => {
      node.setSelected(!!node.id && selectedDeviceIds.has(node.id));
    });
  }, [selectedDeviceIds]);

  return (
    <>
      {loadError && <Typography color="danger-01">{t('companyUpdatesOverview.errorMessage')}</Typography>}

      <Grid
        toolbarProps={{
          searchFieldPlaceholder: t('companyUpdatesOverview.grid.toolbar.searchFieldPlaceholder'),
          filters: deviceFilters,
          showCollapseAll: true,
          initialFilter: initialFilter,
          onFilterChanged: onFilterChanged
        }}
        rowData={status}
        defaultColDef={defaultColDef}
        columnDefs={columnDefinitions}
        getRowId={({ data }: GetRowIdParams<CompanyDeviceUpdateStatus>) => data.device.id}
        onSelectionChanged={onSelectionChanged}
        isRowSelectable={isRowSelectable}
        overlayNoRowsTemplate={t('companyUpdatesOverview.grid.noRows')}
        context={context}
        initialGroupOrderComparator={deviceGroupComparator}
        ref={gridRef}
      />
    </>
  );
}
