import Device from '../../core/models/Device';
import { AppUpdateState } from '../models/PatchManagementCapabilities';

export const areUpdateCapabilitiesLimited = (device: Device): boolean => {
  return isDeviceOsNotActivated(device) || areAppUpdateCapabilitiesLimited(device);
};

export const isDeviceOsNotActivated = (device: Device): boolean => {
  return device.osActivation !== undefined && !device.osActivation;
};

export const areAppUpdateCapabilitiesLimited = (device: Device): boolean => {
  return (
    device.patchmanagement?.appUpdateCapability !== undefined &&
    device.patchmanagement?.appUpdateCapability !== AppUpdateState.Available
  );
};
