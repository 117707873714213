import { GetRowIdParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { getWindowsUpdatesColumnDefsAsArray } from '../../../core/components/grid/column-definitions';
import Grid from '../../../core/components/grid/Grid';
import { WindowsUpdate } from '../../models/windows-update';
import { useTranslation } from 'react-i18next';
import UpdatesEmptyState, { UpdateType } from './UpdatesEmptyState';
import { JsxElement } from 'typescript';

type OsUpdatesProps = {
  updates: WindowsUpdate[];
  isLoading: boolean;
};

export default function JobDeviceOsUpdatesGrid({ updates, isLoading }: OsUpdatesProps): ReactElement {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const [gridReady, setGridReady] = useState(false);

  useEffect(() => {
    if (!isLoading && gridReady && gridRef.current) {
      gridRef.current.api.setRowData(updates);
    }
  }, [isLoading, gridReady, gridRef, updates]);

  function handleNoOsUpdates(): JSX.Element | undefined {
    if (!isLoading && updates.length === 0) {
      return <UpdatesEmptyState triggeredUpdateType={UpdateType.os} />;
    }
  }

  function handleOsUpdates(): JSX.Element | undefined {
    if (!isLoading && updates.length > 0) {
      return (
        <Grid
          toolbarProps={{
            searchFieldPlaceholder: t('deviceDetails.grid.windows.toolbar.searchFieldPlaceholder')
          }}
          ref={gridRef}
          columnDefs={getWindowsUpdatesColumnDefsAsArray()}
          getRowId={(params: GetRowIdParams<WindowsUpdate>) => params.data.updateId}
          onGridReady={() => setGridReady(true)}
        />
      );
    }
  }

  return (
    <>
      {handleNoOsUpdates()}
      {handleOsUpdates()}
    </>
  );
}
