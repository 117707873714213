import {
  ClockOutlinedIcon,
  CloseFilledIcon,
  DownloadFilledIcon,
  RefreshFilledIcon,
  ScreenOutlinedIcon,
  ScreenRebootOutlinedIcon
} from '@getgo/chameleon-icons/react';
import { Button, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ColDef, GetRowIdParams, ICellRendererParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Actions from '../../../core/components/actions/Actions';
import ContentHeader from '../../../core/components/ContentHeader';
import { generalColumnDefs } from '../../../core/components/grid/column-definitions';
import Grid from '../../../core/components/grid/Grid';
import GridActions from '../../../core/components/grid/GridActions';
import InfoPanel from '../../../core/components/info-panel/InfoPanel';
import Tooltip from '../../../core/components/tooltips/Tooltip';
import { Capability } from '../../../core/models/user-capability';
import http from '../../../core/services/http/default-http-client';
import logger from '../../../core/services/logger.service';
import { navigate } from '../../../core/services/navigation.service';
import { selectUser } from '../../../core/state/user.slice';
import { useAppDispatch, useAppSelector } from '../../../core/store/root.store';
import { combineUrl } from '../../../core/utilities/utilities';
import { environment } from '../../../environments/environment';
import { selectDateLocale } from '../../../ui-frame/state/ui-frame.slice';
import DeviceJobStatusCellRenderer from '../../grid/cell-renderers/DeviceJobStatusCellRenderer';
import { DeviceJobGridRow } from '../../models/device-job-grid-row';
import { downloadAllLogs, selectAllLogsDownloadingByUpdateJobId } from '../../state/all-logs.slice';
import DownloadLog from '../download-log/DownloadLog';
import UpdateJobProgress from '../update-job-progress/UpdateJobProgress';
import styles from './UpdateDetails.module.scss';
import { WindowsUpdateJobDeviceDetailsViewedEvent } from '../../../core/models/UserTrackingEvents';
import { useUpdateJobData } from '../../hooks/use-update-job-details.hook';
import { useSWRConfig } from 'swr';
import { updateJobCacheKey } from '../../hooks/use-update-job.hook';
import { deviceUpdateJobCacheKey } from '../../hooks/use-device-update-job.hook';
import { HostRunStatus } from '@goto/remote-execution';
import DeviceScheduledForCellRenderer from '../../grid/cell-renderers/DeviceScheduledForCellRenderer';
import { DateTime } from 'luxon';
import useActiveAndPastUpdatesLink from '../active-and-past-updates/useActiveAndPastUpdatesLink';
import { TrackedButton } from '../../../core/components/tracking/TrackedComponents';
import JobUpdateCountCellRenderer from '../../../patch-view/core/chameleon-cell-renderers/JobUpdatesCountCellRenderer';

function UpdateDetails(): ReactElement {
  const params = useParams();
  const { mutate } = useSWRConfig();
  const userCapabilities = useAppSelector(selectUser)?.capabilities ?? [];
  const jobId = params.updateJobId as string;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [cancelInProgress, setCancelInProgress] = useState(false);
  const locale = useAppSelector(selectDateLocale);
  const gridRef = useRef<AgGridReact>(null);
  const activeAndPastUpdatesLink = useActiveAndPastUpdatesLink();

  const { job, deviceJobs: deviceJobGridData } = useUpdateJobData(jobId);
  const allLogsDownloading = useAppSelector(state => selectAllLogsDownloadingByUpdateJobId(state, jobId));

  const canCancelUpdateJob =
    userCapabilities.includes(Capability.PatchManagement) &&
    deviceJobGridData.some(item => item.status.deviceJobStatus === HostRunStatus.Pending) &&
    !job?.finishedAt;

  const columnDefs: ColDef<DeviceJobGridRow>[] = [
    {
      field: 'deviceName',
      headerName: t('updateDetails.grid.headers.nickname'),
      minWidth: 100
    },
    {
      field: 'hostName',
      headerName: t('updateDetails.grid.headers.hostName'),
      minWidth: 100
    },
    {
      field: 'status',
      headerName: t('updateDetails.grid.headers.status'),
      minWidth: 100,
      cellRenderer: DeviceJobStatusCellRenderer
    },
    {
      field: 'updateCount',
      headerName: t('updateDetails.grid.headers.updates'),
      minWidth: 100,
      flex: job?.isScheduled === true ? undefined : 1,
      cellRenderer: JobUpdateCountCellRenderer,
      cellRendererParams: {
        osMandatoryUpdateCount: job?.osUpdateJobInfo?.updateCount ?? 0,
        osOptionalUpdateCount: job?.osUpdateJobInfo?.optionalUpdateCount ?? 0,
        appUpdateCount: job?.appUpdateJobInfo?.count ?? 0
      }
    }
  ];

  if (job?.isScheduled === true) {
    columnDefs.push({
      field: 'deviceTimeZone',
      headerName: t('updateDetails.grid.headers.timeZone'),
      minWidth: 200,
      flex: 1
    });
    columnDefs.push({
      field: 'scheduledAt',
      headerName: t('updateDetails.grid.headers.scheduledFor'),
      minWidth: 200,
      flex: 1,
      cellRenderer: DeviceScheduledForCellRenderer
    });
  }

  columnDefs.push({
    ...generalColumnDefs.actions,
    cellRenderer: (params: ICellRendererParams<DeviceJobGridRow>) => {
      const isPast = !!params.data?.finishedAt;
      return (
        <GridActions>
          <TrackedButton
            trackingEvent={new WindowsUpdateJobDeviceDetailsViewedEvent(isPast)}
            variant="neutral"
            onClick={() =>
              navigate({
                path: '/updates/:jobId/devices/:deviceId/updates',
                params: { jobId, deviceId: params.data?.deviceId ?? '' }
              })
            }>
            {t('snackbar.actions.seeDetails')}
          </TrackedButton>
          {params.data && (
            <DownloadLog logAvailable={params.data.logAvailable} updateJobId={jobId} deviceId={params.data.deviceId} />
          )}
        </GridActions>
      );
    }
  });

  const cancelUpdateJob = async (): Promise<void> => {
    await http.post(combineUrl(environment.remoteExecutionFrontendApiUrl, `automation/runs/${jobId}/cancel`), {
      runId: jobId
    });
  };

  const handleCancelClick = async (): Promise<void> => {
    setCancelInProgress(true);
    try {
      await cancelUpdateJob();
      await Promise.all([mutate(updateJobCacheKey(jobId)), mutate(deviceUpdateJobCacheKey(jobId))]);
    } catch (error) {
      logger.logError(error as Error);
    } finally {
      setCancelInProgress(false);
    }
  };

  const handleDownloadAllLogsClick = async (): Promise<void> => {
    dispatch(downloadAllLogs(jobId));
  };

  function evaluateOsMandatoryUpdatesCount(): JSX.Element | undefined {
    if (job?.osUpdateJobInfo?.updateCount) {
      return (
        <Typography variant="body-small-strong" color="type-color-secondary">
          {t('updateDetails.updateInfo.osMandatoryUpdates', { count: job?.osUpdateJobInfo?.updateCount })}
        </Typography>
      );
    }
  }

  function evaluateOsOptionalUpdatesCount(): JSX.Element | undefined {
    if (job?.osUpdateJobInfo?.optionalUpdateCount) {
      return (
        <Typography variant="body-small-strong" color="type-color-secondary">
          {t('updateDetails.updateInfo.osOptionalUpdates', { count: job?.osUpdateJobInfo?.optionalUpdateCount })}
        </Typography>
      );
    }
  }

  function evaluateAppUpdatesCount(): JSX.Element | undefined {
    if (job?.appUpdateJobInfo?.count) {
      return (
        <Typography variant="body-small-strong" color="type-color-secondary">
          {t('updateDetails.updateInfo.appUpdates', { count: job?.appUpdateJobInfo?.count })}
        </Typography>
      );
    }
  }

  return (
    <>
      <ContentHeader
        title={
          <Typography variant="heading-medium" tag="h1">
            {job?.displayName}
          </Typography>
        }
        breadcrumbLinks={[activeAndPastUpdatesLink]}
        actions={
          <Actions>
            <Tooltip
              trigger={
                <Button
                  variant="neutral"
                  leadingIcon={<CloseFilledIcon />}
                  onClick={handleCancelClick}
                  disabled={!canCancelUpdateJob}
                  isLoading={cancelInProgress}>
                  {t('updateDetails.actions.cancel.title')}
                </Button>
              }>
              {t('updateDetails.actions.cancel.tooltip')}
            </Tooltip>
            <Button
              leadingIcon={<DownloadFilledIcon />}
              disabled={!job?.finishedAt}
              isLoading={allLogsDownloading?.downloading}
              onClick={handleDownloadAllLogsClick}>
              {t('updateDetails.actions.downloadAllLogs')}
            </Button>
          </Actions>
        }
      />
      <div className={styles.pageContent}>
        <div className={styles.summary}>
          {job && (
            <>
              <Typography className={styles.summaryTitle} variant="heading-small" tag="h2">
                {t('updateDetails.summary')}
              </Typography>
              <div className={styles.updateJobDetails}>
                <div className={styles.updateJobProgress}>
                  <UpdateJobProgress deviceJobStatusCounts={job.hostRunStatusCounts} />
                </div>
                <div className={styles.descriptionItem}>
                  <Typography className={styles.descriptionItemLabel} variant="body-medium-strong">
                    {t('updateDetails.runBy')}
                  </Typography>
                  <Typography variant="body-medium">{job.effectiveRunBy}</Typography>
                </div>
                <div className={styles.descriptionItem}>
                  <Typography className={styles.descriptionItemLabel} variant="body-medium-strong">
                    {job.isScheduled ? t('updateDetails.scheduledAt') : t('updateDetails.startTime')}
                  </Typography>
                  <Typography variant="body-medium">
                    {DateTime.fromJSDate(job.scheduledAt).toFormat('FF', { locale: locale })}
                  </Typography>
                </div>
                {job.finishedAt && (
                  <div className={styles.descriptionItem}>
                    <Typography className={styles.descriptionItemLabel} variant="body-medium-strong">
                      {t('updateDetails.finishTime')}
                    </Typography>
                    <Typography variant="body-medium">
                      {DateTime.fromJSDate(job.finishedAt).toFormat('FF', { locale: locale })}
                    </Typography>
                  </div>
                )}
                <div className={styles.descriptionItem}>
                  <Typography className={styles.descriptionItemLabel} variant="body-medium-strong">
                    {t('updateDetails.platform.title')}
                  </Typography>
                  <Typography variant="body-medium">{job.platformDisplayName}</Typography>
                </div>
              </div>
              <InfoPanel>
                <div className={styles.updateInfoSection}>
                  <div className={styles.updateInfoSectionTitle}>
                    <span>
                      <RefreshFilledIcon size="24px" />
                    </span>
                    <Typography variant="body-medium" tag="h3">
                      {t('updateDetails.updateInfo.updates')}
                    </Typography>
                  </div>
                  <div className={styles.updateInfoSectionEntry}>
                    {evaluateOsMandatoryUpdatesCount()}
                    {evaluateOsOptionalUpdatesCount()}
                    {evaluateAppUpdatesCount()}
                  </div>
                </div>
                <div className={styles.updateInfoSection}>
                  <div className={styles.updateInfoSectionTitle}>
                    <span>
                      <ScreenOutlinedIcon size="24px" />
                    </span>
                    <Typography variant="body-medium" tag="h3">
                      {t('updateDetails.updateInfo.devices')}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body-small-strong" color="type-color-secondary">
                      {t('updateDetails.updateInfo.selectedDevices', { count: job.deviceCount })}
                    </Typography>
                  </div>
                </div>
                {job.isScheduled && (
                  <div className={styles.updateInfoSection}>
                    <div className={styles.updateInfoSectionTitle}>
                      <span>
                        <ClockOutlinedIcon size="24px" />
                      </span>
                      <Typography variant="body-medium" tag="h3">
                        {t('updateDetails.updateInfo.scheduleInfoTitle')}
                      </Typography>
                    </div>
                    <Typography variant="body-small-strong" color="type-color-secondary">
                      {t('updateDetails.updateInfo.scheduleInfoMessage')}
                    </Typography>
                  </div>
                )}
                {job.osUpdateJobInfo?.forceReboot && (
                  <div className={styles.updateInfoSection}>
                    <div className={styles.updateInfoSectionTitle}>
                      <span>
                        <ScreenRebootOutlinedIcon size="24px" />
                      </span>
                      <Typography variant="body-medium" tag="h3">
                        {t('updateDetails.updateInfo.reboot')}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body-small-strong" color="type-color-secondary">
                        {job.osUpdateJobInfo.forceRebootMaxDelaysCount
                          ? t('updateDetails.updateInfo.rebootPostpone', {
                              count: job.osUpdateJobInfo.forceRebootMaxDelaysCount
                            })
                          : t('updateDetails.updateInfo.rebootPostponeNull')}
                      </Typography>
                    </div>
                  </div>
                )}
              </InfoPanel>
            </>
          )}
        </div>
        <Grid
          toolbarProps={{
            searchFieldPlaceholder: t('updateDetails.grid.toolbar.searchFieldPlaceholder')
          }}
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={deviceJobGridData}
          getRowId={(params: GetRowIdParams<DeviceJobGridRow>) => params.data.deviceId}
        />
      </div>
    </>
  );
}

export default UpdateDetails;
