import defaultHttpClient from '../../core/services/http/default-http-client';
import { combineUrl } from '../../core/utilities/utilities';
import { environment } from '../../environments/environment';
import logger from '../../core/services/logger.service';

export interface ReportJobParams {
  remoteExecutionJobId: string;
  osUpdateInfo?: OsUpdateInfo | null;
  appUpdateInfo?: AppUpdateInfo | null;
}

export interface OsUpdateInfo {
  updateIds: string[];
  deviceIds: string[];
  installOptionalUpdates: boolean;
  forceReboot: boolean;
  forceRebootMaxDelaysCount?: number | null;
}

export interface AppUpdateInfo {
  deviceIdToAppUpdate: { [key: string]: AppUpdate[] };
}

export interface AppUpdate {
  id: string;
  name?: string | null;
  source: string;
  installedVersion?: string | null;
  targetVersion: string;
}

export class UpdateJobReportService {
  async reportJob(params: ReportJobParams): Promise<void> {
    try {
      const url = combineUrl(environment.frontendApiUrl, 'jobs');
      await defaultHttpClient.post(url, params);
    } catch (e) {
      logger.logError(e as Error);
    }
  }
}
