import { ClockOutlinedIcon, PlayFilledIcon } from '@getgo/chameleon-icons/react';
import { ReactElement, useMemo } from 'react';
import { ButtonProps } from '@getgo/chameleon-web-react-wrapper';
import UpdateHandle from '../../models/UpdateHandle';
import { numberOfWindowsUpdates, numberOfWingetUpdates } from '../CompanyUpdatesOverview/DeviceUpdatesStatistics';
import { useTranslation } from 'react-i18next';
import { TrackedButton } from '../../../core/components/tracking/TrackedComponents';
import { UpdateInstalledEvent } from '../../../core/models/UserTrackingEvents';

type InstallButtonProps = {
  isScheduled: boolean;
  selectedUpdates: UpdateHandle[];
} & ButtonProps;

export default function InstallButton({
  isScheduled,
  selectedUpdates,
  ...buttonProps
}: InstallButtonProps): ReactElement {
  const { t } = useTranslation();
  const trackingEvent = useMemo(
    () =>
      new UpdateInstalledEvent(
        numberOfWindowsUpdates(selectedUpdates),
        numberOfWingetUpdates(selectedUpdates),
        isScheduled
      ),
    [selectedUpdates, isScheduled]
  );
  return (
    <TrackedButton
      trackingEvent={trackingEvent}
      fullWidth
      size="large"
      leadingIcon={isScheduled ? <ClockOutlinedIcon /> : <PlayFilledIcon />}
      {...buttonProps}>
      {isScheduled ? t('updateDrawer.schedule.installWhenScheduled') : t('updateDrawer.installNow')}
    </TrackedButton>
  );
}
