import { GetQuickFilterTextParams } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import UpdateStatus from '../../../../models/UpdateStatus';

export default function useUpdateStatusQuickFilterText(): (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  param: GetQuickFilterTextParams<any, UpdateStatus>
) => string {
  const { t } = useTranslation();

  return useCallback(
    ({ value }) => {
      switch (value) {
        case UpdateStatus.UpToDate:
          return t('updateStatus.upToDate');
        case UpdateStatus.Vulnerable:
          return t('updateStatus.vulnerable');
        case UpdateStatus.UpToDateWithOptionalUpdates:
          return t('updateStatus.optionalUpdateAvailable');
        case UpdateStatus.UpdatesAvailable:
          return t('updateStatus.updateAvailable');
        case UpdateStatus.Unknown:
          return t('updateStatus.unknown');
        default:
          throw new Error(`Unknown health value ${value}!`);
      }
    },
    [t]
  );
}
