import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement } from 'react';
import DeviceDetailsLink from '../../../DeviceDetails/DeviceDetailsLink';
import Context from '../Context';
import CompanyDeviceUpdateStatus from '../../../../models/CompanyDeviceUpdateStatus';
import styles from './DeviceNameCellRenderer.module.scss';
import { DeviceDetailsViewedEvent } from '../../../../../core/models/UserTrackingEvents';

type DeviceNameCellRendererProps = Omit<ICellRendererParams<CompanyDeviceUpdateStatus, string>, 'context'> & {
  context: Context;
};

export default function DeviceNameCellRenderer({
  data,
  value,
  context
}: DeviceNameCellRendererProps): ReactElement | null {
  if (!data) {
    return null;
  }

  return (
    <DeviceDetailsLink
      deviceId={data.device.id}
      size={'small'}
      className={styles.deviceNameLink}
      trackingEvent={new DeviceDetailsViewedEvent()}>
      {value}
    </DeviceDetailsLink>
  );
}
