import { AgGridReact } from '@ag-grid-community/react';
import { ReactElement, RefObject, useState } from 'react';
import { GridFilterData } from './grid-filter';
import GridFilter from './GridFilter';
import styles from './GridFilters.module.scss';
import GridFilterIds from '../../../windows-updates/models/GridFilterIds';

interface GridFiltersProps {
  gridRef: RefObject<AgGridReact>;
  filters: GridFilterData[];
  onFilterSelected: (filterId: GridFilterIds) => void;
  initialFilter?: GridFilterIds;
}

function GridFilters({ gridRef, filters, onFilterSelected, initialFilter }: GridFiltersProps): ReactElement {
  const [selectedFilter, setSelectedFilter] = useState<GridFilterIds>(initialFilter ?? GridFilterIds.ALL);

  const handleSelectFilter = (filter: GridFilterData): void => {
    const gridApi = gridRef.current?.api;

    // set filter model via the grid API
    gridApi?.setFilterModel(filter.filterModel);
    setSelectedFilter(filter.filterId);
    onFilterSelected(filter.filterId);
  };

  const gridFilters = filters.map((item, index) => (
    <GridFilter
      key={index}
      filter={item}
      selectFilter={handleSelectFilter}
      selected={selectedFilter === item.filterId}
    />
  ));

  return <div className={styles.gridFilters}>{gridFilters}</div>;
}

export default GridFilters;
