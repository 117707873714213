import { GetRowIdParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../core/components/grid/Grid';
import { ApplicationUpdate } from '../../models/application/application-update';
import UpdatesEmptyState, { UpdateType } from './UpdatesEmptyState';

type AppUpdatesProps = {
  updates: ApplicationUpdate[];
  isLoading: boolean;
};

export default function JobDeviceAppUpdatesGrid({ updates, isLoading }: AppUpdatesProps): ReactElement {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const [gridReady, setGridReady] = useState(false);

  //updates = updates ?? [];
  useEffect(() => {
    if (!isLoading && gridReady && gridRef.current) {
      gridRef.current.api.setRowData(updates);
    }
  }, [isLoading, gridReady, gridRef, updates]);

  function handleNoAppUpdates(): JSX.Element | undefined {
    if (!isLoading && updates.length === 0) {
      return <UpdatesEmptyState triggeredUpdateType={UpdateType.app} />;
    }
  }

  function handleAppUpdates(): JSX.Element | undefined {
    if (updates.length > 0) {
      return (
        <Grid
          toolbarProps={{
            searchFieldPlaceholder: t('deviceDetails.grid.windows.toolbar.searchFieldPlaceholder')
          }}
          ref={gridRef}
          columnDefs={[
            {
              field: 'name',
              headerName: t('deviceDetails.grid.apps.headers.name'),
              minWidth: 300,
              flex: 1
            },
            {
              field: 'id',
              headerName: t('deviceDetails.grid.apps.headers.id'),
              minWidth: 300
            },
            {
              field: 'installedVersion',
              headerName: t('deviceDetails.grid.apps.headers.installedVersion'),
              minWidth: 50
            },
            {
              field: 'targetVersion',
              headerName: t('deviceDetails.grid.apps.headers.targetVersion'),
              minWidth: 50
            }
          ]}
          getRowId={(params: GetRowIdParams<ApplicationUpdate>) => params.data.id}
          onGridReady={() => setGridReady(true)}
          overlayNoRowsTemplate={t('companyUpdatesOverview.grid.noRows')}
        />
      );
    }
  }

  return (
    <>
      {handleNoAppUpdates()}
      {handleAppUpdates()}
    </>
  );
}
