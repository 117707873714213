import { DateTime } from 'luxon';

export const dateTimeInputFormat = `yyyy-MM-dd'T'HH:mm`;
export const dateTimeLogFormat = `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`;

export function combineUrl(base: string, ...paths: string[]): string {
  if (base.endsWith('/')) {
    base = base.slice(0, -1);
  }

  paths = paths.map((path: string) => {
    if (path.startsWith('/')) {
      path = path.substring(1);
    }

    if (path.endsWith('/')) {
      path = path.slice(0, -1);
    }

    return path;
  });
  return `${base}/${paths.join('/')}`;
}

export function upperCaseFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function lowerCaseFirstLetter(text: string): string {
  return text.charAt(0).toLowerCase() + text.slice(1);
}

export function getFilenameFromContentDisposition(contentDisposition: string): string | null {
  const matches = contentDisposition.match(/filename\*?=(UTF-\d['"]*)?((['"]).*?[.]$|[^;\n]*)?/gi);

  if (matches && matches.length) {
    return matches.length === 1
      ? matches[0].split('filename=')[1].replace(/['"]/g, '')
      : decodeURIComponent(matches[1].split(/filename\*=UTF-\d['"]*/gi)[1].replace(/['"]/g, ''));
  }

  return null;
}

export async function retriedPromise<T>(
  operation: () => Promise<T>,
  shouldRetry: (attempt: number, error: unknown) => boolean,
  retryDelayMs: (attempt: number) => number,
  attempt = 1
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (shouldRetry(attempt, error)) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(retriedPromise(operation, shouldRetry, retryDelayMs, ++attempt));
        }, retryDelayMs(attempt));
      });
    } else {
      throw error;
    }
  }
}

export async function pollUntil<T>(
  operation: () => Promise<T>,
  predicate: (value: T) => boolean,
  pollInterval: number
): Promise<T> {
  const value = await operation();

  if (predicate(value)) {
    return value;
  }

  return new Promise((resolve, reject) => {
    let timeoutId: number | null = null;

    try {
      timeoutId = window.setTimeout(() => {
        resolve(pollUntil(operation, predicate, pollInterval));
      }, pollInterval);
    } catch (error) {
      timeoutId && window.clearTimeout(timeoutId);
      reject(error);
    }
  });
}

export function getRandomId(length: number): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function normalizeDateTime(
  dateTime: string,
  minDate = DateTime.fromJSDate(new Date()).plus({ minutes: 1 }).toJSDate(),
  maxDate = DateTime.fromJSDate(new Date()).plus({ years: 1 }).toJSDate()
): string {
  const date = new Date(dateTime);
  if (DateTime.fromJSDate(date) < DateTime.fromJSDate(minDate)) {
    return DateTime.fromJSDate(minDate).toFormat(dateTimeInputFormat);
  }
  if (DateTime.fromJSDate(date) > DateTime.fromJSDate(maxDate)) {
    return DateTime.fromJSDate(maxDate).toFormat(dateTimeInputFormat);
  }
  return dateTime;
}

export function getNormalizedCurrentDateStr(): string {
  const currentDate = DateTime.fromJSDate(new Date());
  return normalizeDateTime(currentDate.toFormat(dateTimeInputFormat));
}

export function convertDateStrToUtc(dateTime: string): string {
  const date = new Date(dateTime);
  const tmp = DateTime.fromJSDate(date).toUTC();
  return tmp.toFormat(dateTimeLogFormat);
}
