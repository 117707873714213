import { useTranslation } from 'react-i18next';
import { HostRunStatus } from '@goto/remote-execution';
import { CellText } from './CellText';
import { ReactElement } from 'react';

function JobStatusCellRenderer(status: HostRunStatus): ReactElement {
  const { t } = useTranslation();

  return (
    <CellText>
      {' '}
      {(() => {
        switch (status) {
          case HostRunStatus.Cancelled:
            return t('jobHistoryGrid.jobStatus.cancelled');
          case HostRunStatus.Running:
            return t('jobHistoryGrid.jobStatus.running');
          case HostRunStatus.Pending:
            return t('jobHistoryGrid.jobStatus.pending');
          case HostRunStatus.Failed:
            return t('jobHistoryGrid.jobStatus.failed');
          case HostRunStatus.Finished:
            return t('jobHistoryGrid.jobStatus.finished');
          default:
            return t('jobHistoryGrid.jobStatus.unknown');
        }
      })()}
    </CellText>
  );
}

export default JobStatusCellRenderer;
