import { ContainerModule } from 'inversify';
import frontendApiAxios from './frontendApiAxios';
import { EnvironmentServices } from '../../../environments/module';
import { AuthServices } from '../auth/module';

const HttpServices = {
  FrontendApiAxios: Symbol.for('FrontendApiAxios')
};

const HttpModule = new ContainerModule(bind => {
  bind(HttpServices.FrontendApiAxios)
    .toDynamicValue(context =>
      frontendApiAxios(
        context.container.get(EnvironmentServices.EnvironmentConfig),
        context.container.get(AuthServices.AccessTokenProvider)
      )
    )
    .inSingletonScope();
});

export { HttpModule, HttpServices };
