import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement } from 'react';
import { SoftwareUpdateGridRow, SoftwareUpdateStatus } from '../SoftwareUpdateGridRow';
import UpdateStatusCells from '../../../../grid/cell-renderers/UpdateStatusCells';

export default function SoftwareUpdateStatusCellRenderer({
  value
}: ICellRendererParams<SoftwareUpdateGridRow, SoftwareUpdateStatus>): ReactElement {
  if (value.updateInProgress) {
    return (
      <UpdateStatusCells.UpdatesInProgress count={1}>
        &nbsp;({value.availableVersions[0].version})
      </UpdateStatusCells.UpdatesInProgress>
    );
  } else if (value.availableVersions.length > 0) {
    return (
      <UpdateStatusCells.UpdatesAvailable count={1}>
        &nbsp;({value.availableVersions[0].version})
      </UpdateStatusCells.UpdatesAvailable>
    );
  } else {
    return <UpdateStatusCells.UpToDate />;
  }
}
