import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Typography } from '@getgo/chameleon-web-react-wrapper';
import styles from './UpdateDrawer.module.scss';
import UpdateHandle from '../../models/UpdateHandle';
import {
  numberOfMandatoryWindowsUpdates,
  numberOfOptionalWindowsUpdates,
  numberOfWindowsUpdates
} from '../CompanyUpdatesOverview/DeviceUpdatesStatistics';

interface WindowsInfoPanelProps {
  selectedUpdates: UpdateHandle[];
  installMandatoryUpdates: boolean;
  setInstallMandatoryUpdates: (state: boolean) => void;
  installOptionalUpdates: boolean;
  setInstallOptionalUpdates: (state: boolean) => void;
}

function WindowsInfoPanel({
  selectedUpdates,
  installMandatoryUpdates,
  setInstallMandatoryUpdates,
  installOptionalUpdates,
  setInstallOptionalUpdates
}: WindowsInfoPanelProps): ReactElement {
  const { t } = useTranslation();

  const calculateNumberOfSelectedUpdates = (
    isMandatoryUpdatesEnabled: boolean,
    isOptionalUpdatesEnabled: boolean,
    updates: UpdateHandle[]
  ): number => {
    let selectedUpdates = 0;
    if (isMandatoryUpdatesEnabled) {
      selectedUpdates += numberOfMandatoryWindowsUpdates(updates);
    }
    if (isOptionalUpdatesEnabled) {
      selectedUpdates += numberOfOptionalWindowsUpdates(updates);
    }
    return selectedUpdates;
  };

  return (
    <>
      <Typography variant="body-medium" tag="h3" className={styles.updateInfoSectionHeading}>
        {t('updateDrawer.infoPanel.winUpdates')}
      </Typography>
      <div className={styles.updateInfoSectionEntry}>
        <Typography variant="body-small-strong" color="type-color-secondary">
          {t('updateDrawer.infoPanel.updatesSelectedOutOfAvailable', {
            count: calculateNumberOfSelectedUpdates(installMandatoryUpdates, installOptionalUpdates, selectedUpdates),
            availableUpdates: numberOfWindowsUpdates(selectedUpdates)
          })}
        </Typography>
      </div>
      {numberOfMandatoryWindowsUpdates(selectedUpdates) > 0 && (
        <Switch
          className={styles.installOptionalUpdatesSwitch}
          checked={installMandatoryUpdates}
          onChange={event => setInstallMandatoryUpdates(event.target.checked)}>
          {t('updateDrawer.infoPanel.mandatoryWindowsUpdatesAvailable', {
            count: numberOfMandatoryWindowsUpdates(selectedUpdates)
          })}
        </Switch>
      )}

      {numberOfOptionalWindowsUpdates(selectedUpdates) > 0 && (
        <div className={styles.updateInfoSectionEntry}>
          <Switch
            className={styles.installOptionalUpdatesSwitch}
            checked={installOptionalUpdates}
            onChange={event => setInstallOptionalUpdates(event.target.checked)}>
            {t('updateDrawer.infoPanel.installOptionalUpdatesWithCount', {
              count: numberOfOptionalWindowsUpdates(selectedUpdates)
            })}
          </Switch>
        </div>
      )}
    </>
  );
}

export default WindowsInfoPanel;
