import { ArrowLeftFilledIcon } from '@getgo/chameleon-icons/react';
import { IconButton } from '@getgo/chameleon-web-react-wrapper';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, navigateToApp } from '../../services/navigation.service';
import styles from './Breadcrumb.module.scss';
import GoToResolveAppLink from '../GoToResolveAppLink';
import AppLink from '../AppLink';
import { AppLinkDef } from '../../models/AppLinkDef';

function Breadcrumbs({ links }: { links: (AppLinkDef | null)[] }): ReactElement {
  const definedLinks = links.filter(it => it !== null) as AppLinkDef[];
  const { t } = useTranslation();

  const back =
    links.length === 0
      ? () => navigateToApp({ path: '/devices' })
      : () => navigate(definedLinks[definedLinks.length - 1].route);

  return (
    <nav className={styles.nav} aria-label="breadcrumb">
      <IconButton aria-label="back" className={styles.back} variant="primary" onClick={back}>
        <ArrowLeftFilledIcon />
      </IconButton>
      <ol className={styles.breadcrumb}>
        <li className={styles.breadcrumbItem} key={0}>
          <GoToResolveAppLink route={{ path: '/devices' }}>{t('breadcrumb.devices')}</GoToResolveAppLink>
        </li>
        {definedLinks.map((link, index) => (
          <li className={styles.breadcrumbItem} key={index + 1}>
            <AppLink {...link} />
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
