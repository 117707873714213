class FileService {
  save(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.setAttribute('download', filename);
    link.setAttribute('href', url);
    link.click();

    window.URL.revokeObjectURL(url);
  }
}

const fileService = new FileService();
export default fileService;
