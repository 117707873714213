import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';
import { DebuggableProps } from '../DebuggableProps';
import styles from './GridActions.module.scss';

interface GridActionsProps extends DebuggableProps {
  className?: string;
}

function GridActions({ children, className, debug = false }: PropsWithChildren<GridActionsProps>): ReactElement {
  return (
    <div className={clsx(className, styles.gridRowActions, 'grid-row-actions-hover', debug && styles.debug)}>
      <div>{children}</div>
    </div>
  );
}

export default GridActions;
