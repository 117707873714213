import { Job, RunDto, RemoteExecutionService as UpstreamRemoteExecutionService } from '@goto/remote-execution';
import { UiFrameAdapter, DefaultUiFrameAdapter, MockUiFrameAdapter } from 'goto-zero-trust-vault';
import logger from '../../core/services/logger.service';
import { environment } from '../../environments/environment';
import i18n from '../../i18n/i18n';

export class RemoteExecutionService {
  private readonly remoteExecutionService: UpstreamRemoteExecutionService;

  constructor(userId: string, companyId: string, jwt: string) {
    const uiFrameAdapter: UiFrameAdapter = environment.production
      ? new DefaultUiFrameAdapter()
      : new MockUiFrameAdapter(companyId, userId, () => jwt);

    const options = {
      zeroTrustApiUrl: environment.zeroTrustApiUrl,
      remoteExecutionFrontendApiUrl: environment.remoteExecutionFrontendApiUrl
    };

    this.remoteExecutionService = UpstreamRemoteExecutionService.create(uiFrameAdapter, logger, options);
  }

  async run(job: Job): Promise<RunDto> {
    if (!this.remoteExecutionService) {
      const error = new Error('REService is not instantiated.');
      logger.logError(error);
      throw error;
    }

    try {
      return await this.remoteExecutionService.run(job, i18n.t('zeroTrust.install'));
    } catch (error) {
      logger.logError(error as Error);
      throw error;
    }
  }
}
