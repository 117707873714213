import { IconButton, SearchField } from '@getgo/chameleon-web-react-wrapper';
import { AgGridReact } from '@ag-grid-community/react';
import { ChangeEvent, ReactElement, RefObject, useState } from 'react';
import { GridFilterData } from './grid-filter';
import GridFilters from './GridFilters';
import styles from './GridToolbar.module.scss';
import GridFilterIds from '../../../windows-updates/models/GridFilterIds';
import { ChevronDownOutlinedIcon, ChevronRightOutlinedIcon } from '@getgo/chameleon-icons/react';
import Tooltip from '../tooltips/Tooltip';
import { useTranslation } from 'react-i18next';

export interface GridToolbarProps {
  searchFieldPlaceholder: string;
  actions?: ReactElement;
  filters?: GridFilterData[];
  showCollapseAll?: boolean;
  gridRef: RefObject<AgGridReact>;
  initialFilter?: GridFilterIds;
  onFilterChanged?: (currentFilterFragment: GridFilterIds) => void;
}

function GridToolbar({
  actions,
  filters,
  showCollapseAll,
  gridRef,
  searchFieldPlaceholder,
  initialFilter,
  onFilterChanged
}: GridToolbarProps): ReactElement {
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [expanded, setExpanded] = useState(true);

  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuickFilter(event.target.value);
  };

  const handleFilterSelected = (filterId: GridFilterIds): void => {
    if (onFilterChanged) {
      onFilterChanged(filterId);
    }
    setQuickFilter('');
  };

  const setQuickFilter = (value: string): void => {
    setSearchFieldValue(value);
    gridRef.current?.api?.setQuickFilter(value);
  };

  const onExpandAllChange = (expand: boolean): void => {
    setExpanded(expand);
    expanded ? gridRef.current?.api?.collapseAll() : gridRef.current?.api?.expandAll();
  };

  return (
    <div className={styles.toolbar}>
      {filters && (
        <GridFilters
          gridRef={gridRef}
          filters={filters}
          onFilterSelected={handleFilterSelected}
          initialFilter={initialFilter}></GridFilters>
      )}
      {actions && <div className={styles.toolbarActions}>{actions}</div>}
      <div className={styles.toolbarRight}>
        {showCollapseAll && (
          <Tooltip
            trigger={
              <IconButton variant="secondary" onClick={() => onExpandAllChange(!expanded)}>
                {expanded ? <ChevronRightOutlinedIcon /> : <ChevronDownOutlinedIcon />}
              </IconButton>
            }>
            {expanded ? t('tooltips.collapseAll') : t('tooltips.expandAll')}
          </Tooltip>
        )}
        <SearchField
          labelId="search"
          className={styles.toolbarSearchField}
          placeholder={searchFieldPlaceholder}
          value={searchFieldValue}
          onChange={handleChange}></SearchField>
      </div>
    </div>
  );
}

export default GridToolbar;
