import SoftwareInventory from '../models/SoftwareInventory';
import { inject, injectable } from 'inversify';
import InstalledSoftware from '../models/InstalledSoftware';
import DeviceDto from '../../core/dtos/DeviceDto';
import { HttpServices } from '../../core/services/http/module';
import type { AxiosInstance } from 'axios';
import { AxiosError } from 'axios';
import ApiError from '../../core/services/http/ApiError';

@injectable()
export default class ApplicationService {
  constructor(@inject(HttpServices.FrontendApiAxios) private frontendApi: AxiosInstance) {}

  async getDeviceInventory(deviceId: string): Promise<SoftwareInventory> {
    const response = await this.frontendApi
      .get<SoftwareInventoryByDeviceResponse>(`devices/${deviceId}/software`)
      .catch((e: AxiosError) => {
        throw new ApiError(`Could not get software inventory of device ${deviceId}`, e);
      });
    return { ...response.data, apps: response.data.appInventory };
  }

  async getDeviceUpdates(deviceId: string): Promise<SoftwareInventory> {
    const response = await this.frontendApi
      .get<SoftwareInventoryByDeviceResponse>(`devices/${deviceId}/software/updates`)
      .catch((e: AxiosError) => {
        throw new ApiError(`Could not get updates of device ${deviceId}`, e);
      });
    return {
      ...response.data,
      apps: response.data.appInventory
    };
  }
}

type InstalledSoftwareDto = InstalledSoftware;

interface SoftwareInventoryByDeviceResponse {
  device: DeviceDto;
  appInventory: InstalledSoftwareDto[];
  errorCode?: string;
}
