import { HostSnapshot } from '@goto/remote-execution';
import Device from './Device';

type DeviceSnapshot = Pick<Device, 'id' | 'name' | 'hostName' | 'timeZone'>;

export function mapDeviceToHostSnapshot({ id, hostName, name, timeZone }: DeviceSnapshot): HostSnapshot {
  if (!hostName) {
    throw new Error('missing hostname!');
  }
  if (!name) {
    throw new Error('missing name!');
  }
  return {
    hostId: id,
    hostName,
    name,
    timeZone
  };
}

export default DeviceSnapshot;
