import { ColDef } from '@ag-grid-community/core';
import { generalColumnDefs } from '../../../../core/components/grid/column-definitions';
import OnlineStatusCellRenderer from '../../../grid/cell-renderers/OnlineStatusCellRenderer';
import DateTimeCellRenderer from '../../../../core/components/grid/cell-renderers/DateTimeCellRenderer';
import { useTranslation } from 'react-i18next';
import useOnlineStatusQuickFilterText from './quick-filters/useOnlineStatusQuickFilterText';
import useUpdateStatusQuickFilterText from './quick-filters/useUpdateStatusQuickFilterText';
import DeviceUpdatesGridRowActions from './cell-renderers/DeviceUpdatesGridRowActions';
import DeviceUpdateSummaryCellRenderer from './cell-renderers/DeviceUpdateSummaryCellRenderer';
import DeviceNameCellRenderer from './cell-renderers/DeviceNameCellRenderer';
import UpdateStatusCellRenderer from './cell-renderers/UpdateStatusCellRenderer';
import useUpdateSummaryQuickFilterText from './quick-filters/useUpdateSummaryQuickFilterText';
import useDisabledUpdateTooltipText from '../../../hooks/useDisabledUpdateTooltipText';
import CompanyDeviceUpdateStatus from '../../../models/CompanyDeviceUpdateStatus';
import { UngroupedDeviceGroupId } from '../../../../core/models/Device';
import { useMemo } from 'react';

export default function useDeviceUpdateStatusColumnDefinitions(): ColDef<CompanyDeviceUpdateStatus>[] {
  const { t } = useTranslation();
  const disabledUpdateTooltipText = useDisabledUpdateTooltipText();
  const onlineStatusQuickFilterText = useOnlineStatusQuickFilterText();
  const updateStatusQuickFilterText = useUpdateStatusQuickFilterText();
  const updateSummaryQuickFilterText = useUpdateSummaryQuickFilterText();

  return useMemo(() => {
    const selectorColumn: ColDef<CompanyDeviceUpdateStatus> = {
      ...generalColumnDefs.select,
      tooltipValueGetter: params =>
        disabledUpdateTooltipText(params.data?.device, undefined, params.data?.updateStatus),
      resizable: false
    };

    const groupIdColumn: ColDef<CompanyDeviceUpdateStatus> = {
      valueGetter: ({ data }) => data?.device.group?.id ?? UngroupedDeviceGroupId,
      valueFormatter: params => {
        return (
          params.node?.allLeafChildren[0].data?.device.group?.name ?? t('companyUpdatesOverview.grid.groups.ungrouped')
        );
      },
      rowGroup: true,
      hide: true
    };

    const deviceNameColumn: ColDef<CompanyDeviceUpdateStatus> = {
      field: 'device.name',
      headerName: t('companyUpdatesOverview.grid.headers.deviceNickname'),
      minWidth: 70,
      lockPosition: true,
      flex: 1,
      cellRenderer: DeviceNameCellRenderer
    };

    const hostNameColumn: ColDef<CompanyDeviceUpdateStatus> = {
      field: 'device.hostName',
      headerName: t('companyUpdatesOverview.grid.headers.deviceHostName'),
      minWidth: 100,
      flex: 1
    };

    const onlineStatusColumn: ColDef<CompanyDeviceUpdateStatus> = {
      field: 'device.onlineStatus',
      headerName: t('companyUpdatesOverview.grid.headers.deviceOnlineStatus'),
      minWidth: 100,
      maxWidth: 140,
      cellRenderer: OnlineStatusCellRenderer,
      getQuickFilterText: onlineStatusQuickFilterText
    };

    const updateStatusColumn: ColDef<CompanyDeviceUpdateStatus> = {
      field: 'updateStatus',
      headerName: t('companyUpdatesOverview.grid.headers.updateStatus'),
      minWidth: 100,
      filter: true,
      cellRenderer: UpdateStatusCellRenderer,
      getQuickFilterText: updateStatusQuickFilterText
    };

    const updateDetailsColumn: ColDef<CompanyDeviceUpdateStatus> = {
      headerName: t('companyUpdatesOverview.grid.headers.updatesAvailable'),
      minWidth: 100,
      cellRenderer: DeviceUpdateSummaryCellRenderer,
      getQuickFilterText: updateSummaryQuickFilterText
    };

    const lastScanColumn: ColDef<CompanyDeviceUpdateStatus> = {
      field: 'lastScan',
      headerName: t('companyUpdatesOverview.grid.headers.lastScan'),
      minWidth: 100,
      flex: 1,
      cellRenderer: DateTimeCellRenderer
    };

    const actionsColumn: ColDef<CompanyDeviceUpdateStatus> = {
      ...generalColumnDefs.actions,
      cellRenderer: DeviceUpdatesGridRowActions,
      resizable: false
    };

    return [
      selectorColumn,
      groupIdColumn,
      deviceNameColumn,
      hostNameColumn,
      onlineStatusColumn,
      updateStatusColumn,
      updateDetailsColumn,
      lastScanColumn,
      actionsColumn
    ];
  }, [
    disabledUpdateTooltipText,
    onlineStatusQuickFilterText,
    t,
    updateStatusQuickFilterText,
    updateSummaryQuickFilterText
  ]);
}
