import { LinkProps } from '@getgo/chameleon-web-react-wrapper';
import React, { HTMLAttributeAnchorTarget, PropsWithChildren, ReactElement } from 'react';
import { GoToResolveAppRoute } from '../models/routes';
import { navigateToApp } from '../services/navigation.service';
import NavigationLink from './NavigationLink';
import TrackingEvent from '../models/TrackingEvent';

interface Props extends LinkProps {
  route: GoToResolveAppRoute;
  trackingEvent?: TrackingEvent;
  target?: HTMLAttributeAnchorTarget | undefined;
}

function GoToResolveAppLink({ route, ...rest }: PropsWithChildren<Props>): ReactElement {
  return <NavigationLink navigate={() => navigateToApp(route)} route={route} {...rest} />;
}

export default GoToResolveAppLink;
