import { Health } from '../../models/health';
import { PropsWithChildren, ReactElement } from 'react';
import { Button, EmptyState, Typography } from '@getgo/chameleon-web-react-wrapper';
import { CheckmarkCircleFilledIcon, CopyOutlinedIcon, HelpSquareInactiveIcon } from '@getgo/chameleon-icons/react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './DeviceDetailsEmptyState.module.scss';

export interface DeviceDetailsEmptyStateProps {
  health: Health.Error | Health.Unknown | Health.UpToDate;
  errorCode: string | null;
  isApplicationsUpdate?: boolean;
}

const EmptyStateIcons: Record<DeviceDetailsEmptyStateProps['health'], ReactElement> = {
  [Health.Error]: <HelpSquareInactiveIcon size="64px" color="var(--goto-icon-color-secondary)" />,
  [Health.Unknown]: <HelpSquareInactiveIcon size="64px" color="var(--goto-icon-color-secondary)" />,
  [Health.UpToDate]: <CheckmarkCircleFilledIcon size="64px" color="var(--goto-icon-color-success)" />
};

function getHealthTranslationKey(
  health: Health.Error | Health.Unknown | Health.UpToDate,
  isApplicationsUpdate?: boolean
): 'error' | 'unknown' | 'upToDate' | 'appError' {
  switch (health) {
    case Health.Error:
      return isApplicationsUpdate ? 'appError' : 'error';
    case Health.Unknown:
      return 'unknown';
    case Health.UpToDate:
      return 'upToDate';
  }
}

function DeviceDetailsEmptyState({
  children,
  health,
  errorCode,
  isApplicationsUpdate
}: PropsWithChildren<DeviceDetailsEmptyStateProps>): ReactElement {
  const { t } = useTranslation();

  function Title(): ReactElement {
    return (
      <Typography variant="heading-small" className={styles.title}>
        <Trans i18nKey={`deviceDetails.emptyState.title.${getHealthTranslationKey(health, isApplicationsUpdate)}`} />
      </Typography>
    );
  }

  function Body(): ReactElement {
    return (
      <div className={styles.body}>
        <span>
          <Trans
            i18nKey={`deviceDetails.emptyState.body.${getHealthTranslationKey(health, isApplicationsUpdate)}`}
            values={{ errorCode }}
          />
        </span>
        {errorCode && (
          <Button
            variant="neutral"
            leadingIcon={<CopyOutlinedIcon />}
            onClick={() => navigator.clipboard.writeText(errorCode)}>
            {t('deviceDetails.emptyState.copyErrorCode')}
          </Button>
        )}
        {children}
      </div>
    );
  }
  return (
    <EmptyState icon={EmptyStateIcons[health]} title={<Title />}>
      <Body />
    </EmptyState>
  );
}

export default DeviceDetailsEmptyState;
