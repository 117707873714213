import { DialogContextProps } from './DialogProvider';
import { DialogProps } from '@getgo/chameleon-web-react-wrapper';
import { useTranslation } from 'react-i18next';
import CreateTicketForm from '../../../windows-updates/components/create-ticket/CreateTicketForm';
import styles from './use-create-ticket-dialog.hook.module.scss';

const useCreateTicketDialog = ({
  show: showDialog,
  close: closeDialog
}: Pick<DialogContextProps, 'show' | 'close'>): {
  showCreateTicketDialog: (deviceId: string) => void;
} => {
  const { t } = useTranslation();

  return {
    showCreateTicketDialog: deviceId => {
      const props: DialogProps = {
        className: styles.dialog,
        open: true,
        closable: true,
        title: t('ticketCreationDialog.title'),
        children: <CreateTicketForm deviceId={deviceId} closeDialog={closeDialog} />
      };

      showDialog(props);
    }
  };
};
export default useCreateTicketDialog;
