import { ICellRendererParams } from '@ag-grid-community/core';
import { SoftwareUpdateGridRow } from '../SoftwareUpdateGridRow';
import { ReactElement, useCallback } from 'react';
import GridActions from '../../../../../core/components/grid/GridActions';
import Tooltip from '../../../../../core/components/tooltips/Tooltip';
import InstallUpdateButton from '../../InstallUpdateButton';
import useDisabledUpdateTooltipText from '../../../../hooks/useDisabledUpdateTooltipText';
import useUpdateInstallCapabilities from '../../../../hooks/useUpdateInstallCapabilities';
import UpdateInstallGuard from '../../../../hooks/UpdateInstallGuard';
import Context from '../Context';

type DeviceSoftwareInventoryActionsProps = Omit<ICellRendererParams<SoftwareUpdateGridRow>, 'context'> & {
  context: Context;
};

export default function DeviceSoftwareInventoryActions({
  data: updateRow,
  node,
  context,
  api: gridApi
}: DeviceSoftwareInventoryActionsProps): ReactElement | null {
  const disabledUpdateTooltipText = useDisabledUpdateTooltipText();
  const updateInstallCapabilities = useUpdateInstallCapabilities();
  const updateInstallGuard = UpdateInstallGuard.useForDevice();

  const onInstallUpdates = useCallback((): void => {
    if (updateInstallGuard()) {
      return;
    }

    gridApi.deselectAll();
    node.setSelected(true);
  }, [updateInstallGuard, node, gridApi]);

  if (!updateRow) {
    return null;
  }

  return (
    <GridActions>
      <Tooltip
        hidden={
          updateInstallCapabilities.deviceCanInstallUpdates(updateRow) &&
          !updateRow?.softwareUpdateStatus.updateInProgress
        }
        trigger={
          <InstallUpdateButton
            inProgress={updateRow.softwareUpdateStatus.updateInProgress}
            installRequestPending={context.installRequestPending}
            onInstallUpdateClick={onInstallUpdates}
          />
        }>
        {disabledUpdateTooltipText(updateRow.device, updateRow?.softwareUpdateStatus.updateInProgress)}
      </Tooltip>
    </GridActions>
  );
}
