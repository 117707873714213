import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ExternalLink from '../../../core/components/ExternalLink';
import { UpdateRow } from '../../types/frontend/update-row';

function WindowsReleaseNotesCellRenderer(kbArticleId: UpdateRow['kbArticleId']): ReactElement | null {
  const {
    i18n: { language }
  } = useTranslation();

  if (!kbArticleId) {
    return null;
  }

  return (
    <ExternalLink to={`https://support.microsoft.com/${language}/search/results?query=${kbArticleId}`}>
      {kbArticleId}
    </ExternalLink>
  );
}

export default WindowsReleaseNotesCellRenderer;
