import useSwr, { SWRResponse } from 'swr';
import helpdeskTicketService from './helpdesk-ticket.service';
import { HelpdeskService } from '../models/helpdesk-service';
import { HelpdeskTicketData } from '../models/helpdesk-ticket-data';
import useDeviceWindowsUpdates from '../hooks/useDeviceWindowsUpdates';

export const useHelpdeskServices = (): SWRResponse<HelpdeskService[]> =>
  useSwr('getHelpdeskServices', () => helpdeskTicketService.getHelpdeskServices());

interface TicketFormData {
  error: boolean;
  loading: boolean;
  helpdeskServices: HelpdeskService[];
  initialTicketData?: HelpdeskTicketData;
  isFormDisabled: boolean;
}

export const useTicketFormData = (deviceId: string): TicketFormData => {
  const { error: deviceUpdatesError, data: deviceUpdates } = useDeviceWindowsUpdates(deviceId);
  const { error: helpdeskServicesError, data: helpdeskServices } = useHelpdeskServices();

  const error = !!deviceUpdatesError || !!helpdeskServicesError;
  const isFormDisabled = error || !helpdeskServices?.length;
  const loading = !deviceUpdates || !helpdeskServices;

  const initialTicketData =
    helpdeskServices && deviceUpdates
      ? helpdeskTicketService.createDefaultTicketData({
          updates: deviceUpdates.updates,
          device: deviceUpdates.device,
          helpdeskServices,
          isFormDisabled
        })
      : undefined;

  return {
    error,
    isFormDisabled,
    loading,
    helpdeskServices: helpdeskServices ?? [],
    initialTicketData
  };
};
