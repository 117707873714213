import { OverviewTabs } from '../../patch-view/pages/patch-management-overview/PatchManagementOverview';
import { JobHistoryOverviewTabs } from '../../windows-updates/components/job-device-updates/JobDeviceUpdates';
import TrackingEvent from './TrackingEvent';

const appPrefix = '[PatchManagement]' as const;

export type PatchViewTrackingEvent =
  | PatchViewLoadedEvent
  | PatchViewUpdatesSelectedEvent
  | PatchViewDevicesSelectedEvent
  | PatchViewSubmittedEvent
  | PatchManagementOverviewTabSwitchedEvent;

export type UserTrackingEvent =
  | FeatureEnabledEvent
  | WindowsUpdateJobDetailsViewedEvent
  | WindowsUpdateJobDeviceDetailsViewedEvent
  | DevicesFilteredEvent
  | WindowsUpdatePatchesFilteredEvent
  | UpdateInstalledEvent
  | DeviceDetailsViewedEvent
  | PatchViewTrackingEvent;

export class FeatureEnabledEvent implements TrackingEvent {
  name = `${appPrefix} Feature Enabled`;
}

export class WindowsUpdateJobDetailsViewedEvent implements TrackingEvent {
  name = `${appPrefix} Windows Update Job Viewed`;

  constructor(public readonly jobIsInPast: boolean) {}
}

export class WindowsUpdateJobDeviceDetailsViewedEvent implements TrackingEvent {
  name = `${appPrefix} Windows Update Job Viewed Device Details`;

  constructor(public readonly jobIsInPast: boolean) {}
}

export class WindowsUpdatePatchesFilteredEvent implements TrackingEvent {
  name = `${appPrefix} Windows Updates Filtered`;

  constructor(public readonly appliedFilter: WindowsPatchFilter) {}
}

export enum WindowsPatchFilter {
  AllUpdates = 'All updates',
  ImportantAndCritical = 'Important and critical updates'
}

export class DevicesFilteredEvent implements TrackingEvent {
  name = `${appPrefix} Devices Filtered`;

  constructor(public readonly appliedFilter: DeviceFilter) {}
}

export enum DeviceFilter {
  AllDevices = 'All devices',
  VulnerableDevices = 'Vulnerable devices'
}

export class UpdateInstalledEvent implements TrackingEvent {
  name = `${appPrefix} Updates Installed`;

  constructor(
    public readonly windowsUpdateCount: number,
    public readonly wingetUpdateCount: number,
    public readonly isScheduled: boolean
  ) {}
}

export enum DeviceDetailsTargetTab {
  WindowsUpdates = 'OS Updates',
  ApplicationUpdates = 'App Updates'
}

export class DeviceDetailsViewedEvent implements TrackingEvent {
  name = `${appPrefix} Device Details Viewed`;

  constructor(public readonly targetTab: DeviceDetailsTargetTab = DeviceDetailsTargetTab.WindowsUpdates) {}
}

export class PatchManagementOverviewTabSwitchedEvent implements TrackingEvent {
  name = `${appPrefix} Overview Tab Switched`;

  constructor(public readonly targetTab: OverviewTabs) {}
}

export class JobDeviceUpdateOverviewTabSwitchedEvent implements TrackingEvent {
  name = `${appPrefix} Overview Tab Switched`;

  constructor(public readonly targetTab: JobHistoryOverviewTabs) {}
}

export class PatchViewLoadedEvent implements TrackingEvent {
  name = `${appPrefix} Windows patch view`;
}

export class PatchViewUpdatesSelectedEvent implements TrackingEvent {
  name = `${appPrefix} Windows patch view updates selected`;

  constructor(public readonly updatesCount: number) {}
}

export class PatchViewDevicesSelectedEvent implements TrackingEvent {
  name = `${appPrefix} Windows patch view devices selected`;

  constructor(public readonly devicesCount: number) {}
}

export interface InstallationEvent extends TrackingEvent {
  updateName: string;
  numberOfDevices: number;
  jobId: string;
  scheduled: boolean;
  scheduledDate?: string;
}

export class PatchViewSubmittedEvent implements InstallationEvent {
  name = `${appPrefix} Windows patch view submitted`;
  jobId: string;
  scheduledDate?: string;

  constructor(
    public readonly updateName: string,
    public readonly numberOfDevices: number,
    public readonly numberOfPatches: number,
    public readonly scheduled: boolean,
    scheduledDate?: string,
    jobId?: string
  ) {
    this.scheduledDate = scheduledDate;
    this.jobId = jobId ?? 'unknown';
  }
}

export class DeviceUpdatesInstalledEvent implements InstallationEvent {
  name = `${appPrefix} Devices Updates installed`;
  jobId: string;
  scheduledDate?: string;

  constructor(
    public readonly updateName: string,
    public readonly numberOfDevices: number,
    public readonly numberOfWinPatches: number,
    public readonly numberOfAppPatches: number,
    public readonly scheduled: boolean,
    scheduledDate?: string,
    jobId?: string
  ) {
    this.scheduledDate = scheduledDate;
    this.jobId = jobId ?? 'unknown';
  }
}
