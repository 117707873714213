import jwtDecode from 'jwt-decode';
import { JwtData } from '../models/jwt-data';
import { User } from '../models/user';

export function getUserFromJwt(jwt: string): User {
  const decodedJwt = jwtDecode<JwtData>(jwt);

  return {
    id: decodedJwt.sub,
    fullName: decodedJwt.name,
    email: decodedJwt.email,
    company: { id: decodedJwt.companyId, name: decodedJwt.companyName },
    capabilities: decodedJwt.capabilities
  };
}
