import useSwr, { SWRResponse } from 'swr';
import http from '../../core/services/http/default-http-client';
import { UpdateJob, UpdateJobDto } from '../models/update-job';
import { platformTranslationMap } from '../models/platform';
import { t } from '../../i18n/i18n';
import { combineUrl } from '../../core/utilities/utilities';
import { environment } from '../../environments/environment';
import { RunStatus } from '@goto/remote-execution';

export const updateJobCacheKey = (jobId: string): string => combineUrl(environment.frontendApiUrl, `jobs/${jobId}/all`);

export const useUpdateJob = (jobId: string): SWRResponse<UpdateJob, unknown> =>
  useSwr(updateJobCacheKey(jobId), url => http.get<UpdateJobDto>(url).then(data => mapUpdateJobDto(data)));

const mapUpdateJobDto = (updateJobDto: UpdateJobDto): UpdateJob => ({
  ...updateJobDto,
  scheduledAt: new Date(updateJobDto.scheduledAt),
  finishedAt: updateJobDto.finishedAt ? new Date(updateJobDto.finishedAt) : undefined,
  status: updateJobDto.finishedAt ? RunStatus.Completed : RunStatus.Running,
  effectiveRunBy: updateJobDto.runBy?.name ?? updateJobDto.runBy?.email,
  platformDisplayName: platformTranslationMap[updateJobDto.platform] ?? t('updateDetails.platform.unknown')
});
