export default interface PatchManagementCapabilities {
  appInstallationCapability?: AppInstallationState;
  appUpdateCapability?: AppUpdateState;
}

export enum AppInstallationState {
  Available = 'Available',
  UnknownError = 'UnknownError',
  WingetNotFound = 'WingetNotFound',
  WingetNotAccessible = 'WingetNotAccessible'
}

export enum AppUpdateState {
  Available = 'Available',
  UnknownError = 'UnknownError',
  WingetNotFound = 'WingetNotFound',
  WingetNotAccessible = 'WingetNotAccessible'
}
