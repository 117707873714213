import {
  GotoResolveApp,
  InstallWindowsUpdatesStepV5,
  Job,
  MessageWindowsUpdatesRebootStep,
  Platform,
  RunDto,
  Step,
  WingetUpgradePackageStepV3
} from '@goto/remote-execution';
import { mapDeviceToHostSnapshot } from '../../core/models/DeviceSnapshot';
import logger from '../../core/services/logger.service';
import { convertDateStrToUtc, normalizeDateTime } from '../../core/utilities/utilities';
import {
  ApplicationUpdate,
  ApplicationUpdateParams,
  ApplicationUpdatesPerDevice,
  CombinedInstallUpdateRequest,
  InstallApplicationUpdatesRequest,
  InstallWindowsUpdatesRequest,
  WindowsUpdateParams
} from '../models/install-updates-request';
import { RemoteExecutionService } from './RemoteExecutionService';
import { ReportJobParams, UpdateJobReportService, AppUpdate } from './update-job-report.service';
import { InstallationEvent, PatchViewSubmittedEvent } from '../../core/models/UserTrackingEvents';
import trackingService from '../../core/services/tracking/tracking.service';

class UpdateScheduler {
  private remoteExecutionService?: RemoteExecutionService;
  private updateJobReportService?: UpdateJobReportService;

  init(userId: string, companyId: string, jwt: string): void {
    this.remoteExecutionService = new RemoteExecutionService(userId, companyId, jwt);
    this.updateJobReportService = new UpdateJobReportService();
  }

  async scheduleAppUpdate(appUpdateRequest: InstallApplicationUpdatesRequest): Promise<RunDto> {
    // TODO: once this method is used we need to distinguish between app and windows updates in the tracking event
    const trackingEvent = new PatchViewSubmittedEvent(
      appUpdateRequest.displayName,
      appUpdateRequest.devices.length,
      appUpdateRequest.updates?.length ?? 0,
      appUpdateRequest.isScheduled
    );
    return this.scheduleUpdate(
      {
        displayName: appUpdateRequest.displayName,
        devices: appUpdateRequest.devices,
        isScheduled: appUpdateRequest.isScheduled,
        scheduleDateTime: appUpdateRequest.scheduleDateTime,
        applicationUpdates: appUpdateRequest.updates
      },
      trackingEvent
    );
  }

  async scheduleWindowsUpdate(windowsUpdateRequest: InstallWindowsUpdatesRequest): Promise<RunDto> {
    const trackingEvent = new PatchViewSubmittedEvent(
      windowsUpdateRequest.displayName,
      windowsUpdateRequest.devices.length,
      windowsUpdateRequest.updateIds?.length ?? 0,
      windowsUpdateRequest.isScheduled
    );
    return this.scheduleUpdate(
      {
        displayName: windowsUpdateRequest.displayName,
        devices: windowsUpdateRequest.devices,
        isScheduled: windowsUpdateRequest.isScheduled,
        scheduleDateTime: windowsUpdateRequest.scheduleDateTime,
        windowsUpdate: {
          installMandatoryUpdates: true,
          updateIds: windowsUpdateRequest.updateIds,
          forceReboot: windowsUpdateRequest.forceReboot,
          installOptionalUpdates: windowsUpdateRequest.installOptionalUpdates,
          forceRebootMaxDelaysCount: windowsUpdateRequest.forceRebootMaxDelaysCount
        }
      },
      trackingEvent
    );
  }

  async scheduleUpdate(updateRequest: CombinedInstallUpdateRequest, trackingEvent: InstallationEvent): Promise<RunDto> {
    const { displayName, devices, scheduleDateTime } = updateRequest;

    // run normalizeDateTime again to avoid scheduleDateTime to be in the past when the function is called
    const scheduleDate = scheduleDateTime ? new Date(normalizeDateTime(scheduleDateTime)) : undefined;

    let steps: Step[] = [];

    if (updateRequest.windowsUpdate != undefined) {
      steps = [...steps, ...this.prepareWindowsUpdateSteps(updateRequest.windowsUpdate)];
    }
    if (updateRequest.applicationUpdates != undefined) {
      steps = [...steps, ...this.prepareAppUpdateSteps(updateRequest.applicationUpdates)];
    }

    const defaultTaskTimeout = 2 * 60 * 60; // 2 hours
    // if forceReboot and delays are set - convert delays (4 hours each) to seconds and add 2 hours
    const taskTimeout =
      updateRequest.windowsUpdate?.forceReboot && updateRequest.windowsUpdate?.forceRebootMaxDelaysCount !== 0
        ? (updateRequest.windowsUpdate?.forceRebootMaxDelaysCount * 4 + 2) * 60 * 60 + defaultTaskTimeout
        : defaultTaskTimeout;

    const job: Job = {
      displayName,
      steps,
      devices: devices.map(mapDeviceToHostSnapshot),
      platform: Platform.Windows,
      scheduleDateTime: scheduleDate,
      app: GotoResolveApp.PatchManagement,
      taskTimeout: taskTimeout
    };

    if (!this.remoteExecutionService) {
      const error = new Error('RemoteExecutionService is not initialized.');
      logger.logError(error);
      throw error;
    }

    const response = await this.remoteExecutionService.run(job);

    try {
      trackingEvent.scheduledDate = scheduleDateTime ? convertDateStrToUtc(scheduleDateTime) : undefined;
      trackingEvent.jobId = response.id;
      trackingService.trackUserEvent(trackingEvent);
    } catch (error) {
      logger.logError(error as Error | string);
    }

    const { windowsUpdate, applicationUpdates } = updateRequest;

    const jobReport: ReportJobParams = {
      remoteExecutionJobId: response.id,
      osUpdateInfo: windowsUpdate
        ? {
            updateIds: windowsUpdate.updateIds ?? [],
            deviceIds: devices.map(d => d.id),
            installOptionalUpdates: windowsUpdate.installOptionalUpdates ?? false,
            forceReboot: windowsUpdate.forceReboot,
            forceRebootMaxDelaysCount: windowsUpdate.forceReboot ? windowsUpdate.forceRebootMaxDelaysCount : null
          }
        : null,
      appUpdateInfo: applicationUpdates ? { deviceIdToAppUpdate: {} } : null
    };

    if (applicationUpdates) {
      applicationUpdates.forEach((device: ApplicationUpdatesPerDevice) => {
        jobReport.appUpdateInfo!.deviceIdToAppUpdate[device.deviceId] = device.updates.map(
          ({ packageId, packageName, packageSource, targetPackageVersion }: ApplicationUpdate) =>
            ({
              id: packageId,
              name: packageName,
              source: packageSource,
              targetVersion: targetPackageVersion
            }) as AppUpdate
        );
      });
    }

    if (!this.updateJobReportService) {
      const error = new Error('UpdateJobReportService is not initialized.');
      logger.logError(error);
    } else {
      await this.updateJobReportService.reportJob(jobReport);
    }

    return response;
  }

  private prepareWindowsUpdateSteps(windowsUpdateRequest: WindowsUpdateParams): Step[] {
    const {
      updateIds = [],
      installOptionalUpdates = false,
      forceReboot,
      forceRebootMaxDelaysCount
    } = windowsUpdateRequest;

    let steps: Step[] = [];

    if (windowsUpdateRequest.installMandatoryUpdates) {
      const windowsUpdateStep: InstallWindowsUpdatesStepV5 = {
        type: 'installWindowsUpdates',
        version: 5,
        arguments: [
          { key: 'updateIds', value: updateIds.join(',') },
          { key: 'installOptionalUpdates', value: installOptionalUpdates }
        ]
      };
      steps = [...steps, windowsUpdateStep];
    }

    if (forceReboot) {
      const forceRebootStep: MessageWindowsUpdatesRebootStep = {
        type: 'messageWindowsUpdatesReboot',
        version: 1,
        arguments: [{ key: 'maxDelays', value: forceRebootMaxDelaysCount }]
      };

      steps = [...steps, forceRebootStep];
    }

    return steps;
  }

  private prepareAppUpdateSteps(updates: ApplicationUpdatesPerDevice[]): Step[] {
    const deviceUpdateMap = new Map<string, ApplicationUpdateParams[]>();
    updates.forEach(update => {
      deviceUpdateMap.set(
        update.deviceId,
        update.updates.map(
          entry =>
            ({
              packageId: entry.packageId,
              packageSource: entry.packageSource,
              packageVersion: entry.targetPackageVersion
            }) as ApplicationUpdateParams
        )
      );
    });
    const stepArguments: WingetUpgradePackageStepV3['arguments'] = [
      { key: 'upgradeParams', value: JSON.stringify(Object.fromEntries(deviceUpdateMap)) }
    ];
    const step: WingetUpgradePackageStepV3 = {
      type: 'wingetUpgradePackage',
      version: 3,
      arguments: stepArguments
    };
    return [step];
  }
}

export default new UpdateScheduler();
