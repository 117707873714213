import { PresenceAvailableIcon, PresenceOfflineIcon } from '@getgo/chameleon-icons/react';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import OnlineStatus from '../../../windows-updates/models/OnlineStatus';
import styles from './OnlineStatusIndicator.module.scss';

interface Props {
  onlineStatus: OnlineStatus;
}

function OnlineStatusIndicator({ onlineStatus }: Props): ReactElement {
  const { t } = useTranslation();

  switch (onlineStatus) {
    case OnlineStatus.Online:
      return (
        <>
          <PresenceAvailableIcon class={clsx(styles.statusIcon, styles.online)} />
          {t('onlineStatus.online')}
        </>
      );
    case OnlineStatus.Offline:
      return (
        <>
          <PresenceOfflineIcon class={clsx(styles.statusIcon, styles.offline)} />
          {t('onlineStatus.offline')}
        </>
      );
    case OnlineStatus.InSession:
      return (
        <>
          <PresenceAvailableIcon class={clsx(styles.statusIcon, styles.inSession)} />
          {t('onlineStatus.inSession')}
        </>
      );
    default:
      return <></>;
  }
}

export default OnlineStatusIndicator;
