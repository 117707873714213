import { ReactElement } from 'react';
import styles from './OnlineStatusCellRenderer.module.scss';
import OnlineStatusIndicator from '../../../core/components/online-status/OnlineStatusIndicator';
import { DeviceUpdate } from '../../models/device-update';
import { ICellRendererParams } from '@ag-grid-community/core';
import OnlineStatus from '../../models/OnlineStatus';

function OnlineStatusCellRenderer({ value }: ICellRendererParams<DeviceUpdate, OnlineStatus>): ReactElement {
  return (
    <div className={styles.cell}>
      <OnlineStatusIndicator onlineStatus={value} />
    </div>
  );
}

export default OnlineStatusCellRenderer;
