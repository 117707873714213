import i18next, { CallbackError } from 'i18next';
import { initReactI18next, TFuncKey } from 'react-i18next';
import en from './locales/en-US.json';
import resourcesToBackend from 'i18next-resources-to-backend';
import { languages } from '../core/models/language';

interface CommonTranslations {
  common: Translations;
}

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'common';
    resources: CommonTranslations;
  }
}

// using `void` keyword to ignore promise returned by the init function
void i18next
  .use(initReactI18next)
  .use(
    resourcesToBackend(async (language, _, callback) => {
      try {
        const resources = await import(`./locales/${language}.json`);
        callback(null, resources);
      } catch (error) {
        callback(error as unknown as CallbackError, null);
      }
    })
  )
  .init({
    resources: { common: { en } },
    fallbackLng: 'en-US',
    partialBundledLanguages: true,
    supportedLngs: languages,
    defaultNS: 'common'
  });

export type Translations = typeof en;
export const t: typeof i18next.t<'common', TFuncKey> = i18next.t;
export default i18next;
