import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useAppSelector } from '../store/root.store';
import { selectLanguage } from '../../ui-frame/state/ui-frame.slice';
import i18n from '../../i18n/i18n';

function TranslationsProvider({ children }: PropsWithChildren): ReactElement | null {
  const language = useAppSelector(selectLanguage);

  useEffect(() => {
    if (language) {
      void i18n.changeLanguage(language);
    }
  }, [language]);

  return <>{children}</>;
}

export default TranslationsProvider;
