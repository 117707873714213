import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UpdateDrawer.module.scss';
import { RefreshFilledIcon, ScreenOutlinedIcon, FolderOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import InfoPanel from '../../../core/components/info-panel/InfoPanel';
import Device from '../../../core/models/Device';
import WindowsInfoPanel from './WindowsInfoPanel';
import AppInfoPanel from './AppInfoPanel';
import {
  numberOfAppUpdates,
  numberOfMandatoryWindowsUpdates,
  numberOfOptionalWindowsUpdates,
  numberOfWindowsUpdates
} from '../CompanyUpdatesOverview/DeviceUpdatesStatistics';
import UpdateHandle from '../../models/UpdateHandle';
import { splitUpdatesAndDevices } from './DeviceUpdatesUtil';
import DeviceUpdates from '../../models/DeviceUpdates';

interface UpdateDrawerInfoPanelProps {
  selectedDeviceUpdates: DeviceUpdates[];
  installWindowsUpdates: boolean;
  setInstallWindowsUpdates: (state: boolean) => void;
  installOptionalWindowsUpdates: boolean;
  setInstallOptionalWindowsUpdates: (state: boolean) => void;
  installAppUpdates: boolean;
  setInstallAppUpdates: (state: boolean) => void;
}

function UpdateDrawerInfoPanel({
  selectedDeviceUpdates,
  installWindowsUpdates,
  setInstallWindowsUpdates,
  installOptionalWindowsUpdates,
  setInstallOptionalWindowsUpdates,
  installAppUpdates,
  setInstallAppUpdates
}: UpdateDrawerInfoPanelProps): ReactElement {
  const { t } = useTranslation();

  const { uniqueUpdates, selectedDevices: targetDevices } = splitUpdatesAndDevices(selectedDeviceUpdates);

  const numberOfDeviceGroups = (devices: Device[]): number => {
    const valueCount: { [key: string]: number } = {};
    devices.forEach(device => {
      if (device.group) {
        valueCount[device.group.id] = (valueCount[device.group.id] || 0) + 1;
      }
    });
    return Object.keys(valueCount).length;
  };

  const numberOfSelectedUpdates = (
    isAppUpdatesEnabled: boolean,
    isMandatoryWindowsUpdatesEnabled: boolean,
    isOptionalWindowsUpdatesEnabled: boolean,
    updates: UpdateHandle[]
  ): number => {
    let selectedUpdates = 0;
    if (isAppUpdatesEnabled) {
      selectedUpdates += numberOfAppUpdates(updates);
    }
    if (isMandatoryWindowsUpdatesEnabled) {
      selectedUpdates += numberOfMandatoryWindowsUpdates(updates);
    }
    if (isOptionalWindowsUpdatesEnabled) {
      selectedUpdates += numberOfOptionalWindowsUpdates(updates);
    }

    return selectedUpdates;
  };

  return (
    <InfoPanel>
      <div className={styles.updateInfoSection}>
        <div className={styles.updateInfoSectionTitle}>
          <span>
            <RefreshFilledIcon size="24px" class={styles.updateInfoIcon} />
          </span>
          <Typography variant="body-medium" tag="h3">
            {t('updateDrawer.infoPanel.updates')}
          </Typography>
        </div>
        <div className={styles.updateInfoSectionEntry}>
          <Typography variant="body-small-strong" color="type-color-secondary">
            {t('updateDrawer.infoPanel.updatesSelected', {
              count: numberOfSelectedUpdates(
                installAppUpdates,
                installWindowsUpdates,
                installOptionalWindowsUpdates,
                uniqueUpdates
              )
            })}
          </Typography>
        </div>
        {numberOfWindowsUpdates(uniqueUpdates) > 0 && (
          <WindowsInfoPanel
            selectedUpdates={uniqueUpdates}
            setInstallMandatoryUpdates={setInstallWindowsUpdates}
            installMandatoryUpdates={installWindowsUpdates}
            installOptionalUpdates={installOptionalWindowsUpdates}
            setInstallOptionalUpdates={setInstallOptionalWindowsUpdates}
          />
        )}
        {numberOfAppUpdates(uniqueUpdates) > 0 && (
          <AppInfoPanel
            selectedUpdates={uniqueUpdates}
            installAppUpdates={installAppUpdates}
            setInstallAppUpdates={setInstallAppUpdates}
          />
        )}
      </div>
      <div className={styles.updateInfoSection}>
        <div className={styles.updateInfoSectionTitle}>
          <span>
            <ScreenOutlinedIcon size="24px" class={styles.updateInfoIcon} />
          </span>
          <Typography variant="body-medium" tag="h3">
            {t('updateDrawer.infoPanel.devices')}
          </Typography>
        </div>
        <div>
          <Typography variant="body-small-strong" color="type-color-secondary">
            {t('updateDrawer.infoPanel.selectedDevices', { count: targetDevices.length })}
          </Typography>
        </div>
      </div>
      {numberOfDeviceGroups(targetDevices) > 0 && (
        <div className={styles.updateInfoSection}>
          <div className={styles.updateInfoSectionTitle}>
            <span>
              <FolderOutlinedIcon size="24px" class={styles.updateInfoIcon} />
            </span>
            <Typography variant="body-medium" tag="h3">
              {t('updateDrawer.infoPanel.devicesInGroups')}
            </Typography>
          </div>
          <div>
            <Typography variant="body-small-strong" color="type-color-secondary">
              {t('updateDrawer.infoPanel.selectedDevicesInGroups', { count: numberOfDeviceGroups(targetDevices) })}
            </Typography>
          </div>
        </div>
      )}
    </InfoPanel>
  );
}

export default UpdateDrawerInfoPanel;
