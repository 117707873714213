import tracking from './tracking/tracking.service';
import { Logger } from 'goto-zero-trust-vault';

class LoggerService implements Logger {
  logInformation(message: string): void {
    console.info(message);
    tracking.trackInfo(message);
  }

  logWarning(error: string | Error): void {
    console.warn(error);
    tracking.trackWarning(error);
  }

  logError(error: string | Error): void {
    console.error(error);
    tracking.trackException(error);
  }
}

const logger = new LoggerService();
export default logger;
