import { Link, LinkProps } from '@getgo/chameleon-web-react-wrapper';
import React, { HTMLAttributeAnchorTarget, PropsWithChildren, ReactElement } from 'react';
import { AppRoute, GoToResolveAppRoute } from '../models/routes';
import { getPathToNavigate } from '../services/navigation.service';
import { LinkComponent } from '@getgo/chameleon-web';
import { useAppSelector } from '../store/root.store';
import { selectAppPrefix } from '../../ui-frame/state/ui-frame.slice';
import { combineUrl } from '../utilities/utilities';
import { UserTrackingEvent } from '../models/UserTrackingEvents';
import { TrackedLink } from './tracking/TrackedComponents';

interface Props extends LinkProps {
  route: AppRoute | GoToResolveAppRoute;
  trackingEvent?: UserTrackingEvent;
  target?: HTMLAttributeAnchorTarget | undefined;
  navigate: () => void;
}

function NavigationLink({ children, route, trackingEvent, navigate, ...rest }: PropsWithChildren<Props>): ReactElement {
  const appPrefix = useAppSelector(selectAppPrefix);
  const href = combineUrl(appPrefix, getPathToNavigate(route));

  const onClick = (event: React.MouseEvent<LinkComponent>): void => {
    event.preventDefault();
    navigate();
  };

  if (trackingEvent) {
    return (
      <TrackedLink href={href} trackingEvent={trackingEvent} {...rest} onClick={onClick}>
        {children}
      </TrackedLink>
    );
  }
  return (
    <Link href={href} {...rest} onClick={onClick}>
      {children}
    </Link>
  );
}

export default NavigationLink;
