import { LinkComponent } from '@getgo/chameleon-web';
import { Link, LinkProps } from '@getgo/chameleon-web-react-wrapper';
import { HTMLAttributeAnchorTarget, MouseEvent, PropsWithChildren, ReactElement } from 'react';
import { openExternalLink } from '../../ui-frame/services/ui-frame.service';

interface Props extends LinkProps {
  to: string;
  target?: HTMLAttributeAnchorTarget | undefined;
}

function ExternalLink({ children, to, target = '_blank', ...rest }: PropsWithChildren<Props>): ReactElement {
  const handleClick = (event: MouseEvent<LinkComponent>): void => {
    if (navigator.userAgent.includes('NativeTechnicianConsole')) {
      event.preventDefault();
      openExternalLink(to);
    }
  };

  return (
    <Link href={to} onClick={handleClick} target={target} rel="noreferrer" {...rest}>
      {children}
    </Link>
  );
}

export default ExternalLink;
