import { Button, DialogProps } from '@getgo/chameleon-web-react-wrapper';
import { DialogContextProps } from './DialogProvider';
import { Trans, useTranslation } from 'react-i18next';
import ExternalLink from '../ExternalLink';
import { ExternalLinkFilledIcon } from '@getgo/chameleon-icons/react';

function useUpgradePremiumDialog({
  show: showDialog,
  close: closeDialog
}: Pick<DialogContextProps, 'show' | 'close'>): {
  showUpgradePremiumDialog: () => void;
} {
  const { t } = useTranslation();

  const props: DialogProps = {
    open: true,
    closable: true,
    title: t('premiumPlanDialog.title'),
    children: <Trans i18nKey="premiumPlanDialog.text" />,
    actions: (
      <>
        <ExternalLink
          to="https://www.goto.com/it-management/resolve/try"
          appearance="primary"
          leadingIcon={<ExternalLinkFilledIcon />}>
          {t('premiumPlanDialog.actions.contactSales')}
        </ExternalLink>
        <Button size="medium" variant="tertiary-neutral" onClick={closeDialog}>
          {t('dialog.actions.close')}
        </Button>
      </>
    )
  };

  return { showUpgradePremiumDialog: () => showDialog(props) };
}

export default useUpgradePremiumDialog;
