import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement } from 'react';
import { WindowsUpdate } from '../../models/windows-update';
import ShowDetailsLinkRenderer from './ShowDetailsLinkRenderer';

function ReleaseNotesCellRenderer({
  value,
  data,
  ...rest
}: ICellRendererParams<WindowsUpdate, string[]>): ReactElement | null {
  return <ShowDetailsLinkRenderer value={value[0]} data={data} {...rest} />;
}

export default ReleaseNotesCellRenderer;
