import { ThemeProvider as ChameleonThemeProvider } from '@getgo/chameleon-web-react-wrapper';
import { PropsWithChildren, ReactElement } from 'react';
import { useAppSelector } from '../store/root.store';
import { selectTheme } from '../../ui-frame/state/ui-frame.slice';

function ThemeProvider({ children }: PropsWithChildren): ReactElement {
  const theme = useAppSelector(selectTheme);

  return (
    <ChameleonThemeProvider theme={theme} rebranding2021 skin="gotoresolve">
      {children}
    </ChameleonThemeProvider>
  );
}

export default ThemeProvider;
