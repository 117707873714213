import { ICellRendererParams } from '@ag-grid-community/core';
import { WindowsUpdate } from '../../models/windows-update';
import { ReactElement } from 'react';
import styles from './WindowsUpdateDescriptionRenderer.module.scss';
import ExternalLink from '../../../core/components/ExternalLink';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { useTranslation } from 'react-i18next';

function WindowsUpdateDescriptionRenderer({ value, data }: ICellRendererParams<WindowsUpdate>): ReactElement {
  const title = value as string;

  function VirusDefinitionUpdateLink({ version }: { version: string }): ReactElement {
    const {
      i18n: { language }
    } = useTranslation();
    return (
      <ExternalLink
        to={`https://www.microsoft.com/${language}/wdsi/definitions/antimalware-definition-release-notes?requestVersion=${version}`}>
        {version}
      </ExternalLink>
    );
  }

  function KbArticleLink({ kbArticleId }: { kbArticleId: string }): ReactElement {
    const {
      i18n: { language }
    } = useTranslation();
    return (
      <ExternalLink to={`https://support.microsoft.com/${language}/search/results?query=${kbArticleId}`}>
        {kbArticleId}
      </ExternalLink>
    );
  }

  function Description({ kbArticleIds, title, categories }: WindowsUpdate): ReactElement {
    if (!kbArticleIds.length) {
      return <>{title}</>;
    }

    const linksInTitle: { index: number; kbArticleId?: string; defenderUpdateVersion?: string }[] = [];

    if (categories.includes('Microsoft Defender Antivirus') && title.includes('Version')) {
      const matches = title.match(/\(Version (.*?)\)/);
      if (matches) {
        const versionString = matches[1];
        linksInTitle.push({ index: title.indexOf(versionString), defenderUpdateVersion: versionString });
      }
    }

    linksInTitle.push(
      ...kbArticleIds
        .filter(kbArticleId => title.includes(kbArticleId))
        .map(kbArticleId => ({ kbArticleId, index: title.indexOf(kbArticleId) }))
    );

    linksInTitle.sort((a, b) => a.index - b.index);

    if (!linksInTitle.length) {
      return <>{title}</>;
    }

    const fragments: ReactElement[] = [];

    let position = 0;
    for (const { kbArticleId, index, defenderUpdateVersion } of linksInTitle) {
      fragments.push(<span key={index}>{title.substring(position, index)}</span>);
      if (kbArticleId) {
        fragments.push(<KbArticleLink key={kbArticleId} kbArticleId={kbArticleId} />);
        position = index + kbArticleId.length;
        continue;
      }

      if (defenderUpdateVersion) {
        fragments.push(<VirusDefinitionUpdateLink key={defenderUpdateVersion} version={defenderUpdateVersion} />);
        position = index + defenderUpdateVersion.length;
      }
    }
    fragments.push(<span key={position}>{title.substring(position)}</span>);

    return <>{fragments}</>;
  }

  function KbArticleLinks({ kbArticleIds }: { kbArticleIds?: string[] }): ReactElement | null {
    if (!kbArticleIds?.length) {
      return null;
    }

    return (
      <>
        {' '}
        (
        {kbArticleIds.map((kbArticleId, index) => (
          <span key={kbArticleId}>
            <KbArticleLink kbArticleId={kbArticleId} />
            {kbArticleIds.length > 1 && index !== kbArticleIds.length - 1 && ', '}
          </span>
        ))}
        )
      </>
    );
  }

  return (
    <Typography variant={'body-small'} className={styles.text} lineClamp={2}>
      <Description {...(data as WindowsUpdate)} />
      <KbArticleLinks kbArticleIds={data?.kbArticleIds.filter(kbId => !title.includes(kbId))} />
    </Typography>
  );
}

export default WindowsUpdateDescriptionRenderer;
