import React, { PropsWithChildren, ReactElement } from 'react';
import styles from './Actions.module.scss';
import clsx from 'clsx';

interface ActionsProps {
  className?: string;
}

function Actions({ children, className }: PropsWithChildren<ActionsProps>): ReactElement {
  const items = React.Children.toArray(children);

  return (
    <div className={clsx(styles.actions, className)}>
      {items.map((item, index) => (
        <div className={styles.actionItem} key={index}>
          {item}
        </div>
      ))}
    </div>
  );
}

export default Actions;
