import { ReactElement } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import InstalledSoftware from '../../../../windows-updates/models/InstalledSoftware';
import Spinner from '../../spinner/Spinner';
import styles from '../../../../windows-updates/grid/cell-renderers/UpdateStatusCells.module.scss';

function InProgressCellRenderer({ value }: ICellRendererParams<InstalledSoftware>): ReactElement {
  if (value) {
    return (
      <div className={styles.cell}>
        <Spinner useGotoColor={true} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default InProgressCellRenderer;
