import { Dialog, DialogProps } from '@getgo/chameleon-web-react-wrapper';
import { createContext, PropsWithChildren, ReactElement, useContext, useState } from 'react';
import useCreateTicketDialog from './use-create-ticket-dialog.hook';
import useUpgradePremiumDialog from './use-upgrade-premium-dialog.hook';
import useProDevicesDialog from './use-pro-devices-dialog.hook';
import styles from './dialog.module.scss';
import Device from '../../models/Device';
import useLimitedUpdateCapabilitiesDialog from './use-limited-update-capabilities-dialog.hook';

export interface DialogContextProps {
  show: (props: DialogProps) => void;
  showUpgradePremiumDialog: () => void;
  showProDevicesDialog: () => void;
  showCreateTicketDialog: (deviceId: string) => void;
  showLimitedUpdateCapabilitiesDialog: (targetDevices: Device[], installFunc: () => Promise<void>) => void;
  close: () => void;
}

const DialogContext = createContext<DialogContextProps>({} as DialogContextProps);

export function DialogProvider({ children }: PropsWithChildren): ReactElement {
  const show = (props: DialogProps): void => {
    setDialogProps({ ...props, onClosed: () => dialogClosedHandler(props.onClosed) });
  };

  const close = (): void => {
    setDialogProps(undefined);
  };

  const [dialogProps, setDialogProps] = useState<DialogProps | undefined>(undefined);
  const { showUpgradePremiumDialog } = useUpgradePremiumDialog({ show, close });
  const { showProDevicesDialog } = useProDevicesDialog({ show, close });
  const { showCreateTicketDialog } = useCreateTicketDialog({ show, close });
  const { showLimitedUpdateCapabilitiesDialog } = useLimitedUpdateCapabilitiesDialog({ show, close });

  // when the dialog closes itself (e.g. click away handler), we need to clear the dialog props and remove it from the DOM
  const dialogClosedHandler = (originalOnClosedHandler?: () => void): void => {
    if (originalOnClosedHandler) {
      originalOnClosedHandler();
    }
    setDialogProps(undefined);
  };

  return (
    <DialogContext.Provider
      value={{
        show,
        showUpgradePremiumDialog,
        showProDevicesDialog,
        showCreateTicketDialog,
        showLimitedUpdateCapabilitiesDialog,
        close
      }}>
      <>
        {!!dialogProps && <Dialog className={styles.customDialog} {...dialogProps}></Dialog>}
        {children}
      </>
    </DialogContext.Provider>
  );
}

export function useDialog(): DialogContextProps {
  return useContext(DialogContext);
}
