import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../core/store/root.store';
import { selectJwt, selectTheme } from '../../../../ui-frame/state/ui-frame.slice';
import { environment } from '../../../../environments/environment';
import { UpdateRow } from '../../../types/frontend/update-row';
import { useEffect, useState } from 'react';

type DevicesSelectorProps = {
  language: string;
  theme: string;
  options: string;
  token: string;
  ['filter']: string;
  ['device-filters']: string;
};

export const useDevicesSelectorProps = (selectedUpdates: UpdateRow[]): DevicesSelectorProps => {
  const [filter, setFilters] = useState('');

  const language = useLanguage();
  const theme = useTheme();
  const options = useOptions();
  const token = useToken();
  // these are filters users can apply in the UI
  const deviceFilters = useDeviceFilters();

  useEffect(() => {
    const newFilters = JSON.stringify({
      platforms: ['Win'],
      patchmanagement: {
        availableUpdates: selectedUpdates.map(({ id }) => {
          return { id };
        })
      }
    });

    setFilters(newFilters);
  }, [selectedUpdates]);

  return {
    language,
    theme,
    options,
    token,
    filter,
    'device-filters': deviceFilters
  };
};

const useLanguage = (): string => {
  return useTranslation().i18n.language;
};

const useTheme = (): string => {
  const theme = useAppSelector(selectTheme);
  return theme ? `synapse-${theme}` : 'synapse-light';
};

const useToken = (): string => {
  return useAppSelector(selectJwt);
};

const useOptions = (): string => {
  const options = {
    clientName: 'AlertsFrontend',
    graphqlHost: environment.commonDeviceServiceUrl
  };

  return JSON.stringify(options);
};

const useDeviceFilters = (): string => {
  // eslint-disable-next-line max-len
  // Based on https://bitbucket.ops.expertcity.com/projects/RC/repos/devices-ui/browse/src/components/drawers/device-filters-drawer/default-filter.js
  return JSON.stringify([
    {
      groupName: 'connection',
      filters: [
        { name: 'online', value: false, type: 'checkbox' },
        {
          name: 'onlinePresent',
          value: false,
          type: 'checkbox',
          subFilter: true
        },
        {
          name: 'onlineNotPresent',
          value: false,
          type: 'checkbox',
          subFilter: true
        },
        { name: 'inSession', value: false, type: 'checkbox' },
        { name: 'offline', value: false, type: 'checkbox' }
      ]
    },
    {
      groupName: 'group',
      filters: [{ name: 'groups', value: null, type: 'multi-select' }]
    },
    {
      groupName: 'label',
      filters: [{ name: 'labels', value: null, type: 'multi-select' }]
    },
    {
      groupName: 'deviceHealth',
      filters: [
        { name: 'allGood', value: false, type: 'checkbox' },
        { name: 'needsAttention', value: false, type: 'checkbox' },
        { name: 'critical', value: false, type: 'checkbox' },
        { name: 'blocking', value: false, type: 'checkbox' }
      ]
    },
    {
      groupName: 'lastOnline',
      filters: [
        {
          name: 'modifierOnline',
          value: 'before',
          type: 'dropdown',
          options: ['before', 'on', 'after']
        },
        { name: 'dateOnline', value: null, type: 'date' }
      ]
    },
    {
      groupName: 'lastInSession',
      filters: [
        {
          name: 'modifierInSession',
          value: 'before',
          type: 'dropdown',
          options: ['before', 'on', 'after']
        },
        { name: 'dateInSession', value: null, type: 'date' }
      ]
    },
    {
      groupName: 'lastBootTime',
      filters: [
        {
          name: 'modifierLastBootTime',
          value: 'before',
          type: 'dropdown',
          options: ['before', 'on', 'after']
        },
        { name: 'dateLastBootTime', value: null, type: 'date' }
      ]
    }
  ]);
};
