import { PropsWithChildren, ReactElement } from 'react';
import AppLink, { AppLinkProps } from '../../../core/components/AppLink';
import { AppRoute } from '../../../core/models/routes';
import { DeviceDetailsTargetTab } from '../../../core/models/UserTrackingEvents';

interface DeviceDetailsLinkProps extends Omit<AppLinkProps, 'route'> {
  targetTab?: DeviceDetailsTargetTab;
  deviceId: string;
}

export default function DeviceDetailsLink({
  targetTab,
  deviceId,
  children,
  ...appLinkProps
}: PropsWithChildren<DeviceDetailsLinkProps>): ReactElement {
  let path = '/devices/:deviceId/updates';
  switch (targetTab) {
    case DeviceDetailsTargetTab.ApplicationUpdates:
      path += '/applications';
      break;
    case DeviceDetailsTargetTab.WindowsUpdates:
      path += '/windows';
      break;
  }
  return (
    <AppLink {...appLinkProps} route={{ path, params: { deviceId } } as AppRoute}>
      {children}
    </AppLink>
  );
}
