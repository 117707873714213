import { ReactElement } from 'react';
import Device from '../../models/Device';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { useTranslation } from 'react-i18next';
import styles from './LimitedCapabilitiesInfo.module.scss';

interface LimitedCapabilitiesInfoProps {
  osNotActivatedDevices: Device[];
  wingetInaccessibleDevices: Device[];
}

function LimitedCapabilitiesInfo({
  osNotActivatedDevices,
  wingetInaccessibleDevices
}: LimitedCapabilitiesInfoProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.limitedCapabilitiesContent}>
        <span>
          <Typography variant="body-medium">
            {t(
              `limitedUpdateCapabilitiesDialog.${
                osNotActivatedDevices.length > 0
                  ? wingetInaccessibleDevices.length > 0
                    ? 'textBoth'
                    : 'textOsNotActivated'
                  : 'textMissingWinget'
              }`
            )}
          </Typography>
        </span>
        <LimitedEntry affectedDevices={osNotActivatedDevices} limitedReason={'osNotActivated'} />
        <LimitedEntry affectedDevices={wingetInaccessibleDevices} limitedReason={'wingetInaccessible'} />
      </div>
    </>
  );
}

export const limitedReasons = ['osNotActivated', 'wingetInaccessible'] as const;
export type LimitedReason = (typeof limitedReasons)[number];

interface LimitedEntryProps {
  affectedDevices: Device[];
  limitedReason: LimitedReason;
}

function LimitedEntry({ affectedDevices, limitedReason }: LimitedEntryProps): ReactElement {
  const { t } = useTranslation();

  const limitedReasonTranslationMap: Record<LimitedReason, string> = {
    osNotActivated: t('limitedUpdateCapabilitiesDialog.devicesWithoutOsActivation'),
    wingetInaccessible: t('limitedUpdateCapabilitiesDialog.devicesWithoutWinget')
  };

  return affectedDevices.length > 0 ? (
    <span>
      <Typography variant="body-medium">{limitedReasonTranslationMap[limitedReason]}</Typography>
      <Typography variant="body-medium-strong" lineClamp={0}>
        {affectedDevices.map(device => device.name).join(', ')}
      </Typography>
    </span>
  ) : (
    <></>
  );
}

export default LimitedCapabilitiesInfo;
