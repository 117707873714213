import { ReactElement } from 'react';
import DateTimeCellRenderer from '../../../core/components/grid/cell-renderers/DateTimeCellRenderer';
import { t } from '../../../i18n/i18n';
import { CellText } from './CellText';

function JobDateScheduledCellRenderer(value: Date): ReactElement {
  if (!value) {
    return <CellText>{t('jobHistoryGrid.instantUpdate')}</CellText>;
  }
  return (
    <CellText>
      <DateTimeCellRenderer value={value} />
    </CellText>
  );
}

export default JobDateScheduledCellRenderer;
