import { ColDef, GetQuickFilterTextParams, ValueFormatterParams } from '@ag-grid-community/core';
import { t } from '../../../i18n/i18n';
import WindowsUpdateDescriptionRenderer from '../../../windows-updates/grid/cell-renderers/WindowsUpdateDescriptionRenderer';
import { WindowsUpdate } from '../../../windows-updates/models/windows-update';
import ReleaseNotesCellRenderer from '../../../windows-updates/grid/cell-renderers/ReleaseNotesCellRenderer';
import SeverityCellRenderer from '../../../windows-updates/grid/cell-renderers/SeverityCellRenderer/SeverityCellRenderer';
import InProgressCellRenderer from './cell-renderers/InProgressCellRenderer';

export type GeneralGridColumn = 'select' | 'actions';

export const generalColumnDefs: Record<GeneralGridColumn, ColDef> = {
  select: {
    colId: 'select',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    showDisabledCheckboxes: true,
    lockPosition: true,
    suppressSizeToFit: true,
    width: 55
  },
  actions: {
    colId: 'actions',
    headerName: '',
    width: 0,
    suppressSizeToFit: true
  }
};

export type WindowsUpdatesGridColumn =
  | 'title'
  | 'inProgress'
  | 'categories'
  | 'severity'
  | 'releaseNotes'
  | 'isMandatory'
  | 'rebootRequired';

export const windowsUpdatesColumnDefs = (): Record<WindowsUpdatesGridColumn, ColDef> => ({
  title: {
    field: 'title',
    headerName: t('deviceDetails.grid.windows.headers.title'),
    minWidth: 400,
    flex: 1,
    cellRenderer: WindowsUpdateDescriptionRenderer
  },
  inProgress: {
    field: 'inProgress',
    headerName: '',
    width: 50,
    cellRenderer: InProgressCellRenderer
  },
  categories: {
    field: 'categories',
    headerName: t('deviceDetails.grid.windows.headers.category'),
    minWidth: 100,
    flex: 0.75
  },
  severity: {
    field: 'severity',
    headerName: t('deviceDetails.grid.windows.headers.severity'),
    width: 120,
    filter: 'agTextColumnFilter', // NOTE: this is the default, but leave it as is for clarity
    filterParams: {
      textMatcher: ({ value }: any) => {
        // NOTE: a custom text matcher is needed because we want to filter for both 'important' and 'critical' severity values
        const valueLowerCase = value.toString().toLowerCase();
        return valueLowerCase === 'important' || valueLowerCase === 'critical';
      }
    },
    cellRenderer: SeverityCellRenderer
  },
  releaseNotes: {
    colId: 'releaseNotes',
    field: 'moreInfoUrls',
    headerName: t('deviceDetails.grid.windows.headers.releaseNotes'),
    width: 120,
    cellRenderer: ReleaseNotesCellRenderer
  },
  isMandatory: {
    field: 'isMandatory',
    headerName: t('deviceDetails.grid.windows.headers.mandatory'),
    minWidth: 75,
    valueFormatter: (params: ValueFormatterParams<WindowsUpdate, boolean>) =>
      params.value ? t('deviceDetails.grid.windows.yes') : t('deviceDetails.grid.windows.no'),
    getQuickFilterText: (params: GetQuickFilterTextParams<WindowsUpdate, boolean>) =>
      params.value ? t('deviceDetails.grid.windows.yes') : t('deviceDetails.grid.windows.no')
  },
  rebootRequired: {
    field: 'rebootRequired',
    headerName: t('deviceDetails.grid.windows.headers.rebootRequired'),
    minWidth: 75,
    valueFormatter: (params: ValueFormatterParams<WindowsUpdate, boolean>) =>
      params.value ? t('deviceDetails.grid.windows.yes') : t('deviceDetails.grid.windows.no'),
    getQuickFilterText: (params: GetQuickFilterTextParams<WindowsUpdate, boolean>) =>
      params.value ? t('deviceDetails.grid.windows.yes') : t('deviceDetails.grid.windows.no')
  }
});

export const getWindowsUpdatesColumnDefsAsArray = (): ColDef<WindowsUpdate>[] =>
  Object.values<ColDef<WindowsUpdate>>(windowsUpdatesColumnDefs());
