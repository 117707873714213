/**
 * URI and base64 decodes the payload
 * @param payload Encoded unicode string
 * @returns Decoded payload
 */
export function decodeCopilotUrlQueryStringPayload(payload: string): string {
  const urlDecodedPayload: string = decodeURIComponent(payload);
  const binString: string = atob(urlDecodedPayload);
  const unicodeBinString: Uint8Array = Uint8Array.from(binString, c => c.charCodeAt(0));
  return new TextDecoder().decode(unicodeBinString);
}

/**
 * Extracts the Windows Update IDs of the updates that should be selected from the Copilot payload
 * @param payload Stringified JSON payload
 * @returns Windows Update IDs
 */
export function getSelectedWindowsUpdateIdsFromPayload(payload: string): string[] {
  try {
    const parsedPayload = JSON.parse(payload);

    // Check if 'selectWindowsUpdateIds' exists
    if (!parsedPayload.selectWindowsUpdateIds) {
      throw new Error('Payload does not contain selectWindowsUpdateIds');
    }

    // Check if 'selectWindowsUpdateIds' is an array
    if (!Array.isArray(parsedPayload.selectWindowsUpdateIds)) {
      throw new Error('selectWindowsUpdateIds is not an array');
    }

    // Check if every item in the array is a string
    if (!parsedPayload.selectWindowsUpdateIds.every((id: any) => typeof id === 'string')) {
      throw new Error('Not all selectWindowsUpdateIds are strings');
    }

    return parsedPayload.selectWindowsUpdateIds;
  } catch (error) {
    console.error('Error parsing payload or invalid structure:', error);
    return [];
  }
}
