import { RemoteExecutionLiveUpdatesService } from '@goto/remote-execution';
import loggerService from './logger.service';
import store from '../store/root.store';
import { selectJwt } from '../../ui-frame/state/ui-frame.slice';
import { environment } from '../../environments/environment';

class LiveUpdatesService {
  private liveUpdatesService: RemoteExecutionLiveUpdatesService | undefined;

  start(): void {
    if (this.liveUpdatesService) {
      return;
    }
    this.liveUpdatesService = RemoteExecutionLiveUpdatesService.create(
      () => selectJwt(store.getState()),
      loggerService,
      {
        remoteExecutionFrontendApiUrl: environment.remoteExecutionFrontendApiUrl,
        zeroTrustApiUrl: environment.zeroTrustApiUrl
      }
    );
    void this.liveUpdatesService.start();
  }

  on(
    ...params: Parameters<RemoteExecutionLiveUpdatesService['on']>
  ): ReturnType<RemoteExecutionLiveUpdatesService['on']> {
    return this.liveUpdatesService?.on(...params);
  }

  off(
    ...params: Parameters<RemoteExecutionLiveUpdatesService['off']>
  ): ReturnType<RemoteExecutionLiveUpdatesService['off']> {
    return this.liveUpdatesService?.off(...params);
  }

  stop(): void {
    this?.liveUpdatesService?.stop();
  }
}

const liveUpdatesService = new LiveUpdatesService();
export default liveUpdatesService;
