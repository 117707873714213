import { useCallback } from 'react';
import useIsCapabilityEnabled from './useIsCapabilityEnabled';
import { Capability } from '../../core/models/user-capability';
import { DialogContextProps, useDialog } from '../../core/components/dialogs/DialogProvider';

type UpdateInstallGuard = () => boolean;

interface UpdateInstallGuardFactory {
  useForDevice: () => UpdateInstallGuard;
}

const UpdateInstallGuard: UpdateInstallGuardFactory = {
  useForDevice: () => useCreateUpdateInstallGuard(useIsCapabilityEnabled(Capability.PatchManagement), useDialog())
};

export default UpdateInstallGuard;

function useCreateUpdateInstallGuard(userIsPatchManagementCapable: boolean, dialog: DialogContextProps): () => boolean {
  return useCallback(() => {
    if (!userIsPatchManagementCapable) {
      dialog.showUpgradePremiumDialog();
      return true;
    }
    return false;
  }, [dialog, userIsPatchManagementCapable]);
}
