import { ReactElement } from 'react';
import { useAppSelector } from '../../../store/root.store';
import { selectDateLocale } from '../../../../ui-frame/state/ui-frame.slice';
import { DateTime } from 'luxon';

function DateTimeCellRenderer({ value }: { value: Date }): ReactElement | null {
  const locale = useAppSelector(selectDateLocale);
  return value ? <>{DateTime.fromJSDate(value).toFormat('FF', { locale: locale })}</> : null;
}

export default DateTimeCellRenderer;
