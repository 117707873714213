import { useEffect, useState } from 'react';
import liveUpdatesService from '../../core/services/live-updates.service';
import { RunChangedData } from '@goto/remote-execution';

type UpdateJobsChangedData = Record<string, RunChangedData>;

export const useUpdateJobsChangedLiveData = (): UpdateJobsChangedData => {
  const [jobUpdates, setJobUpdates] = useState<UpdateJobsChangedData>({});

  useEffect(() => {
    const runChangedHandler = (runChangedData: RunChangedData): void => {
      setJobUpdates(prevJobUpdates => ({
        ...prevJobUpdates,
        [runChangedData.id]: {
          ...runChangedData
        }
      }));
    };

    liveUpdatesService.on('RunChanged', runChangedHandler);
    return () => {
      liveUpdatesService.off('RunChanged', runChangedHandler);
    };
  }, []);

  return jobUpdates;
};
