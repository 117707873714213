import { InitialGroupOrderComparatorParams } from '@ag-grid-community/core/dist/cjs/es5/entities/iCallbackParams';
import CompanyDeviceUpdateStatus from '../../../models/CompanyDeviceUpdateStatus';
import { UngroupedDeviceGroupId } from '../../../../core/models/Device';
import { defaultComparator } from '@ag-grid-community/core/dist/cjs/es5/utils/generic';

export default function ({ nodeA, nodeB }: InitialGroupOrderComparatorParams<CompanyDeviceUpdateStatus>): number {
  if (nodeA.key === UngroupedDeviceGroupId) {
    return nodeB.key === UngroupedDeviceGroupId ? 0 : 1;
  } else if (nodeB.key === UngroupedDeviceGroupId) {
    return -1;
  }

  return defaultComparator(
    nodeA?.allLeafChildren[0].data?.device.group?.name,
    nodeB?.allLeafChildren[0].data?.device.group?.name
  );
}
