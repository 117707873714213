import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UpdateDrawer.module.scss';
import { Switch, Typography } from '@getgo/chameleon-web-react-wrapper';
import UpdateHandle from '../../models/UpdateHandle';
import { numberOfAppUpdates } from '../CompanyUpdatesOverview/DeviceUpdatesStatistics';

interface AppInfoPanelProps {
  selectedUpdates: UpdateHandle[];
  installAppUpdates: boolean;
  setInstallAppUpdates: (state: boolean) => void;
}

function AppInfoPanel({ selectedUpdates, installAppUpdates, setInstallAppUpdates }: AppInfoPanelProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.updateInfoSectionEntryGroup}>
      <Typography variant="body-medium" tag="h3" className={styles.updateInfoSectionHeading}>
        {t('updateDrawer.infoPanel.appUpdates')}
      </Typography>
      <Switch
        className={styles.installOptionalUpdatesSwitch}
        checked={installAppUpdates}
        onChange={event => setInstallAppUpdates(event.target.checked)}>
        {t('updateDrawer.infoPanel.installAppUpdatesWithCount', { count: numberOfAppUpdates(selectedUpdates) })}
      </Switch>
    </div>
  );
}

export default AppInfoPanel;
